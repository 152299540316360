/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {create} from 'zustand'
import {
    License
} from "./LicenseModels";

interface LicenseState {
    license?: License;
    operation: "add" | "delete" | "none";
    setLicense: (license: License) => void;
}

export const useLicenseStore = create<LicenseState>((set) => ({
    operation: "none",
    license: undefined,
    setLicense: (ps: License) => {
        set(() => ({
            license: ps,
        }));
    },
    setOperation: (o:  "add" | "delete" | "none") => {
        set(() => ({
            operation: o
        }));
    },
}));