import React, {useEffect, useState} from 'react';

import {Button, debounce, Form, FormGroup, Modal, ModalVariant, Radio, TextInput,} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {useMilestoneProductStepsStore} from "../../api/MilestoneProductStepsStore";
import {MilestoneProductStepsService} from "../../api/MilestoneProductStepsService";
import {AxiosError} from "axios";
import {
    MilestoneProductFilter,
    MilestoneProductStep,
    MilestoneProductStepFilter,
    MilestoneStepAssign
} from "../../api/MilestoneProductModels";
import {PaginatedResult, PaginationRequest} from "../../api/ProjectModels";
import {useMilestoneProductsStore} from "../../api/MilestoneProductStore";
import InfiniteScroll from "react-infinite-scroll-component";


interface Props {
    productId: string | undefined
    selectedMilestoneSteps: MilestoneProductStep[]
    onSaveMilestone: Function
}

export const AssignMilestonesModal = (props: Props) => {

    const DEFAULT_MILESTONE_CANDIDATES_PAGE = 1;
    const DEFAULT_MILESTONE_CANDIDATE_SIZE = 10;

    const { operation, setOperation, activeFilter} = useMilestoneProductStepsStore();
    const [milestoneId, setMilestoneId] = useState<number>();

    const [error, setError] = useState<AxiosError | undefined>(undefined);
    const [isError, setIsError] = useState(false);

    const [milestoneProductStepCandidates2, setMilestoneProductStepCandidates] = useState<MilestoneProductStep[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [paginationRequest, setPaginationRequest] = useState<PaginationRequest>(new PaginationRequest(DEFAULT_MILESTONE_CANDIDATES_PAGE, DEFAULT_MILESTONE_CANDIDATE_SIZE));
    const [filter, setFilter] = useState<MilestoneProductStepFilter>(MilestoneProductFilter.default);


    useEffect(() => {
        fetchData(false, paginationRequest)
    }, [operation, filter]);


    function hasMoreData(data :PaginatedResult<any>) {
        return data.pagination.currentPage <= data.pagination.totalPages
    }

    const fetchData = async (append = false, paginationRequest :PaginationRequest) => {
        setError(undefined);

            const highestOrderProductStep: MilestoneProductStep = getHighestSortOrderStep(props.selectedMilestoneSteps)

        if (props.productId && highestOrderProductStep) {
            const [ response, err ] = await MilestoneProductStepsService.getMilestoneProductStepCandidates(props.productId, highestOrderProductStep.id.toString(), paginationRequest, filter);

            if(response != null) {
                setMilestoneProductStepCandidates(append ? prevItems => [...prevItems, ...response.items] : response.items);
                hasMoreData(response) ? setHasMore(true) : setHasMore(false);
            } else if (err != null) {
                setError(err);
            }
        }
    };

    const fetchNextPage = () => {
        const nextPagePaginationRequest = paginationRequest.nextPage()
        setPaginationRequest(nextPagePaginationRequest);
        fetchData(true, nextPagePaginationRequest)
    };

    function cleanValues() {
        setMilestoneId(undefined);
        setOperation("none");
        setMilestoneProductStepCandidates([])
        setError(undefined);
        setIsError(false);
        setPaginationRequest(PaginationRequest.default());
        setFilter(MilestoneProductStepFilter.default())
    }

    function closeModal() {
        cleanValues();
    }

    async function confirmAndCloseModal<T extends React.UIEvent>(event: T) {
        if (event !== null) {
            event.preventDefault();
        }

        setError(undefined);
        setIsError(false);

        if (props.productId && milestoneId) {
            const milestoneStepAssign = new MilestoneStepAssign(milestoneId, props.selectedMilestoneSteps.map(step => step.id));
            const err = await MilestoneProductStepsService.assignMilestone(props.productId, milestoneStepAssign);

            if (err !== undefined && err !== null) {
                setError(err);
            }
            if (err !== null) {
                setIsError(true);
            } else {
                setIsError(false);
                useMilestoneProductsStore.setState({operation: "none"});
                cleanValues();
                props.onSaveMilestone();
            }
        }
    }

    async function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>): Promise<void> {
        if (event.key === 'Enter') {
            await confirmAndCloseModal(event);
        }
    }

    function getHighestSortOrderStep(milestoneProductSteps: MilestoneProductStep[]) {
        const maxOrderStep = Math.max(...milestoneProductSteps.map(o => o.sortOrder))
        return milestoneProductSteps.filter(obj => obj.sortOrder === maxOrderStep)[0];
    }

    const filterByName = (query: string) => {
        const defaultFilter = MilestoneProductStepFilter.default();
        defaultFilter.name = query
        setPaginationRequest(PaginationRequest.default())
        setFilter(defaultFilter)
    }

    const onMilestoneChange = (value: number) => {
        setMilestoneId(value);
    };

    return (
        <Modal
            title="Assign Milestones"
            variant={ModalVariant.small}
            isOpen={["assign"].includes(operation)}
            onKeyUp={onKeyDown}
            onClose={() => closeModal()}
            actions={[
                <Button key="confirm" variant="primary" onClick={confirmAndCloseModal}>Assign</Button>,
                <Button key="cancel" variant="link"
                        onClick={e => closeModal()}>Cancel</Button>
            ]}
            onEscapePress={e => closeModal()}>
            <div>{"Select milestone to assign:"}</div>

            <Form>
                <TextInput autoComplete="off"
                           id="filter-milestones"
                           type="text"
                           placeholder="Search by name"
                           required
                           onChange={debounce((_, value) => filterByName(value), 600)}
                />
                <FormGroup fieldId='product-step' isRequired>
                    <div id="scrollableDiv" style={{height: "150px", overflowY: "scroll"}}>
                        <InfiniteScroll
                            dataLength={milestoneProductStepCandidates2.length}
                            next={fetchNextPage}
                            hasMore={hasMore}
                            loader={<p>Loading...</p>}
                            scrollableTarget="scrollableDiv"
                            scrollThreshold = {0.9}
                        >
                            {
                                milestoneProductStepCandidates2.map(productStep => {
                                    return <Radio value={productStep.id}
                                                  label={productStep.name}
                                                  key={"milestone-" + productStep.id}
                                                  name={"Milestone-" + productStep.id}
                                                  id={"milestone-" + productStep.id.toString()}
                                                  isChecked={productStep.id === milestoneId}
                                                  onChange={(event, _) => {
                                                      onMilestoneChange(productStep.id);
                                                  }}
                                    />
                                })
                            }
                        </InfiniteScroll>
                    </div>
                    {error && <p>Error: {error.message}</p>}
                </FormGroup>
            </Form>
        </Modal>
    )
}