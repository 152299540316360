import React from 'react';
import {
    Bullseye, Button,
    EmptyState,
    EmptyStateIcon,
    EmptyStateVariant,
    PageSection,
    Panel,
    PanelHeader,
    PanelMain, PanelMainBody,
    Title,
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {Table, Tbody, Td, Th, Thead, Tr} from "@patternfly/react-table";
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import {useProjectLogsStore, useProjectStore} from "../../api/ProjectStore";
import {ProjectLogsToolbar} from "./ProjectLogsToolbar";
import Ansi from "@curvenote/ansi-to-react";
import SyncAltIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";
import {ProjectService} from "../../api/ProjectService";
import {shallow} from "zustand/shallow";

export const ProjectLogsTab = () => {
    const { result, numberOfLogLinesShown} = useProjectLogsStore();
    const [project] = useProjectStore((state) => [state.project], shallow )

    let lineNumber = 1;

    const loadMoreLogs = () => {
        const filter = useProjectLogsStore.getState().activeFilter;
        ProjectService.getMoreProjectLogs(project.projectId, result.searchAfterSort, filter);
    }

    return (
        <PageSection className="project-tab-panel" padding={{default: "padding"}}>
            <Panel isScrollable>
                <PanelHeader style={{paddingBottom:'10px'}}>
                    <ProjectLogsToolbar/>
                </PanelHeader>
                <PanelMain maxHeight={'calc(100vh - 300px)'}>
                    <PanelMainBody>
                        <Table aria-label="Logs" variant={"compact"} className={"table"}>
                            <Thead>
                                <Tr>
                                    <Th key='message'>Message</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {result.lines.map(line => {
                                    lineNumber = lineNumber + 1;
                                    let backgroundColor = lineNumber % 2 ? 'white' : '#f0f0f0';

                                    return <Tr key={line.id} style={{backgroundColor: backgroundColor}}>
                                        <Td>
                                            <pre>
                                                <code style={{fontSize:'12px', whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                                                    <Ansi>{line.message}</Ansi>
                                                </code>
                                            </pre>
                                        </Td>
                                    </Tr>
                                })}
                                {result.lines.length === 0 &&
                                    <Tr>
                                        <Td>
                                            <Bullseye>
                                                <EmptyState variant={EmptyStateVariant.sm}>
                                                    <EmptyStateIcon icon={SearchIcon}/>
                                                    <Title headingLevel="h2" size="lg">
                                                        No results found
                                                    </Title>
                                                </EmptyState>
                                            </Bullseye>
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </PanelMainBody>
                    {numberOfLogLinesShown < result.totalHits &&
                        <Button isBlock variant={"secondary"} icon={<SyncAltIcon/>}
                                onClick={e =>
                                    loadMoreLogs()}>Load more</Button>
                    }
                </PanelMain>
            </Panel>
        </PageSection>
    )
}
