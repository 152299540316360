import {shallow} from "zustand/shallow";
import {createWithEqualityFn} from "zustand/traditional";
import {ProjectFile} from "./ProjectModels";

interface SmhIntegrationState {
    integrationsCount: number;
    setIntegrationsCount: (count: number) => void;
    integrationsFiles: ProjectFile[];
    setIntegrationsFiles: (files: ProjectFile[]) => void;
}

export const useSmhIntegrationStore = createWithEqualityFn<SmhIntegrationState>((set) => ({
    integrationsCount: 0,
    setIntegrationsCount: (integrationsCount: number) => {
        set((state: SmhIntegrationState) => ({
            integrationsCount: integrationsCount
        }));
    },
    integrationsFiles: [],
    setIntegrationsFiles: (files: ProjectFile[]) => {
        set((state: SmhIntegrationState) => ({
            integrationsFiles: files
        }));
    }
}), shallow);