import {SmhApi} from "./SmhApi";
import {PaginatedResult, PaginationRequest} from "./ProjectModels";
import {MilestoneProduct, MilestoneProductFilter} from "./MilestoneProductModels";
import {useMilestoneProductsStore} from "./MilestoneProductStore";

export class MilestoneProductsService {
    public static async getMilestoneProducts(pagination: PaginationRequest, filter: MilestoneProductFilter) {
        return SmhApi.getMilestoneProducts(pagination, filter, (result: PaginatedResult<MilestoneProduct>) => {
            useMilestoneProductsStore.setState({milestoneProducts: result.items});
            useMilestoneProductsStore.setState({paginationResult: result.pagination});
        });
    }

    public static async deleteMilestoneProduct(milestoneProduct: MilestoneProduct) {
        return await SmhApi.deleteMilestoneProduct(milestoneProduct);
    }

    public static async importMilestoneProduct(milestoneProduct: MilestoneProduct, formData: FormData) {
        return SmhApi.importMilestoneProduct(milestoneProduct, formData);
    }

    public static async cloneMilestoneProduct(sourceProductId: string, milestoneProduct: MilestoneProduct) {
        return SmhApi.cloneMilestoneProduct(sourceProductId, milestoneProduct);
    }
}
