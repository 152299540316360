import React from 'react';

import {
    Button,
    Modal,
    ModalVariant,
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {useMilestoneProductsStore} from "../../api/MilestoneProductStore";
import {MilestoneProductsService} from "../../api/MilestoneProductsService";

export const DeleteProductModal = () => {

    const {milestoneProduct, operation, activeFilter, paginationRequest} = useMilestoneProductsStore();

    function closeModal () {
        useMilestoneProductsStore.setState({operation: "none"})
    }

    function reloadMilestoneProducts() {
        MilestoneProductsService.getMilestoneProducts(paginationRequest, activeFilter);
    }

    function confirmAndCloseModal () {

        if (milestoneProduct) MilestoneProductsService.deleteMilestoneProduct(milestoneProduct).then(res => {
            reloadMilestoneProducts();
            useMilestoneProductsStore.setState({operation: "none"});
        })
    }

    const isOpen= operation === "delete";
    return (
        <Modal
            title="Confirmation"
            variant={ModalVariant.small}
            isOpen={isOpen}
            onClose={() => closeModal()}
            actions={[
                <Button key="confirm" variant="primary" onClick={e => confirmAndCloseModal()}>Delete</Button>,
                <Button key="cancel" variant="link"
                        onClick={e => closeModal()}>Cancel</Button>
            ]}
            onEscapePress={e => closeModal()}>
            <div>{"Are you sure you want to delete milestone product " + milestoneProduct?.productId + "?"}</div>
        </Modal>
    )
}