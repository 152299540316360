import { Table, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { Flex, FlexItem, Panel, PanelHeader, PanelMainBody, TextInput, Pagination, Label, Badge, Title } from "@patternfly/react-core";
import React, { useState, useEffect, useMemo } from "react";
import { ListItems, DataTableProps } from "../VersionHistoryModel"

const DataTable = ({ listData, project, type, itemPerPage, pages, showTableHeader, showPagination, tableHeader, renderItems }: DataTableProps) => {
    const [filteredCommits, setFilteredCommits] = useState<ListItems[]>([]);
    const [page, setPage] = useState(pages);
    const [perPage, setPerPage] = useState(itemPerPage);
    const [filter, setFilter] = useState<string>('');

    useEffect(() => {
        const filtered = listData?.filter(item =>
            item.author.toLowerCase().startsWith(filter.toLowerCase()) ||
            item.lastCommitMessage.toLowerCase().startsWith(filter.toLowerCase()) ||
            item.commitId.toLowerCase().startsWith(filter.toLowerCase())
        );
        setFilteredCommits(filtered);
        setPage(1);
    }, [listData, filter]);

    const handleFilterChange = (value: string) => {
        setFilter(value);
    };

    const paginatedItems = useMemo(() => {
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        return filteredCommits?.slice(startIndex, endIndex);
    }, [filteredCommits, page, perPage]);

    const onSetPage = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPage: number) => {
        setPage(newPage);
    };

    const onPerPageSelect = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPerPage: number, newPage: number) => {
        setPerPage(newPerPage);
        setPage(newPage);
    };

    return (
        <>
            <Panel>
                {showTableHeader && <PanelHeader>
                    <Flex className="toolbar" direction={{ default: "row" }} justifyContent={{ default: "justifyContentFlexStart" }}>
                        <FlexItem align={{ default: 'alignLeft' }}>
                            <Title headingLevel="h2">
                                {project?.name}
                                <Badge key={4} style={{ padding: "5px 10px", marginLeft: "10px" }} screenReaderText="Unread Messages"> {type} </Badge>
                            </Title>
                        </FlexItem>
                        <FlexItem align={{ default: 'alignRight' }}>
                            <TextInput className="text-field" type="search" id="searchCommit" name="filter" autoComplete="off" placeholder="Search commit" value={filter} onChange={(_, value) => handleFilterChange(value)} />
                        </FlexItem>
                    </Flex>
                </PanelHeader>}
                <PanelMainBody>
                    <>
                        <div style={{ height: "100%", overflow: "auto" }}>
                            <Table aria-label="Files" variant={"compact"} className={"table"}>
                                <Thead>
                                    <Tr>
                                        {Object.values(tableHeader).map((item, i) => {
                                            return <Th key={i} width={20}> {item} </Th>
                                        })}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {paginatedItems?.map((item, index) => (
                                        <Tr key={index}>
                                            {Object.keys(item).map((key, i) => {
                                                const blockItem = key as keyof ListItems;
                                                return <Td key={i} width={20}>{renderItems(item, blockItem)}</Td>;
                                            })}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </div>
                        {showPagination && <Pagination
                            itemCount={filteredCommits?.length}
                            perPage={perPage}
                            page={page}
                            onSetPage={onSetPage}
                            widgetId="top-example"
                            onPerPageSelect={onPerPageSelect}
                            ouiaId="PaginationTop"
                        />}
                    </>
                </PanelMainBody>
            </Panel>

        </>
    )
}

export default DataTable;
