import React, {useEffect, useState} from 'react';
import {
    Badge,
    Bullseye,
    Button,
    Card,
    CardBody,
    DescriptionList,
    DescriptionListDescription,
    DescriptionListGroup,
    DescriptionListTerm,
    Flex,
    FlexItem,
    PageSection,
    Panel,
    PanelHeader,
    PanelMain,
    PanelMainBody,
    Spinner,
    Text,
    TextContent,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    Tooltip
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import '../../designer/smh-karavan.css';
import PlusIcon from '@patternfly/react-icons/dist/esm/icons/plus-icon';
import {AddLicenseModal} from './AddLicenseModal';
import {useLicenseStore} from '../../api/LicenseStore';
import {License} from "../../api/LicenseModels";
import {DeleteLicenseModal} from "./DeleteLicenseModal";
import TrashIcon from "@patternfly/react-icons/dist/js/icons/trash-icon";
import {MainToolbar} from "../../designer/MainToolbar";
import {LicenseService} from "../../api/LicenseService";
import {KaravanApi} from "../../api/KaravanApi";
import {useSmhIntegrationStore} from "../../api/SmhIntegrationStore";
import {SmhIntegrationService} from "../../api/SmhIntegrationService";
import {Table} from "@patternfly/react-table/deprecated";
import {Tbody, Td, Th, Thead, Tr} from "@patternfly/react-table";
import {ProjectFile} from "../../api/ProjectModels";
import DeleteIcon from "@patternfly/react-icons/dist/js/icons/times-icon";
import DownloadIcon from "@patternfly/react-icons/dist/esm/icons/download-icon";
import {useFileStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import FileSaver from "file-saver";
import ExclamationCircleIcon from "@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon";
import {LicensePageDeleteFileModal} from './LicensePageDeleteFileModal';

export const LicensePage = () => {

    const {license,  operation, setLicense} = useLicenseStore();
    const {integrationsCount, integrationsFiles,} = useSmhIntegrationStore();
    const [loading, setLoading] = useState<boolean>(true);
    const isIntegrationsLimitExceeded = license !== undefined && license.integrationLimit < integrationsCount;

    const [setFile] = useFileStore((s) => [s.setFile], shallow);

    useEffect(() => {
        LicenseService.getLicense()
            .then(res => {
                const [err, _license] = res;
                if(err == null && _license != null) {
                    setLicense(_license!!)
                }
                setLoading(false);
            });
        SmhIntegrationService.getIntegrationCount();

        if(isIntegrationsLimitExceeded) {
            SmhIntegrationService.getAllIntegrationsFiles();
        }
    }, []);

    function getTools() {
        const isLicenseActive = license !== undefined && !license.expired;
        const isUserAdmin = KaravanApi.me?.roles
            .filter((r: string) => ['administrator'].includes(r))
            .length > 0;

        return <Toolbar id="toolbar-group-types">
            <ToolbarContent>
                <ToolbarItem>
                    <Tooltip content={"Add license"} position={"bottom"}>
                        <Button isDisabled={isLicenseActive || !isUserAdmin || loading} icon={<PlusIcon/>}
                                onClick={e =>
                                    useLicenseStore.setState({operation:"add", license: new License()})}
                        >Add License</Button>
                    </Tooltip>
                </ToolbarItem>
                <ToolbarItem>
                    <Tooltip content={"Delete license"} position={"bottom"}>
                        <Button isDisabled={license === undefined || !isUserAdmin || loading} variant="danger" icon={<TrashIcon/>} onClick={e => {
                            useLicenseStore.setState({operation:"delete"})
                        }}>Delete License</Button>
                    </Tooltip>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    }

    function title() {
        return <TextContent>
            <Text component="h2">License Management</Text>
        </TextContent>
    }

    function getLicenseDetails(integrationsCount: number, license?: License) {
        const isLicenseActive = license !== undefined && !license.expired;
        const isIntegrationsLimitReached = license !== undefined && license.integrationLimit <= integrationsCount;

        const isUserAdmin = KaravanApi.me?.roles
            .filter((r: string) => ['administrator'].includes(r))
            .length > 0;

        if (operation === "none") {
            if(loading) {
                return (
                    <Bullseye>
                        {<Spinner className="progress-stepper" diameter="80px" aria-label="Loading..."/>}
                    </Bullseye>
                );
            }
            else if(license === undefined) {
                return (
                    <Card className="project-development">
                        <CardBody>
                            <DescriptionList
                                isHorizontal
                                orientation={{
                                    md: 'vertical',
                                    lg: 'horizontal',
                                    xl: 'vertical',
                                    '2xl': 'horizontal'
                                }}
                            >
                                { isUserAdmin && <Text>No existing license found! Please add valid, active license to unlock full feature set.</Text> }
                                { !isUserAdmin && <Text>No existing license found! Please contact administrator to add valid, active license to unlock full feature set.</Text> }
                            </DescriptionList>
                        </CardBody>
                    </Card>
                );
            } else {
                return (
                    <Card className="project-development">
                        <CardBody>
                            {!loading &&
                                <DescriptionList
                                    isHorizontal
                                    orientation={{
                                        md: 'vertical',
                                        lg: 'horizontal',
                                        xl: 'vertical',
                                        '2xl': 'horizontal'
                                    }}
                                >
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Status</DescriptionListTerm>
                                        { isLicenseActive &&  <DescriptionListDescription style={{ color: 'green', fontWeight: 'bold'}} >Active</DescriptionListDescription>}
                                        { !isLicenseActive &&  <DescriptionListDescription style={{ color: 'red', fontWeight: 'bold'}} >Not Active</DescriptionListDescription>}
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Integrations Limit</DescriptionListTerm>
                                        { !isIntegrationsLimitReached && <DescriptionListDescription style={{ color: 'green', fontWeight: 'bold'}} >{license.integrationLimit} (Number of active integrations: {integrationsCount})</DescriptionListDescription>}
                                        { isIntegrationsLimitReached && <DescriptionListDescription style={{ color: 'red', fontWeight: 'bold'}} >{license.integrationLimit} (Number of active integrations: {integrationsCount})</DescriptionListDescription>}
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Key</DescriptionListTerm>
                                        <DescriptionListDescription>{license?.key}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>App Code</DescriptionListTerm>
                                        <DescriptionListDescription>{license?.appCode}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>App Id</DescriptionListTerm>
                                        <DescriptionListDescription>{license?.applicationId}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>Start Date</DescriptionListTerm>
                                        <DescriptionListDescription>{license?.startDate}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                    <DescriptionListGroup>
                                        <DescriptionListTerm>End Date</DescriptionListTerm>
                                        <DescriptionListDescription>{license?.endDate}</DescriptionListDescription>
                                    </DescriptionListGroup>
                                </DescriptionList>
                            }
                        </CardBody>
                    </Card>
                );
            }
        }
    }

    function getDate(lastUpdate: number): string {
        if (lastUpdate) {
            const date = new Date(lastUpdate);
            return date.toISOString().slice(0, 19).replace('T',' ');
        } else {
            return "N/A"
        }
    }

    function download(file: ProjectFile) {
        if (file) {
            const type = file.name.endsWith("yaml") ? "application/yaml;charset=utf-8" : undefined;
            const f = new File([file.code], file.name, {type: type});
            FileSaver.saveAs(f);
        }
    }

    return (
        <PageSection className="kamelet-section projects-page" padding={{default: 'noPadding'}}>
            { isIntegrationsLimitExceeded &&
                <PageSection className="license-warning-banner">
                    <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                        <FlexItem>
                            <ExclamationCircleIcon />
                        </FlexItem>
                        <FlexItem>
                            <Text className="license-warning-banner-text">
                                Warning! Licensed number of integrations exceeded. Please delete some of the integrations listed below to regain full feature set.
                            </Text>
                        </FlexItem>
                    </Flex>
                </PageSection>
            }
            <Panel isScrollable>
                <PanelHeader className="tools-section" style={{padding:'0px'}}>
                    <MainToolbar title={title()} tools={getTools()}/>
                </PanelHeader>
                <PanelMain maxHeight={'calc(100vh - 150px)'}>
                    <PanelMainBody>
                        <PageSection className="project-tab-panel" padding={{default: "padding"}}>
                            {getLicenseDetails(integrationsCount, license)}
                        </PageSection>

                        { isIntegrationsLimitExceeded &&
                            <PageSection className="project-tab-panel" padding={{default: "padding"}}>
                                <Table aria-label="Integrations" variant={"compact"} className={"table"}>
                                    <Thead>
                                        <Tr>
                                            <Th key='projectId' width={30}>Project ID</Th>
                                            <Th key='filename' width={40}>Integration</Th>
                                            <Th key='lastUpdate' width={30}>Updated</Th>
                                            <Th key='action'>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {integrationsFiles.map(file => {
                                            return <Tr key={file.name}>
                                                <Td>
                                                    <Badge>{file.projectId}</Badge>
                                                </Td>
                                                <Td>
                                                    {file.name}
                                                </Td>
                                                <Td>
                                                    {getDate(file.lastUpdate)}
                                                </Td>
                                                <Td modifier={"fitContent"}>
                                                    <Button className="dev-action-button" style={{padding: '0'}} variant={"plain"}
                                                            onClick={e =>
                                                                setFile('delete', file)
                                                            }>
                                                        <DeleteIcon/>
                                                    </Button>
                                                    <Tooltip content="Download source" position={"bottom-end"}>
                                                        <Button className="dev-action-button"  size="sm" variant="plain" icon={<DownloadIcon/>} onClick={e => download(file)}/>
                                                    </Tooltip>
                                                </Td>
                                            </Tr>
                                        })}
                                    </Tbody>
                                </Table>
                                <LicensePageDeleteFileModal />
                            </PageSection>
                        }
                        {["add"].includes(operation) && <AddLicenseModal/>}
                        {["delete"].includes(operation) && <DeleteLicenseModal/>}
                    </PanelMainBody>
                </PanelMain>
            </Panel>
        </PageSection>
    )
}