
export enum ProjectAddOnType {
    REST_BASIC_AUTHENTICATION = 'REST_BASIC_AUTHENTICATION'
}

export class ProjectAddOn {
    type: string = '';
    name: string = '';
    description: string = '';
    fileNames: string[] = [];


    constructor(type: string, name: string, description: string, fileNames: string[]) {
        this.type = type;
        this.name = name;
        this.description = description;
        this.fileNames = fileNames;
    }
}

export const ProjectAddOns: ProjectAddOn[] = [
    new ProjectAddOn(
        ProjectAddOnType.REST_BASIC_AUTHENTICATION,
        "REST Basic Authentication",
        "Add on enabling REST Basic Authentication support. Credentials should be added in the 'basic_auth_credentials.conf' file",
        ["basic_auth_credentials.conf" , "RestBasicAuthentication.java"]
    )
];
