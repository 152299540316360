/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import '../../designer/karavan.css';
import {
    Card,
    CardBody,
    PageSection
} from "@patternfly/react-core";
import MarkdownPreview from '@uiw/react-markdown-preview';
import {useFilesStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";

export function ReadmeTab () {

    const [files] = useFilesStore((s) => [s.files], shallow);

    const markdown = files.find(f => f.name.toLowerCase() === 'readme.md')?.code;
    console.log(markdown)
    return (
        <PageSection className="project-tab-panel project-build-panel" padding={{default: "padding"}}>
            <Card className="project-status">
                <CardBody>
                    <MarkdownPreview source={markdown} />
                </CardBody>
            </Card>
        </PageSection>
    )
}
