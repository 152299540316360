/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Navigate, Route, Routes} from 'react-router-dom';
import React from "react";
import {ProjectsPage} from "../projects/ProjectsPage";
import {ProjectPage} from "../project/ProjectPage";
import {ServicesPage} from "../services/ServicesPage";
import {ContainersPage} from "../containers/ContainersPage";
import {TemplatesPage} from "../templates/TemplatesPage";
import {ConfigurationPage} from "../config/ConfigurationPage";
import {KnowledgebaseWrapper} from './KnowledgebaseWrapper';
import {LicensePage} from "../smh/license/LicensePage";
import {useLicenseStore} from "../api/LicenseStore";
import {ProcessedEntitiesPage} from "../smh/processed-entities/ProcessedEntitiesPage";
import {MilestoneProductsPage} from "../smh/product-milestones/MilestoneProductsPage";
import {MilestoneProductDetailsPage} from "../smh/product-milestones/MilestoneProductDetailsPage";
import {useSmhIntegrationStore} from "../api/SmhIntegrationStore";

export function MainRoutes() {

    const {license} = useLicenseStore();
    const {integrationsCount} = useSmhIntegrationStore();

    function isLicenseValid() {
        return license !== undefined
            && license != null
            && !license.expired
            && license.appCode !== undefined
            && license.appCode !== ''
            && license.integrationLimit >= integrationsCount;
    }

    return (
        <Routes>
            {isLicenseValid() && <Route path="/projects" element={<ProjectsPage key={'projects'}/>}/> }
            {isLicenseValid() && <Route path="/projects/:projectId" element={<ProjectPage key={'project'}/>}/> }
            {isLicenseValid() && <Route path="/processed-entities" element={<ProcessedEntitiesPage key={'processed-entities'}/>}/> }
            {isLicenseValid() && <Route path="/product-milestones" element={<MilestoneProductsPage key={'product-milestones'}/>}/> }
            {isLicenseValid() && <Route path="/product-milestones/:productId" element={<MilestoneProductDetailsPage key={'product-details'}/>}/> }
            {isLicenseValid() && <Route path="/templates" element={<TemplatesPage key={'templates'}/>}/> }
            {isLicenseValid() && <Route path="/services" element={<ServicesPage key="services"/>}/> }
            {isLicenseValid() && <Route path="/containers" element={<ContainersPage key="services"/>}/> }
            {isLicenseValid() && <Route path="/knowledgebase" element={<KnowledgebaseWrapper dark={false}/>}/> }
            {isLicenseValid() && <Route path="/configuration" element={<ConfigurationPage dark={false}/>}/> }
            <Route path="/license" element={<LicensePage key={'license'}/>}/>
            {isLicenseValid() && <Route path="*" element={<Navigate to="/projects" replace/>}/>}
        </Routes>
    )
}
