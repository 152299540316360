import React from "react";

function CircleIcon(className?: string) {
    return (
        <svg
            version="1.1"
            id="svg50"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 88.44 90.71"
            enableBackground="new 0 0 88.44 90.71"
            xmlSpace="preserve"
            className={className ? className : "logo"}
        >
            <circle fill="#FFFFFF" cx="44.27" cy="45.41" r="44.12"/>
            <circle fill="#477DC0" cx="50.38" cy="29.02" r="2.13"/>
            <circle fill="#477DC0" cx="38.39" cy="60.81" r="2.13"/>
            <g>
	            <g>
                    <path fill="#22395D" d="M54.08,73.02c-1.65-0.02-2.91-0.69-3.74-2.12c-0.58-1-1.16-2-1.76-2.99c-0.5-0.82-1.26-1.29-2.2-1.43
                        c-1.36-0.19-2.7-0.46-4.04-0.74c-0.74-0.15-1.36,0.02-1.85,0.63c-0.58,0.72-1.1,1.49-1.61,2.25c-0.69,1.02-1.71,1.42-2.93,1.14
                        c-0.94-0.22-1.81-0.65-2.68-1.04c-2.3-1.04-4.6-2.09-6.9-3.14c-0.9-0.41-1.8-0.83-2.7-1.24c-0.69-0.31-1.28-0.73-1.68-1.39
                        c-0.4-0.66-0.4-1.29,0.06-1.93c0.6-0.83,1.18-1.69,1.77-2.54c0.28-0.41,0.27-0.62-0.02-1.03c-0.18-0.25-0.41-0.45-0.65-0.63
                        c-0.88-0.66-1.7-1.4-2.55-2.09c-0.77-0.62-1.65-0.87-2.64-0.78c-1.3,0.12-2.6,0.21-3.9,0.32c-0.62,0.05-1.24,0.14-1.86,0
                        c-1.13-0.26-2.06-0.83-2.66-1.84c-1.43-2.41-2.85-4.84-4.28-7.26C5.13,44.94,5,44.7,4.86,44.47c-0.84-1.3,0.12-2.33,1.16-2.56
                        c0.6-0.13,1.22-0.11,1.83-0.17c1.24-0.12,2.48-0.23,3.72-0.35c0.17-0.02,0.32-0.07,0.47-0.14c0.24-0.12,0.39-0.31,0.38-0.6
                        c-0.03-0.86-0.04-1.71,0-2.57c0.02-0.43-0.26-0.72-0.56-0.98c-0.41-0.36-0.92-0.53-1.41-0.76c-1.48-0.7-3.01-1.32-4.47-2.07
                        c-0.5-0.26-0.89-0.62-1.2-1.08c-0.51-0.78-0.51-1.27,0.02-2.04c0.54-0.78,1.09-1.56,1.64-2.34c0.81-1.16,1.68-2.27,2.4-3.48
                        c0.9-1.5,2.28-1.55,3.45-1.23c0.89,0.23,1.69,0.68,2.53,1.05c1.07,0.48,2.15,0.95,3.21,1.46c0.88,0.43,1.79,0.4,2.66-0.07
                        c0.75-0.41,1.53-0.76,2.32-1.09c0.54-0.22,0.69-0.58,0.4-1.09c-0.63-1.13-1.28-2.24-1.93-3.36c-0.17-0.29-0.35-0.59-0.39-0.93
                        c-0.07-0.68,0.28-1.26,0.97-1.61c0.35-0.18,0.72-0.22,1.1-0.25c1.94-0.16,3.88-0.33,5.81-0.49c1.66-0.14,3.31-0.28,4.97-0.45
                        c1.32-0.13,2.48,0.2,3.47,1.1c0.25,0.22,0.44,0.49,0.61,0.77c0.53,0.92,1.09,1.82,1.6,2.75c0.6,1.1,1.45,1.76,2.73,1.94
                        c1.34,0.18,2.65,0.52,3.98,0.74c0.7,0.11,1.32-0.11,1.74-0.75c0.5-0.74,1.05-1.45,1.53-2.21c0.68-1.09,1.69-1.28,2.84-1.12
                        c0.82,0.11,1.55,0.51,2.29,0.84c2.73,1.23,5.45,2.48,8.17,3.72c0.76,0.35,1.52,0.68,2.29,1.03c0.61,0.28,1.08,0.71,1.4,1.31
                        c0.32,0.6,0.3,1.16-0.1,1.72c-0.58,0.81-1.14,1.65-1.74,2.45c-0.43,0.57-0.43,1,0.22,1.52c0.73,0.6,1.47,1.18,2.19,1.79
                        c0.19,0.16,0.38,0.31,0.56,0.47c0.87,0.79,1.91,1.02,3.06,0.91c1.28-0.12,2.56-0.21,3.84-0.34c0.69-0.07,1.38-0.1,2.05,0.12
                        c1.04,0.33,1.86,0.93,2.4,1.91c1.14,2.07,2.35,4.09,3.54,6.13c0.32,0.55,0.65,1.1,0.96,1.66c0.68,1.23,0.03,2.51-1.38,2.65
                        c-1.58,0.16-3.16,0.28-4.74,0.42c-0.33,0.03-0.66,0.04-0.97,0.18c-0.38,0.17-0.51,0.38-0.5,0.79c0.02,0.7,0.06,1.4-0.01,2.1
                        c-0.06,0.56,0.2,0.97,0.61,1.31c0.28,0.22,0.59,0.38,0.91,0.53c1.62,0.74,3.24,1.47,4.85,2.21c0.61,0.28,1.08,0.73,1.41,1.31
                        c0.36,0.62,0.34,1.23-0.1,1.82c-1.13,1.51-2.17,3.09-3.26,4.62c-0.42,0.59-0.82,1.18-1.24,1.77c-0.4,0.56-0.99,0.8-1.65,0.86
                        c-0.92,0.08-1.75-0.2-2.58-0.59c-1.57-0.74-3.16-1.44-4.76-2.13c-0.77-0.33-1.56-0.29-2.31,0.1c-0.79,0.41-1.62,0.77-2.42,1.15
                        c-0.59,0.28-0.7,0.52-0.39,1.1c0.62,1.12,1.26,2.23,1.92,3.33c0.7,1.18,0.32,2.25-0.98,2.7c-0.2,0.07-0.42,0.1-0.63,0.12
                        c-2.41,0.21-4.82,0.42-7.24,0.63C56.78,72.8,55.43,72.91,54.08,73.02z M18.21,53.11c1.28,0,2.25,0.36,3.1,1.05
                        c0.83,0.69,1.66,1.38,2.49,2.07c0.2,0.17,0.41,0.33,0.58,0.53c0.63,0.81,0.76,1.52,0.15,2.33c-0.13,0.18-0.26,0.36-0.39,0.55
                        c-0.46,0.67-0.92,1.35-1.39,2.02c-0.19,0.28-0.18,0.54-0.02,0.82c0.3,0.54,0.78,0.84,1.33,1.08c1.75,0.78,3.5,1.55,5.23,2.36
                        c1.81,0.84,3.63,1.68,5.46,2.49c0.57,0.26,1.15,0.53,1.8,0.55c0.67,0.03,1.24-0.11,1.64-0.73c0.49-0.76,1.05-1.47,1.54-2.23
                        c0.68-1.04,1.63-1.38,2.82-1.13c1.33,0.27,2.67,0.52,4.01,0.75c1.14,0.19,2.08,0.75,2.69,1.77c0.59,1.01,1.16,2.05,1.75,3.06
                        c0.54,0.93,1.36,1.47,2.42,1.63c0.64,0.1,1.28-0.02,1.92-0.07c1.49-0.12,2.98-0.26,4.48-0.39c1.4-0.12,2.81-0.24,4.21-0.38
                        c0.6-0.06,1.21-0.04,1.79-0.21c0.7-0.21,0.85-0.59,0.49-1.23c-0.63-1.11-1.26-2.21-1.89-3.32c-0.15-0.27-0.3-0.54-0.33-0.86
                        c-0.07-0.71,0.29-1.28,1.02-1.6c0.75-0.33,1.5-0.64,2.22-1.04c1.22-0.68,2.43-0.66,3.68-0.07c1.61,0.76,3.24,1.47,4.86,2.19
                        c0.5,0.22,1.04,0.32,1.59,0.31c0.51-0.01,0.94-0.18,1.23-0.64c0.4-0.61,0.83-1.2,1.25-1.8c1.03-1.47,2.06-2.95,3.09-4.42
                        c0.21-0.3,0.21-0.58,0.02-0.89c-0.29-0.46-0.7-0.77-1.19-0.99c-1.7-0.77-3.4-1.53-5.09-2.31c-0.88-0.41-1.47-1.09-1.55-2.07
                        c-0.07-0.92-0.02-1.85-0.02-2.77c0-0.37,0.16-0.68,0.43-0.92c0.33-0.31,0.71-0.52,1.16-0.57c1.44-0.14,2.89-0.26,4.33-0.41
                        c0.5-0.05,1.02,0.02,1.49-0.21c0.5-0.24,0.63-0.62,0.36-1.1c-1.55-2.68-3.09-5.38-4.66-8.04c-0.63-1.06-1.66-1.53-2.86-1.48
                        c-1.58,0.08-3.16,0.26-4.74,0.39c-1.38,0.12-2.6-0.27-3.63-1.19c-0.48-0.43-0.96-0.85-1.46-1.25c-0.45-0.36-0.9-0.72-1.3-1.13
                        c-0.71-0.75-0.91-1.55-0.24-2.46c0.59-0.81,1.15-1.64,1.72-2.46c0.26-0.37,0.26-0.55,0.01-0.93c-0.28-0.42-0.66-0.7-1.12-0.91
                        c-1.2-0.55-2.4-1.1-3.61-1.65c-2.24-1.02-4.47-2.04-6.71-3.04c-0.68-0.3-1.34-0.69-2.1-0.78c-0.62-0.07-1.25-0.07-1.67,0.49
                        c-0.6,0.8-1.21,1.59-1.77,2.43c-0.65,0.97-1.7,1.4-2.84,1.15c-1.24-0.27-2.49-0.53-3.75-0.72c-1.43-0.22-2.49-0.95-3.18-2.25
                        c-0.48-0.91-1.02-1.79-1.54-2.68c-0.53-0.91-1.33-1.41-2.35-1.6c-0.61-0.11-1.2,0.02-1.8,0.07c-1.48,0.11-2.97,0.23-4.45,0.37
                        c-1.73,0.16-3.47,0.32-5.2,0.46c-0.43,0.03-0.88,0.02-1.27,0.26c-0.39,0.24-0.49,0.53-0.29,0.93c0.23,0.46,0.49,0.9,0.74,1.35
                        c0.46,0.82,0.95,1.63,1.39,2.46c0.38,0.71,0.21,1.42-0.39,1.91c-0.17,0.14-0.36,0.23-0.56,0.31c-0.82,0.35-1.63,0.72-2.43,1.14
                        c-0.93,0.49-1.9,0.51-2.87,0.12c-0.38-0.15-0.75-0.32-1.12-0.48c-1.39-0.62-2.78-1.25-4.17-1.87c-0.37-0.16-0.74-0.28-1.15-0.32
                        c-0.79-0.08-1.42,0.13-1.88,0.83c-0.81,1.21-1.67,2.39-2.5,3.59C6.61,30.16,6.05,31,5.47,31.82c-0.17,0.25-0.17,0.47-0.03,0.73
                        c0.26,0.47,0.62,0.82,1.11,1.03c1.79,0.78,3.54,1.64,5.35,2.39c0.93,0.39,1.52,1.29,1.49,2.3c-0.02,0.76-0.04,1.52,0.01,2.27
                        c0.04,0.69-0.3,1.14-0.86,1.46c-0.27,0.15-0.57,0.27-0.88,0.3c-1.28,0.12-2.56,0.23-3.83,0.35c-0.53,0.05-1.07,0.04-1.59,0.17
                        c-0.68,0.17-0.88,0.63-0.54,1.22c0.36,0.65,0.74,1.3,1.11,1.95c1.16,2,2.32,4,3.49,6c0.47,0.81,1.2,1.26,2.09,1.46
                        c0.72,0.17,1.45,0.02,2.18-0.03C15.83,53.33,17.1,53.21,18.21,53.11z"
                    />
	            </g>
            </g>
            <g>
                <path fill="#ABD6F3" d="M44.42,42.59c-0.94,0-1.89,0.07-2.83-0.02c-1.19-0.11-2.38-0.31-3.56-0.52c-1.57-0.28-3.1-0.71-4.59-1.27
                    c-0.75-0.28-1.22-0.99-1.28-1.78c-0.09-1.23,0.62-1.95,1.6-2.26c1.79-0.58,3.61-1.03,5.47-1.33c1.33-0.21,2.65-0.32,4-0.29
                    c0.89,0.02,1.78,0,2.68,0.01c1.49,0.02,2.95,0.25,4.41,0.52c1.46,0.27,2.88,0.67,4.29,1.12c0.7,0.22,1.24,0.62,1.52,1.33
                    c0.42,1.09-0.06,2.35-1.16,2.71c-1.54,0.51-3.12,0.96-4.71,1.28c-1.36,0.28-2.76,0.35-4.15,0.5c-0.56,0.06-1.12,0.06-1.69,0.08
                    C44.42,42.64,44.42,42.62,44.42,42.59z"
                />
                <path fill="#73AFDF" d="M45.67,48.65c-1.18,0-2.36,0.07-3.53-0.02c-1.34-0.1-2.68-0.3-4.01-0.53c-1.56-0.27-3.09-0.68-4.58-1.24
                    c-0.81-0.3-1.27-0.9-1.37-1.73c-0.1-0.89,0.21-1.62,1-2.11c0.26-0.16,0.54-0.21,0.83-0.11c1.62,0.52,3.27,0.92,4.96,1.16
                    c1.12,0.16,2.25,0.3,3.38,0.38c1.15,0.07,2.31,0.1,3.46,0.06c1.02-0.04,2.05-0.17,3.07-0.29c1.6-0.19,3.18-0.54,4.73-0.98
                    c0.25-0.07,0.51-0.14,0.75-0.24c0.44-0.17,0.8-0.03,1.13,0.25c0.59,0.48,0.84,1.09,0.8,1.87c-0.04,0.69-0.27,1.22-0.84,1.6
                    c-0.15,0.1-0.32,0.18-0.5,0.24c-1.79,0.53-3.57,1.07-5.42,1.34c-0.87,0.13-1.75,0.22-2.63,0.32c-0.41,0.04-0.83,0.06-1.25,0.08
                    C45.67,48.67,45.67,48.66,45.67,48.65z"
                />
                <path fill="#457BBF" d="M32.41,51.93c-0.02-0.02-0.04-0.04-0.06-0.06c-0.03-0.08-0.05-0.16-0.08-0.25
                    c-0.2-0.66-0.11-1.28,0.26-1.86c0.24-0.39,0.59-0.64,1.02-0.77c0.14-0.04,0.31-0.02,0.45,0.02c1.05,0.27,2.09,0.55,3.14,0.83
                    c0.06,0.23,0.32,0.37,0.66,0.4c0.57,0.05,1.15,0.12,1.72,0.16c0.12,0.01,0.25-0.06,0.37-0.09c0.06,0.01,0.13,0.02,0.19,0.04
                    c0.01,0.01,0.03,0.01,0.04,0.01c0.72,0.08,1.44,0.15,2.15,0.23c0.09,0.01,0.17,0.01,0.26,0.01c1.11,0,2.22,0.03,3.33-0.01
                    c1.55-0.06,3.11-0.16,4.63-0.51c1.35-0.31,2.69-0.65,4.04-0.96c0.18-0.04,0.42-0.02,0.58,0.06c0.9,0.42,1.31,1.18,1.24,2.22
                    c-0.05,0.78-0.73,1.6-1.65,1.85c-1.5,0.42-3.01,0.82-4.54,1.15c-1.36,0.29-2.75,0.44-4.15,0.4c-1.46-0.04-2.92,0.01-4.38-0.02
                    c-0.48-0.01-0.95-0.11-1.43-0.18c-1.04-0.16-2.09-0.33-3.13-0.52c-0.42-0.07-0.82-0.2-1.23-0.31c0.01-0.28-0.19-0.44-0.4-0.51
                    c-0.49-0.18-0.99-0.3-1.49-0.46c-0.21-0.07-0.48-0.11-0.58-0.25C33.14,52.19,32.81,52.02,32.41,51.93z"
                />
                <path fill="#477DC0" d="M26.05,52.4c0.04-0.04,0.09-0.08,0.13-0.12c0.06-0.07,0.17-0.13,0.17-0.19c-0.02-0.49-0.03-0.49,0.41-0.69
                    c0.04-0.02,0.07-0.03,0.1-0.05c0.12-0.04,0.25-0.05,0.35-0.12c0.13-0.09,0.25-0.22,0.36-0.34c0.45-0.54,1.61-0.38,1.82,0.43
                    c0.21,0.8,0.46,1.58,0.68,2.37c0.19,0.67,0.36,1.35,0.57,2.11c-2.11-0.55-4.19-1.09-6.36-1.65c0.36-0.35,0.65-0.63,0.94-0.91
                    c0.22-0.22,0.44-0.44,0.66-0.66c0,0,0,0,0,0C25.93,52.51,25.99,52.45,26.05,52.4z"
                />
                <path fill="#457CBF" d="M58.23,35.77c0-0.02,0.01-0.04,0-0.06c-0.3-0.62-0.45-1.28-0.64-1.94c-0.02-0.06-0.07-0.11-0.15-0.21
                    c0.15,0,0.25-0.02,0.34,0c0.72,0.18,1.44,0.38,2.16,0.57c0.07,0.02,0.15,0,0.23,0c0,0,0.01,0.01,0.01,0.01l0.02-0.01
                    c0.71,0.17,1.42,0.35,2.14,0.52c0.09,0.02,0.19,0.05,0.28,0.07c0.09,0.14,0.17,0.29,0.28,0.41c0.17,0.17,0.28,0.34,0.25,0.58
                    c-0.05,0.06-0.09,0.11-0.14,0.17c-0.22-0.01-0.31,0.11-0.31,0.31l-0.02-0.01c0,0,0.02,0.02,0.02,0.02c-0.06,0-0.14-0.01-0.19,0.02
                    c-0.44,0.21-0.7,0.59-0.83,1.02c-0.08,0.27-0.2,0.44-0.45,0.55c-0.04,0.03-0.08,0.05-0.12,0.08c-0.02,0.02-0.04,0.04-0.06,0.07
                    c-0.03,0.04-0.06,0.08-0.1,0.11c0,0-0.02,0.02-0.02,0.02c-0.02,0.01-0.03,0.03-0.05,0.04c-0.02,0.02-0.05,0.05-0.07,0.07
                    c0,0.02-0.01,0.03-0.03,0.04c-0.31,0.02-0.61,0.06-0.92,0.06c-0.41,0-0.51-0.12-0.66-0.49c-0.12-0.28-0.2-0.61-0.55-0.73
                    c-0.02-0.02-0.03-0.04-0.05-0.07c-0.01-0.02-0.01-0.03-0.02-0.05c-0.05-0.16-0.09-0.31-0.14-0.47c-0.01-0.03-0.02-0.05-0.03-0.08
                    c-0.03-0.11-0.06-0.22-0.08-0.33c-0.01-0.02-0.02-0.05-0.03-0.07c0.01-0.02,0-0.03-0.01-0.04C58.26,35.89,58.25,35.83,58.23,35.77z"
                />
                <path fill="#B0BDD0" d="M62.29,49.67c-0.11,2.23-0.5,4.14-1.67,5.81c-0.65,0.92-1.49,1.66-2.38,2.33
                    c-1.51,1.14-3.23,1.75-5.08,2.01c-0.2,0.03-0.4,0.01-0.46-0.23c-0.07-0.28,0.1-0.38,0.34-0.43c0.67-0.15,1.36-0.25,2.01-0.47
                    c1.34-0.44,2.58-1.09,3.6-2.08c0.57-0.55,1.12-1.14,1.59-1.77c0.68-0.92,0.94-2.03,1.16-3.14c0.39-1.96,0.14-3.85-0.57-5.7
                    c-0.07-0.19-0.2-0.42,0.09-0.54c0.23-0.1,0.46,0.01,0.56,0.31c0.2,0.59,0.41,1.18,0.53,1.78C62.15,48.32,62.21,49.11,62.29,49.67z"
                />
                <path fill="#AFBED0" d="M26.06,39.91c0.03-1.09,0.35-2.66,1.06-4.15c0.51-1.08,1.31-1.94,2.2-2.72c1.14-0.99,2.42-1.72,3.87-2.14
                    c0.58-0.17,1.19-0.28,1.78-0.4c0.19-0.04,0.4-0.05,0.47,0.2c0.06,0.23-0.05,0.38-0.33,0.45c-0.64,0.15-1.29,0.26-1.92,0.46
                    c-1.24,0.39-2.35,1.02-3.35,1.85c-0.93,0.78-1.75,1.67-2.24,2.79c-0.42,0.96-0.7,1.97-0.8,3.02c-0.16,1.67,0.01,3.29,0.6,4.86
                    c0.05,0.13,0.1,0.28,0.09,0.42c0,0.1-0.09,0.27-0.16,0.28c-0.11,0.01-0.29-0.05-0.34-0.13c-0.14-0.23-0.24-0.49-0.32-0.75
                    C26.33,42.82,26.03,41.67,26.06,39.91z"
                />
                <path fill="#4B7FC1" d="M61.21,37.79c0.24-0.11,0.36-0.28,0.45-0.55c0.13-0.43,0.39-0.82,0.83-1.02c0.06-0.03,0.13-0.01,0.19-0.02
                    c-0.14,0.16-0.29,0.33-0.43,0.49c-0.11,0.09-0.21,0.18-0.33,0.27c0.14,0.16,0.24,0.27,0.33,0.38c-0.02,0.16-0.05,0.29,0.09,0.45
                    c0.64,0.7,1.25,1.42,1.89,2.13c0.11,0.12,0.28,0.19,0.43,0.28c0.12,0.14,0.24,0.27,0.35,0.41c0.02,0.02,0.03,0.04,0.05,0.07
                    c0.33,0.52,0.67,1.03,0.98,1.57c0.31,0.54,0.61,1.09,0.86,1.66c0.37,0.84,0.68,1.7,1.02,2.55c0.03,0.06,0.06,0.11,0.09,0.17
                    c0.03,0.16,0.06,0.31,0.09,0.47c0.01,0.02,0.02,0.04,0.04,0.05c0.01,0.06,0.01,0.12,0.02,0.18c-0.12,0-0.23,0-0.35,0
                    c-0.12-0.44-0.25-0.89-0.37-1.33c-0.05-0.19-0.09-0.38-0.16-0.56c-0.1-0.27-0.21-0.54-0.34-0.79c-0.21-0.41-0.66-0.64-0.8-1.11
                    c-0.01-0.05-0.1-0.11-0.16-0.11c-0.23-0.01-0.46-0.01-0.68-0.01c-0.08-0.1-0.2-0.18-0.22-0.29c-0.05-0.28-0.26-0.37-0.48-0.47
                    c-0.69-1.1-1.37-2.21-2.08-3.29c-0.33-0.5-0.72-0.97-1.08-1.44C61.38,37.85,61.28,37.83,61.21,37.79z"
                />
                <path fill="#4C80C1" d="M26.87,51.34c-0.03,0.02-0.07,0.04-0.1,0.05c-0.44,0.2-0.43,0.2-0.41,0.69c0,0.06-0.11,0.13-0.17,0.19
                    c-0.15-0.26-0.31-0.51-0.46-0.77c-0.07-0.07-0.14-0.15-0.2-0.22c-0.02-0.02-0.04-0.04-0.06-0.06c-0.08-0.1-0.16-0.19-0.23-0.29
                    c0,0,0,0,0,0c-0.11-0.14-0.23-0.27-0.34-0.41c0-0.02,0-0.03,0-0.05c0.07-0.37-0.2-0.64-0.36-0.9c-0.42-0.68-0.91-1.33-1.39-1.97
                    c-0.08-0.11-0.25-0.15-0.38-0.23c-0.36-0.75-0.73-1.5-1.09-2.25c-0.01-0.03-0.03-0.05-0.04-0.08c-0.01-0.02-0.01-0.04-0.02-0.06
                    c0.31-0.08,0.59-0.01,0.81,0.2c0.14,0.14,0.28,0.17,0.47,0.13c0.09-0.02,0.19,0.02,0.28,0.05c0.1,0.04,0.2,0.1,0.29,0.15
                    c0.84,1.88,1.88,3.65,3.08,5.33C26.65,51,26.76,51.17,26.87,51.34z"
                />
                <path fill="#5387C6" d="M23.46,45.51c-0.1-0.05-0.19-0.11-0.29-0.15c-0.09-0.03-0.19-0.07-0.28-0.05
                    c-0.19,0.04-0.32,0.01-0.47-0.13c-0.23-0.21-0.51-0.28-0.81-0.2c-0.12-0.29-0.24-0.58-0.36-0.87c-0.11-0.27-0.24-0.55-0.32-0.83
                    c-0.1-0.33-0.17-0.66-0.25-0.99c-0.03-0.14-0.07-0.27-0.1-0.41c-0.02-0.08-0.03-0.16-0.05-0.24c-0.01-0.02-0.01-0.04-0.02-0.05
                    c-0.01-0.06-0.02-0.11-0.02-0.17c-0.02-0.08-0.04-0.16-0.06-0.25c-0.02-0.15-0.05-0.31-0.07-0.46c0.23-0.35,0.22-0.41-0.06-0.46
                    c-0.01-0.02-0.02-0.04-0.03-0.06c-0.01-0.12-0.03-0.23-0.04-0.35c0.47,0.09,0.8,0.44,0.72,0.83c-0.06,0.28,0.11,0.37,0.28,0.43
                    c0.11,0.04,0.27-0.04,0.4-0.08c0.06-0.02,0.1-0.08,0.19-0.15c0.06,0.14,0.09,0.25,0.15,0.35c0.04,0.07,0.11,0.13,0.17,0.19
                    c0.29,0.98,0.58,1.97,0.89,2.95C23.14,44.76,23.31,45.13,23.46,45.51z"
                />
                <path fill="#568BC8" d="M67.81,47.32c0.12,0,0.23,0,0.35,0c0.07,0.22,0.16,0.43,0.2,0.66c0.14,0.95,0.26,1.89,0.39,2.84
                    c0.01,0.08-0.01,0.15-0.01,0.23c-0.06,0.04-0.17,0.06-0.18,0.11c-0.07,0.35-0.27,0.3-0.5,0.18c-0.06-0.03-0.13-0.05-0.18-0.08
                    c-0.27-0.15-0.53-0.29-0.79-0.44c0-0.04,0-0.08-0.01-0.12c0-0.07-0.01-0.15-0.01-0.22c-0.14-1.5-0.49-2.96-1-4.38
                    c-0.03-0.09-0.05-0.19-0.08-0.28c0.37,0.03,0.49,0.18,0.6,0.59c0.07,0.26,0.08,0.6,0.41,0.71c0.23,0.07,0.3,0.21,0.28,0.43
                    c-0.01,0.07-0.01,0.17,0.03,0.22c0.06,0.08,0.15,0.14,0.24,0.17c0.03,0.01,0.12-0.1,0.15-0.18C67.75,47.62,67.77,47.47,67.81,47.32z"
                />
                <path fill="#B0D2EF" d="M38.02,25.27c0.39,0.04,0.79,0.05,1.18,0.12c1.39,0.26,2.78,0.48,4.15,0.83c1.2,0.3,2.36,0.74,3.54,1.12
                    c0.14,0.04,0.27,0.1,0.41,0.14c0.18,0.05,0.3,0.17,0.21,0.33c-0.04,0.08-0.25,0.14-0.35,0.11c-0.58-0.17-1.13-0.41-1.72-0.55
                    c-1.32-0.32-2.64-0.63-3.97-0.87c-0.99-0.18-2-0.24-3-0.36c-0.06-0.01-0.11-0.03-0.17-0.05C38.22,25.82,38.12,25.55,38.02,25.27z"
                />
                <path fill="#9EC7EA" d="M38.02,25.27c0.1,0.27,0.2,0.55,0.3,0.82c-0.52,0-1.05-0.02-1.57-0.01c-1.34,0.03-2.67,0.13-3.98,0.39
                    c-0.09,0.02-0.19,0.01-0.29,0.02c-0.02-0.1-0.04-0.2-0.07-0.3c-0.05-0.21-0.11-0.43-0.16-0.64c0.62-0.09,1.23-0.2,1.85-0.27
                    c0.58-0.06,1.17-0.11,1.76-0.11c0.62,0,1.23,0.06,1.85,0.09C37.81,25.26,37.91,25.27,38.02,25.27z"
                />
                <path fill="#6A9FD5" d="M20.69,34.84c0.09-0.3,0.15-0.61,0.27-0.89c0.27-0.6,0.57-1.19,0.85-1.79c0.03-0.04,0.06-0.08,0.09-0.12
                    c0.01-0.02,0.01-0.04,0.02-0.06c0.01-0.02,0.03-0.03,0.04-0.05c0,0,0,0,0,0c0.14-0.12,0.26-0.27,0.42-0.35
                    c0.23-0.12,0.46-0.1,0.67,0.08c0.21,0.17,0.01,0.32-0.01,0.49c-0.03,0.28-0.03,0.56-0.04,0.84c-0.18,0.47-0.38,0.93-0.54,1.41
                    c-0.14,0.4-0.25,0.81-0.37,1.22c-0.12-0.13-0.27-0.25-0.36-0.4C21.52,34.81,21.08,34.65,20.69,34.84z"
                />
                <path fill="#669CD3" d="M65.82,57.05c0.46-0.97,0.84-1.98,1.02-3.04c0.04-0.23,0.07-0.46,0.1-0.69c0.07,0.09,0.16,0.18,0.21,0.28
                    c0.06,0.12,0.06,0.27,0.12,0.38c0.21,0.38,0.73,0.34,0.96,0.7c-0.16,0.8-0.44,1.55-0.83,2.26c-0.04,0.02-0.1,0.02-0.13,0.05
                    c-0.21,0.21-0.41,0.44-0.64,0.62c-0.12,0.09-0.32,0.14-0.47,0.11c-0.09-0.02-0.16-0.2-0.22-0.32
                    C65.88,57.3,65.86,57.17,65.82,57.05z"
                />
                <path fill="#6196CF" d="M20.69,34.84c0.39-0.19,0.83-0.02,1.05,0.37c0.09,0.15,0.24,0.27,0.36,0.4c-0.2,1.04-0.29,2.08-0.27,3.14
                    c-0.45-0.34-0.89-0.69-1.34-1.02c-0.08-0.06-0.19-0.1-0.28-0.14c-0.01-0.02-0.01-0.04-0.02-0.06c0-0.08,0.01-0.16,0.01-0.23
                    c0.02-0.14,0.03-0.27,0.05-0.41c0.01-0.08,0.01-0.16,0.02-0.23c0.1-0.51,0.2-1.01,0.3-1.52c0.02-0.02,0.03-0.04,0.05-0.06
                    C20.64,34.99,20.66,34.91,20.69,34.84z"
                />
                <path fill="#76A9DB" d="M23.01,32.97c0.01-0.28,0.01-0.56,0.04-0.84c0.02-0.16,0.21-0.32,0.01-0.49c-0.21-0.17-0.45-0.19-0.67-0.08
                    c-0.16,0.08-0.28,0.23-0.42,0.35c0.1-0.17,0.19-0.34,0.29-0.51c0.11-0.16,0.23-0.32,0.34-0.48c0.02-0.03,0.05-0.07,0.07-0.1
                    c0.1-0.12,0.2-0.24,0.3-0.36c0.36-0.39,0.71-0.8,1.09-1.18c0.21-0.21,0.47-0.39,0.71-0.58c-0.09,0.51,0.31,0.53,0.63,0.63
                    c0.13,0.04,0.25,0.12,0.37,0.19c-0.58,0.62-1.19,1.22-1.73,1.87c-0.33,0.4-0.57,0.87-0.85,1.31C23.13,32.8,23.07,32.89,23.01,32.97z"
                />
                <path fill="#91BCE5" d="M32.24,25.55c0.05,0.21,0.11,0.43,0.16,0.64c0.02,0.1,0.05,0.2,0.07,0.3c-0.64,0.17-1.3,0.32-1.93,0.52
                    c-0.67,0.22-1.32,0.5-1.98,0.75c-0.29-0.29-0.58-0.57-0.87-0.86c0.91-0.49,1.89-0.8,2.89-1.05C31.12,25.73,31.69,25.65,32.24,25.55z"
                />
                <path fill="#5D94CE" d="M68.23,54.69c-0.23-0.36-0.75-0.32-0.96-0.7c-0.06-0.11-0.06-0.26-0.12-0.38c-0.05-0.1-0.14-0.19-0.21-0.28
                    c0.03-0.61,0.06-1.23,0.1-1.84c0.01-0.22,0.02-0.44,0.03-0.67c0.26,0.15,0.53,0.3,0.79,0.44c0.06,0.03,0.12,0.05,0.18,0.08
                    c0.23,0.12,0.43,0.17,0.5-0.18c0.01-0.05,0.11-0.07,0.18-0.11c-0.06,0.73-0.1,1.46-0.19,2.18c-0.06,0.46-0.18,0.92-0.28,1.38
                    C68.25,54.64,68.24,54.66,68.23,54.69C68.23,54.68,68.23,54.69,68.23,54.69z"
                />
                <path fill="#70A6D9" d="M65.82,57.05c0.04,0.12,0.07,0.24,0.12,0.35c0.06,0.12,0.13,0.3,0.22,0.32c0.15,0.02,0.35-0.02,0.47-0.11
                    c0.23-0.18,0.43-0.41,0.64-0.62c0.03-0.03,0.09-0.04,0.13-0.05c-0.26,0.64-0.61,1.22-1.04,1.76c-0.34,0.42-0.67,0.84-1.01,1.26
                    c-0.04,0.02-0.08,0.05-0.11,0.07c-0.15,0.03-0.3,0.05-0.45,0.08c-0.12,0.03-0.24,0.06-0.36,0.09c-0.33,0.08-0.58-0.09-0.81-0.29
                    c0.7-0.69,1.34-1.43,1.85-2.28C65.57,57.45,65.7,57.25,65.82,57.05z"
                />
                <path fill="#9EC7EA" d="M48.24,63.54c1.44,0.18,2.88,0.24,4.33,0.23c0.41-0.01,0.81-0.03,1.22-0.05c0.02,0.29,0.04,0.58,0.06,0.87
                    c-0.6,0.02-1.2,0.04-1.8,0.04c-0.36,0-0.73-0.01-1.09-0.05c-0.83-0.09-1.66-0.2-2.49-0.3c0-0.05-0.02-0.11,0.01-0.14
                    c0.18-0.26,0.06-0.4-0.19-0.5C48.27,63.63,48.26,63.57,48.24,63.54z"
                />
                <path fill="#84B4E0" d="M27.69,26.91c0.29,0.29,0.58,0.57,0.87,0.86c-1.01,0.46-1.89,1.13-2.79,1.76
                    c-0.12-0.06-0.24-0.15-0.37-0.19c-0.33-0.1-0.72-0.12-0.63-0.63c0.29-0.23,0.57-0.48,0.88-0.67c0.61-0.38,1.24-0.72,1.86-1.07
                    C27.55,26.94,27.62,26.93,27.69,26.91z"
                />
                <path fill="#598ECB" d="M20.21,37.58c0.09,0.05,0.2,0.08,0.28,0.14c0.45,0.34,0.89,0.68,1.34,1.02c0.09,0.81,0.19,1.62,0.28,2.42
                    c0.01,0.09,0.01,0.17,0.02,0.26c-0.06-0.06-0.13-0.12-0.17-0.19c-0.06-0.1-0.1-0.22-0.15-0.35c-0.09,0.07-0.13,0.13-0.19,0.15
                    c-0.13,0.04-0.29,0.12-0.4,0.08c-0.16-0.06-0.33-0.15-0.28-0.43c0.08-0.39-0.26-0.74-0.72-0.83c-0.01-0.16-0.02-0.31-0.02-0.47
                    C20.2,38.78,20.21,38.18,20.21,37.58z"
                />
                <path fill="#97C1E7" d="M53.85,64.59c-0.02-0.29-0.04-0.58-0.06-0.87c1.11-0.18,2.23-0.35,3.34-0.53c0.31-0.05,0.62-0.15,0.93-0.22
                    c0.1,0.32,0.19,0.64,0.29,0.96c-0.16,0.05-0.31,0.09-0.47,0.14c-0.33,0.07-0.67,0.14-1,0.21c-0.7,0.1-1.39,0.22-2.09,0.3
                    C54.48,64.61,54.16,64.58,53.85,64.59z"
                />
                <path fill="#B0D2EF" d="M48.24,63.54c0.02,0.03,0.03,0.09,0.05,0.1c0.25,0.1,0.37,0.23,0.19,0.5c-0.02,0.03-0.01,0.09-0.01,0.14
                    c-0.66-0.13-1.32-0.24-1.97-0.39c-0.78-0.18-1.57-0.35-2.33-0.59c-0.88-0.27-1.74-0.61-2.6-0.94c-0.13-0.05-0.23-0.18-0.34-0.27
                    c0.01-0.04,0.02-0.08,0.03-0.11c0.13-0.01,0.27-0.05,0.39-0.01c0.82,0.24,1.62,0.53,2.45,0.73c1.31,0.3,2.63,0.53,3.95,0.8
                    C48.11,63.5,48.18,63.52,48.24,63.54z"
                />
                <path fill="#80B1DF" d="M63.61,59.92c0.24,0.2,0.48,0.37,0.81,0.29c0.12-0.03,0.24-0.07,0.36-0.09c0.15-0.03,0.3-0.06,0.45-0.08
                    c-0.04,0.05-0.08,0.11-0.13,0.16c-0.5,0.44-1,0.87-1.5,1.3c0,0,0,0,0,0c-0.02,0.02-0.05,0.04-0.07,0.05
                    c-0.45,0.29-0.91,0.58-1.37,0.86c-0.1,0.06-0.21,0.09-0.32,0.14c-0.08-0.1-0.16-0.2-0.25-0.29c-0.18-0.18-0.38-0.35-0.57-0.52
                    C61.89,61.14,62.75,60.53,63.61,59.92z"
                />
                <path fill="#4F85C5" d="M67.81,47.32c-0.03,0.15-0.06,0.3-0.11,0.45c-0.02,0.07-0.12,0.18-0.15,0.18c-0.09-0.03-0.18-0.1-0.24-0.17
                    c-0.04-0.05-0.04-0.15-0.03-0.22c0.02-0.22-0.05-0.36-0.28-0.43c-0.33-0.11-0.34-0.45-0.41-0.71c-0.11-0.41-0.23-0.55-0.6-0.59
                    c0,0,0,0,0,0c-0.02-0.04-0.03-0.08-0.05-0.12c-0.01-0.02-0.02-0.03-0.02-0.05c0.02-0.04,0.05-0.08,0.07-0.12
                    c0.03-0.01,0.07-0.01,0.08-0.03c0.26-0.52-0.09-1.25-0.55-1.54c-0.08-0.17-0.16-0.35-0.23-0.52c0.23,0,0.46,0,0.68,0.01
                    c0.06,0,0.14,0.06,0.16,0.11c0.13,0.47,0.59,0.7,0.8,1.11c0.13,0.26,0.24,0.52,0.34,0.79c0.07,0.18,0.11,0.37,0.16,0.56
                    C67.56,46.43,67.68,46.88,67.81,47.32z"
                />
                <path fill="#8BB9E4" d="M61.03,61.74c0.19,0.17,0.38,0.34,0.57,0.52c0.09,0.09,0.17,0.19,0.25,0.29c-0.35,0.18-0.68,0.39-1.04,0.53
                    c-0.68,0.27-1.37,0.49-2.06,0.73c-0.02,0-0.04,0-0.06,0.01c-0.12,0.04-0.23,0.07-0.35,0.11c-0.1-0.32-0.19-0.64-0.29-0.96
                    C59.05,62.56,60.04,62.15,61.03,61.74z"
                />
                <path fill="#B0BDD0" d="M45.86,31.63c0.44,0.16,0.82,0.3,1.2,0.44c0.16,0.06,0.33,0.13,0.48,0.21c0.15,0.08,0.29,0.19,0.2,0.39
                    c-0.09,0.2-0.25,0.28-0.45,0.21c-0.41-0.14-0.82-0.3-1.23-0.45c-0.13-0.05-0.27-0.1-0.35-0.19c-0.08-0.08-0.15-0.24-0.12-0.34
                    C45.61,31.79,45.76,31.72,45.86,31.63z"
                />
                <path fill="#5184C4" d="M32.41,51.93c0.4,0.09,0.73,0.26,0.98,0.61c0.11,0.15,0.38,0.19,0.58,0.25c0.49,0.16,1,0.28,1.49,0.46
                    c0.21,0.08,0.41,0.23,0.4,0.51c-0.41-0.1-0.83-0.18-1.24-0.29c-0.15-0.04-0.29-0.14-0.43-0.22c-0.12-0.04-0.24-0.08-0.36-0.13
                    c-0.11-0.05-0.23-0.1-0.34-0.15c-0.1-0.05-0.2-0.1-0.3-0.15C32.87,52.59,32.53,52.34,32.41,51.93z"
                />
                <path fill="#F6FAFC" d="M62.26,37.34c-0.1-0.11-0.19-0.22-0.33-0.38c0.12-0.09,0.22-0.18,0.33-0.27c0.12,0.44,0.44,0.75,0.73,1.07
                    c0.47,0.52,0.98,1,1.31,1.63c0.12,0.23,0.23,0.48,0.34,0.71c-0.28-0.35-0.54-0.7-0.84-1.04C63.3,38.48,62.78,37.92,62.26,37.34z"
                />
                <path fill="#5184C4" d="M22.75,47.37c0.13,0.07,0.3,0.12,0.38,0.23c0.48,0.65,0.96,1.29,1.39,1.97c0.16,0.27,0.43,0.53,0.36,0.9
                    c-0.03-0.02-0.07-0.05-0.1-0.07c-0.47-0.66-0.94-1.33-1.41-1.99C23.16,48.07,22.96,47.72,22.75,47.37z"
                />
                <path fill="#5184C4" d="M62.26,37.34c0.52,0.57,1.04,1.14,1.55,1.72c0.29,0.33,0.56,0.69,0.84,1.04c0,0.03,0.01,0.07,0.01,0.1
                    c-0.14-0.09-0.32-0.16-0.43-0.28c-0.64-0.7-1.25-1.43-1.89-2.13C62.2,37.64,62.24,37.5,62.26,37.34z"
                />
                <path fill="#5184C4" d="M58.65,37.05c0.35,0.12,0.43,0.44,0.55,0.73c0.15,0.38,0.26,0.49,0.66,0.49c0.31,0,0.61-0.04,0.92-0.06
                    c-0.55,0.6-1.05,0.61-1.63,0.12c-0.01-0.01-0.03-0.03-0.04-0.04c-0.06-0.08-0.11-0.17-0.17-0.25c-0.04-0.1-0.08-0.19-0.12-0.29
                    C58.76,37.52,58.7,37.28,58.65,37.05z"
                />
                <path fill="#568BC8" d="M66,45.51c-0.02,0.04-0.05,0.08-0.07,0.12c-0.44-0.99-0.89-1.99-1.33-2.98c0.21,0.1,0.42,0.19,0.48,0.47
                    c0.02,0.11,0.15,0.2,0.22,0.29c0.08,0.17,0.16,0.35,0.23,0.52c0.03,0.11,0.07,0.22,0.1,0.33C65.75,44.68,65.88,45.1,66,45.51z"
                />
                <path fill="#5184C4" d="M39.89,50.31c-0.12,0.03-0.25,0.1-0.37,0.09c-0.57-0.04-1.15-0.11-1.72-0.16c-0.34-0.03-0.59-0.17-0.66-0.4
                    c0.3,0.05,0.6,0.11,0.9,0.16c0.51,0.08,1.02,0.16,1.54,0.25C39.68,50.26,39.78,50.29,39.89,50.31z"
                />
                <path fill="#F6FAFC" d="M63.6,61.5c0.5-0.43,1-0.87,1.5-1.3c0.05-0.04,0.08-0.1,0.13-0.16c0.04-0.02,0.08-0.05,0.11-0.07
                    c0,0.41-0.07,0.77-0.52,0.96c-0.29,0.12-0.53,0.32-0.81,0.47C63.89,61.46,63.74,61.46,63.6,61.5z"
                />
                <path fill="#F6FAFC" d="M23.37,48.41c0.47,0.66,0.94,1.33,1.41,1.99c-0.32-0.01-0.52-0.15-0.6-0.47c-0.02-0.09-0.15-0.16-0.22-0.25
                    c-0.23-0.32-0.45-0.63-0.65-0.96C23.26,48.66,23.34,48.52,23.37,48.41z"
                />
                <path fill="#4B7FC1" d="M63.14,35.72c0.03-0.24-0.08-0.41-0.25-0.58c-0.11-0.11-0.19-0.27-0.28-0.41c0.38,0.08,0.76,0.17,1.24,0.27
                    C63.59,35.26,63.36,35.49,63.14,35.72z"
                />
                <path fill="#F6FAFC" d="M56.88,64.27c0.33-0.07,0.67-0.14,1-0.21C57.69,64.45,57.15,64.57,56.88,64.27z"/>
                <path fill="#F6FAFC" d="M20.19,39.38c0.01,0.16,0.02,0.31,0.02,0.47c0.01,0.12,0.03,0.23,0.04,0.35c-0.28-0.1-0.27-0.35-0.27-0.57
                    C19.98,39.55,20.12,39.47,20.19,39.38z"
                />
                <path fill="#F6FAFC" d="M25.88,52.57c-0.22,0.22-0.44,0.44-0.66,0.66c-0.03-0.03-0.05-0.07-0.08-0.1
                    C25.38,52.95,25.51,52.6,25.88,52.57z"
                />
                <path fill="#598ECB" d="M20.29,40.26c0.28,0.05,0.29,0.11,0.06,0.46C20.33,40.57,20.31,40.41,20.29,40.26z"/>
                <path fill="#F6FAFC" d="M24.88,50.53c0.11,0.14,0.23,0.27,0.34,0.41C24.92,50.96,24.84,50.79,24.88,50.53z"/>
                <path fill="#F6FAFC" d="M22.6,30.92c-0.11,0.16-0.23,0.32-0.34,0.48C22.29,31.19,22.28,30.94,22.6,30.92z"/>
                <path fill="#F6FAFC" d="M20.57,41.89c0.03,0.14,0.07,0.27,0.1,0.41C20.39,42.17,20.37,42.09,20.57,41.89z"/>
                <path fill="#F6FAFC" d="M22.96,30.46c-0.1,0.12-0.2,0.24-0.3,0.36C22.64,30.6,22.7,30.45,22.96,30.46z"/>
                <path fill="#4B7FC1" d="M62.69,36.2c0-0.21,0.09-0.32,0.31-0.31C62.89,35.99,62.79,36.09,62.69,36.2z"/>
                <path fill="#F6FAFC" d="M33.18,52.85c0.1,0.05,0.2,0.1,0.3,0.15C33.31,53.13,33.26,53.1,33.18,52.85z"/>
                <path fill="#F6FAFC" d="M25.23,50.94c0.08,0.1,0.16,0.19,0.23,0.29C25.25,51.24,25.22,51.11,25.23,50.94z"/>
                <path fill="#F6FAFC" d="M58.82,37.75c0.04,0.1,0.08,0.19,0.12,0.29C58.74,38.01,58.7,37.91,58.82,37.75z"/>
                <path fill="#F6FAFC" d="M32.28,51.62c0.03,0.08,0.05,0.16,0.08,0.25C32.21,51.82,32.09,51.77,32.28,51.62z"/>
                <path fill="#F6FAFC" d="M33.82,53.14c0.12,0.04,0.24,0.08,0.36,0.13C34.06,53.23,33.87,53.38,33.82,53.14z"/>
                <path fill="#F6FAFC" d="M20.42,41.18c0.02,0.08,0.04,0.16,0.06,0.25C20.33,41.38,20.25,41.31,20.42,41.18z"/>
                <path fill="#F6FAFC" d="M25.52,51.29c0.07,0.07,0.14,0.15,0.2,0.22C25.65,51.44,25.59,51.36,25.52,51.29z"/>
                <path fill="#F6FAFC" d="M20.27,36.64c-0.01,0.08-0.01,0.16-0.02,0.23C20.1,36.79,20.1,36.71,20.27,36.64z"/>
                <path fill="#F6FAFC" d="M20.21,37.28c0,0.08-0.01,0.16-0.01,0.23C20.04,37.43,20.04,37.36,20.21,37.28z"/>
                <path fill="#F6FAFC" d="M26.05,52.4c-0.06,0.06-0.12,0.12-0.18,0.18C25.93,52.51,25.99,52.45,26.05,52.4z"/>
                <path fill="#F6FAFC" d="M58.23,35.77c0.02,0.06,0.04,0.12,0.06,0.17C58.26,35.89,58.25,35.83,58.23,35.77z"/>
                <path fill="#568BC8" d="M68.01,46.62c-0.03-0.06-0.06-0.11-0.09-0.17C67.95,46.51,67.98,46.56,68.01,46.62z"/>
                <path fill="#5D94CE" d="M67.06,50.47c0,0.07,0.01,0.15,0.01,0.22C67.07,50.62,67.06,50.55,67.06,50.47z"/>
                <path fill="#76A9DB" d="M21.9,32.03c-0.03,0.04-0.06,0.08-0.09,0.12C21.84,32.11,21.87,32.07,21.9,32.03z"/>
                <path fill="#568BC8" d="M65.94,45.69c0.02,0.04,0.03,0.08,0.05,0.12C65.97,45.77,65.96,45.73,65.94,45.69z"/>
                <path fill="#669CD3" d="M68.23,54.68c0.01-0.02,0.02-0.04,0.03-0.07C68.25,54.64,68.24,54.66,68.23,54.68z"/>
                <path fill="#5387C6" d="M21.62,45.05c0.01,0.03,0.03,0.05,0.04,0.08C21.65,45.1,21.63,45.07,21.62,45.05z"/>
                <path fill="#5184C4" d="M61.03,37.94c0.02-0.02,0.04-0.04,0.06-0.07C61.07,37.89,61.05,37.91,61.03,37.94z"/>
                <path fill="#F6FAFC" d="M65.06,40.67c-0.02-0.02-0.03-0.04-0.05-0.07C65.03,40.63,65.04,40.65,65.06,40.67z"/>
                <path fill="#F6FAFC" d="M20.5,41.6c0.01,0.02,0.01,0.04,0.02,0.05C20.51,41.63,20.5,41.62,20.5,41.6z"/>
                <polygon fill="#5184C4" points="60.92,38.06 60.94,38.05 60.94,38.07"/>
                <path fill="#F6FAFC" d="M58.58,36.93c0.01,0.02,0.01,0.03,0.02,0.05C58.59,36.97,58.58,36.95,58.58,36.93z"/>
                <path fill="#5184C4" d="M40.12,50.35c-0.02,0-0.03,0-0.04-0.01C40.1,50.33,40.11,50.33,40.12,50.35z"/>
                <path fill="#F6FAFC" d="M58.41,36.39c0.01,0.03,0.02,0.05,0.03,0.08C58.43,36.44,58.42,36.41,58.41,36.39z"/>
                <path fill="#F6FAFC" d="M58.29,35.99c0.01,0.02,0.02,0.05,0.03,0.07C58.32,36.03,58.3,36.01,58.29,35.99z"/>
                <path fill="#F6FAFC" d="M63.53,61.55c0.02-0.02,0.05-0.04,0.07-0.05C63.58,61.51,63.55,61.53,63.53,61.55z"/>
                <path fill="#5184C4" d="M60.8,38.18c0.02-0.02,0.05-0.05,0.07-0.07C60.85,38.13,60.83,38.15,60.8,38.18z"/>
                <path fill="#6A9FD5" d="M20.61,35.07c-0.02,0.02-0.03,0.04-0.05,0.06C20.58,35.11,20.6,35.09,20.61,35.07z"/>
                <path fill="#F6FAFC" d="M60.19,34.14l-0.02,0.01c0,0-0.01-0.01-0.01-0.01C60.17,34.13,60.18,34.14,60.19,34.14z"/>
                <path fill="#F6FAFC" d="M59.1,38.29c0.01,0.01,0.03,0.03,0.04,0.04C59.12,38.32,59.11,38.31,59.1,38.29z"/>
                <path fill="#76A9DB" d="M21.96,31.92c-0.01,0.02-0.03,0.03-0.04,0.05C21.93,31.96,21.95,31.94,21.96,31.92z"/>
                <path fill="#97C1E7" d="M58.7,63.82c0.02,0,0.04,0,0.06-0.01c0,0-0.03,0.01-0.03,0.01L58.7,63.82z"/>
                <path fill="#5184C4" d="M48.7,30.23c0.02,0.02,0.04,0.05,0.06,0.07C48.74,30.27,48.72,30.25,48.7,30.23z"/>
                <path fill="#568BC8" d="M68.13,47.14c-0.01-0.02-0.02-0.04-0.04-0.05C68.11,47.11,68.12,47.12,68.13,47.14z"/>
                <path fill="#4B7FC1" d="M66,45.51c-0.12-0.41-0.24-0.83-0.36-1.24c-0.03-0.11-0.07-0.22-0.1-0.33c0.46,0.3,0.81,1.03,0.55,1.54
                    C66.07,45.5,66.02,45.5,66,45.51z"
                />
            </g>
        </svg>
    );
}

export default CircleIcon;
