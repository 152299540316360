import {SmhApi} from "./SmhApi";
import {PaginatedResult, PaginationRequest} from "./ProjectModels";
import {
    MilestoneProductStep,
    MilestoneProductStepFilter, MilestoneStepAssign
} from "./MilestoneProductModels";
import {useMilestoneProductStepsStore} from "./MilestoneProductStepsStore";

export class MilestoneProductStepsService {
    public static async getMilestoneProductSteps(productId: string, pagination: PaginationRequest, filter: MilestoneProductStepFilter) {
        return SmhApi.getMilestoneProductSteps(productId, pagination, filter, (result: PaginatedResult<MilestoneProductStep>) => {
            useMilestoneProductStepsStore.setState({milestoneProductSteps: result.items});
            useMilestoneProductStepsStore.setState({paginationResult: result.pagination});
        });
    }

    public static async getMilestoneProductStepCandidates(productId: string, productStepId: string, pagination: PaginationRequest, filter: MilestoneProductStepFilter) {
        return SmhApi.getMilestoneCandidates(productId, productStepId, pagination, filter);
    }

    public static async assignMilestone(productId :string, assignMilestone: MilestoneStepAssign) {
        return SmhApi.assignMilestone(productId, assignMilestone);
    }
}
