import React, {useEffect, useState} from 'react';
import {
    Button,
    HelperText,
    HelperTextItem,
    Modal,
    ModalVariant,
    Text,
    TextContent,
    TextVariants,
    Tooltip
} from '@patternfly/react-core';
import {useFilesStore, useProjectStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {ProjectAddOnService} from "../../api/ProjectAddOnService";
import {ProjectAddOnType} from "../../api/SmhProjectModels";
import {EventBus} from "../../designer/utils/EventBus";


export const RestBasicAuthenticationAddonToggleButton: React.FunctionComponent = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [project] = useProjectStore((state) => [state.project], shallow )
    const [files] = useFilesStore((s) => [s.files], shallow);
    const [isBasicAuthenticationEnabled, setIsBasicAuthenticationEnabled] = useState(false);

    const basicAuthenticationFileNames = ['basic_auth_credentials.conf' , 'RestBasicAuthentication.java']

    const handleModalToggle = (_event: KeyboardEvent | React.MouseEvent) => {
        setIsModalOpen(!isModalOpen);
    };

    const calculateIsBasicAuthenticationEnabled = () => {
        return files.filter(f => basicAuthenticationFileNames.includes(f.name)).length === 2;
    }

    const getModalTitle = () => {
        return isBasicAuthenticationEnabled ? "Warning" : "Info";
    }

    const getModalTitleIconVariant = () => {
        return isBasicAuthenticationEnabled ? "danger" : "info";
    }

    useEffect(() => {
        setIsBasicAuthenticationEnabled(calculateIsBasicAuthenticationEnabled())
    }, []);

    const handleAddOnToggleConfirmed = (_event: KeyboardEvent | React.MouseEvent) => {
        if(!isBasicAuthenticationEnabled) {
            ProjectAddOnService.enableAddOn(project.projectId, ProjectAddOnType.REST_BASIC_AUTHENTICATION);
            EventBus.sendAlert( "Success", "REST Basic Auth add-on successfully enabled", "success");

            setIsBasicAuthenticationEnabled(true);
        } else {
            ProjectAddOnService.disableAddOn(project.projectId, ProjectAddOnType.REST_BASIC_AUTHENTICATION);
            EventBus.sendAlert( "Success", "REST Basic Auth add-on successfully disabled", "success");

            setIsBasicAuthenticationEnabled(false);
        }

        setIsModalOpen(!isModalOpen);
    };

    return (
        <React.Fragment>
            {isBasicAuthenticationEnabled &&
                <Tooltip content={"Disable REST Basic Auth Add-On"} position={"bottom"}>
                    <Button variant="danger" onClick={handleModalToggle} >
                        Disable
                    </Button>
                </Tooltip>
            }
            {!isBasicAuthenticationEnabled &&
                <Tooltip content={"Enable REST Basic Auth Add-On"} position={"bottom"}>
                    <Button variant="primary" onClick={handleModalToggle}>
                        Enable
                    </Button>
                </Tooltip>
            }
            <Modal
                variant={ModalVariant.medium}
                title={getModalTitle()}
                titleIconVariant={getModalTitleIconVariant()}
                isOpen={isModalOpen}
                aria-describedby="modal-title-icon-description"
                onClose={handleModalToggle}
                actions={[
                    <Button key="confirm" variant="primary" onClick={handleAddOnToggleConfirmed}>
                        Confirm
                    </Button>,
                    <Button key="cancel" variant="link" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                {!isBasicAuthenticationEnabled &&
                    <TextContent>
                        <Text component={TextVariants.h3}>Are you sure you want to enable REST Basic Auth Add-On?</Text>
                    </TextContent>
                }
                {isBasicAuthenticationEnabled &&
                    <TextContent>
                        <Text component={TextVariants.h3}>Are you sure you want to disable REST Basic Auth Add-On?</Text>
                        <HelperText>
                            <HelperTextItem variant="warning">
                                Integration will fail to build and stop functioning if it's setup to use REST Basic authentication.
                            </HelperTextItem>
                        </HelperText>
                    </TextContent>
                }
            </Modal>
        </React.Fragment>
    );
};
