/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";

function Icon(className?: string) {
    return (
        <svg
            version="1.1"
            id="svg50"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 400 252.3"
            enableBackground="new 0 0 400 252.3"
            xmlSpace="preserve"
            className={className ? className : "logo"}
        >
        <g id="svgg">
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M175.7,1.7c-2.8,0.2-10,0.8-13.9,1.2c-2.5,0.2-7,0.6-9.9,0.9
                c-6,0.5-6.5,0.6-7.8,1.7c-2.1,1.9-2,2.9,1.9,9.5c3.8,6.6,3.8,6.5,0.5,7.9c-1.1,0.4-2.9,1.3-4.1,1.9l-2.2,1.1h-1.9
                c-2.1,0-1.7,0.1-6.1-1.9c-0.6-0.3-2.2-1-3.6-1.7c-1.4-0.6-3.3-1.5-4.2-1.9c-6.1-2.9-9.5-2.5-12.2,1.3c-5.2,7.4-10.8,15.6-11.1,16
                c-1.2,2.3,0.9,5.9,4.4,7.3c0.5,0.2,1.6,0.7,2.4,1.1c0.8,0.4,2.1,1,2.9,1.3c7.1,3.1,8.4,3.9,9.3,4.9c0.7,0.9,0.8,1,0.8,5.1
                c0,5.6,1.2,5-12,6.1c-6.4,0.5-8.4,1.9-7.8,5.2c0.1,0.8,0.3,1,1.8,3.7c0.5,0.8,1.7,3,2.8,4.8c1.1,1.8,3.4,5.8,5.1,8.7
                c5.7,9.7,6.2,10,18.7,8.9c9.2-0.8,9.5-0.7,13.4,2.6c1.1,0.9,3,2.5,4.2,3.5c3.9,3.2,3.9,3,0.5,7.9c-3,4.3-3.3,4.7-3.3,5.9
                c0,2.3,1.9,4.5,5.2,6c1.6,0.7,2.5,1.1,11.3,5.2c3.1,1.4,6.6,3,7.8,3.5c1.1,0.5,2.9,1.3,3.9,1.8c7,3.2,7.3,3.3,9.9,3.2
                c3.1-0.1,3.5-0.4,7.1-5.5c3.8-5.4,3.9-5.5,10.1-4.1c1.6,0.4,4.1,0.8,5.6,1.1c3.5,0.6,4.6,1,6.1,2.6c0.6,0.7,0.8,1,3.7,6
                c2.8,4.9,3.7,6,5.9,7.1c2.9,1.4,4.5,1.4,24.4-0.5c1.9-0.2,5-0.5,6.9-0.6c5.7-0.5,7.4-1.5,7.4-4.5c0-1.1,0.2-0.8-3.4-6.9
                c-3.5-6.2-3.5-6.3-0.8-7.4c0.6-0.3,2.4-1.1,3.9-1.8c4.4-2.1,5.3-2.1,10.2,0.1c1.4,0.6,3.2,1.5,4.1,1.9s2.5,1.2,3.7,1.7
                c4.1,1.9,4.6,2,6.7,2.1c3.1,0.1,4.5-0.5,6.1-2.8c1.1-1.6,5.7-8.1,8.9-12.7c3.2-4.6,3.3-5,2.2-7.1c-1.2-2.4-1.8-2.8-8.8-5.9
                c-0.8-0.4-2.1-0.9-2.8-1.3c-0.8-0.3-1.8-0.8-2.4-1.1c-3-1.3-4.4-2.1-5.2-3.4l-0.5-0.7v-4c0-5.6-1.1-5.1,12.6-6.2
                c4.7-0.4,5.7-0.7,6.8-2.5c1.1-1.8,0.9-2.4-2.5-8.3c-0.9-1.6-2.1-3.6-2.6-4.4c-0.5-0.9-1.4-2.4-1.9-3.3s-1.9-3.2-3-5.1
                c-3-5.1-3.6-5.8-5.9-7c-2.6-1.3-3.4-1.3-12-0.6c-9.1,0.8-9.4,0.7-13.5-2.8c-0.9-0.8-2.6-2.2-3.8-3.2c-4.1-3.3-4.1-3.2-0.6-8.1
                c3.2-4.5,3.2-4.6,3.2-5.8c0-2.8-1.6-4.3-7-6.7c-1.7-0.8-3.9-1.8-4.8-2.2c-0.9-0.4-1.6-0.7-1.6-0.7s-0.7-0.3-1.5-0.7
                c-1.3-0.6-6.5-3-10.6-4.8c-1-0.4-3.2-1.5-5-2.3c-4.2-1.9-5.2-2.2-7.3-2.2c-3.3,0-3.9,0.4-7.4,5.5c-2.9,4.2-3.1,4.4-4.7,4.8
                l-1.1,0.3l-4.4-0.9c-2.4-0.5-5.2-1-6.2-1.2c-3.5-0.5-5.2-1.6-6.6-4.2C185,5,183.9,3.6,180.8,2.4c-0.7-0.3-1.2-0.5-1.3-0.6
                C179.5,1.5,177.3,1.5,175.7,1.7"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M0.1,127.4c0,68.2,0,96,0,61.9s0-89.9,0-123.9S0.1,59.3,0.1,127.4
                "/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M180.6,4.7c2.3,1.1,2.5,1.3,6.6,8.6c3.3,5.8,4.3,6.5,10.8,7.5
                c2,0.3,4.5,0.8,5.6,1.1c5.9,1.4,7,0.9,11.3-5.3c3.1-4.5,3.1-4.5,5.7-4.5c2,0,1.9,0,5.5,1.6c1.3,0.6,3,1.4,3.9,1.8
                c0.9,0.4,3.5,1.6,5.8,2.7s4.9,2.2,5.8,2.7c0.9,0.4,2.6,1.2,3.8,1.7s2.8,1.3,3.6,1.6c5.3,2.4,6.3,3,6.9,4.5c0.4,1,0.3,1.2-3.8,7
                c-2.9,4.1-2.6,5.6,2.1,9.4c1.3,1.1,3.2,2.6,4.1,3.4c4.2,3.7,5.4,4,14,3.2c8.5-0.8,8.4-0.8,9.6-0.5c2.8,0.6,4.3,1.9,6.5,5.7
                c1.6,2.7,4.7,8.2,8.5,14.8c2.6,4.5,2.5,4.3,2.4,5c-0.3,1.5-0.6,1.6-11.2,2.5c-8.5,0.7-8.8,0.9-8.7,7.9c0.1,6.1,0.6,7.1,5.3,9.1
                c1.7,0.8,3.2,1.4,8.2,3.7c1.3,0.6,2.7,1.2,3.2,1.4c1.7,0.7,3.3,2.2,3.6,3.5c0.1,0.6,0,0.7-5.1,8.1c-1.5,2.1-3.4,4.9-4.3,6.2
                c-3,4.3-3,4.3-4.9,4.5s-2.9-0.1-10.8-3.7c-10.2-4.6-10.5-4.7-16-1.9c-1.6,0.8-3.4,1.6-4.1,1.9c-2.1,0.8-3.1,2.1-3.1,3.9
                c0,1.2,0.2,1.6,3.2,6.7c3.2,5.6,3.3,5.8,2.5,6.6c-0.7,0.8-1.2,0.9-8.6,1.6c-3.9,0.3-10.7,0.9-15.1,1.3c-11.1,1-12.7,0.6-15.2-4
                c-6.1-10.9-6.2-11-14.4-12.5c-2.2-0.4-4.7-0.9-5.6-1.1c-4.2-1-6.3-0.3-8.6,3c-4.5,6.4-4.5,6.4-5.3,6.7c-1.8,0.7-4,0.4-7-1
                c-0.8-0.3-2.5-1.2-3.9-1.8s-3-1.4-3.6-1.6c-0.9-0.4-7.8-3.6-14.5-6.6c-1.1-0.5-2.9-1.3-3.9-1.8c-3.1-1.4-3.9-2-4.7-3.6
                c-0.4-0.8-0.4-0.7,2.7-5.2c3.2-4.6,3.4-5.1,2.7-6.9c-0.5-1.3-1.3-2.2-3.4-3.9c-1.1-0.9-3-2.4-4.2-3.5c-4.7-4-5.5-4.2-16.4-3.2
                c-6.2,0.6-6.7,0.6-8.4,0.1c-2.5-0.7-3.4-1.7-6.5-7.1c-1.2-2-3.6-6.3-5.5-9.6c-4.4-7.7-4.6-8-4.5-8.5c0.3-1.3,1.2-1.7,5.2-2
                c12.2-1.1,12.1-1,13.5-2.2c1.3-1.1,1.3-1.1,1.2-5.9c-0.1-6.5-0.5-7.3-5.5-9.5c-2.9-1.3-9.7-4.4-11.6-5.3c-1.9-0.9-3.2-2.4-3.2-3.5
                c0-0.2,0.8-1.5,1.8-3c11.4-16.3,10.3-14.9,11.6-15.3c1.9-0.6,3.5-0.2,7.4,1.6c14.7,6.7,14.6,6.7,19.3,4.3c1-0.5,2.5-1.2,3.2-1.5
                c3.6-1.6,3.7-1.7,4.3-2.5c1.3-1.7,1-2.8-1.6-7.3c-4.3-7.4-4.3-7.4-3.4-8.3c1-0.9-0.1-0.7,13.6-1.9c3.9-0.3,9.2-0.8,11.8-1
                s5.1-0.4,5.5-0.5C177.8,3.8,179.3,4.1,180.6,4.7"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M216.2,26.8c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C216.1,26.7,216,26.7,216.2,26.8"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M221.6,32c0,0.3,0,0.5,0.1,0.3c0-0.2,0-0.4,0-0.6
                C221.6,31.5,221.6,31.6,221.6,32"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M248.1,49.1l-0.5,0.6L248.1,49.1c0.6-0.4,0.7-0.6,0.6-0.6
                C248.6,48.5,248.4,48.8,248.1,49.1"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M193,96.7c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                S192.9,96.7,193,96.7"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M195.2,96.9c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1S195,96.8,195.2,96.9
                "/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M188.8,171.6c-2.2,1.3-2.3,4.7-0.2,6c2.7,1.6,5.9-0.3,5.5-3.4
                C193.9,171.6,191.1,170.3,188.8,171.6"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M297,171.6c-2,1-3.1,2.8-2.9,5.1c0.4,5.2,7.5,6.6,9.8,1.9
                C306.1,174.2,301.5,169.5,297,171.6"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M116,171.7c-5,1.1-7.8,4.9-7.1,9.4c0.6,4,2.9,6,9.4,8.2
                c4.2,1.4,5.5,2.5,5.3,4.3c-0.5,4.1-8.7,3.6-11.7-0.7l-0.3-0.5l-2.3,1.6c-2.7,1.8-2.6,1.6-1.6,3c2.6,3.6,6.8,5.4,12.3,5.1
                c6.5-0.3,10.2-3.5,10.2-9c0-5-2.7-7.4-11.6-10.3c-2.5-0.8-3.3-1.6-3.3-3.1c0-2.9,5.9-3.4,8.5-0.8l0.6,0.6l2.1-1.6
                c2.5-1.9,2.4-1.7,1.2-2.9C124.8,172.1,120.3,170.8,116,171.7"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M301.4,172.3c3.5,1.8,2.9,7.4-0.9,8.4c-3.9,1-6.9-2.6-5.2-6.3
                C296.3,172,299.1,171.1,301.4,172.3"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M297.4,176.2v3h0.4c0.4,0,0.4-0.1,0.4-1.1V177l0.5-0.1
                c0.5,0,0.6,0,1.2,1.1c0.6,0.9,0.8,1.1,1.1,1.1c0.5,0,0.5,0-0.2-1.1c-0.7-1.2-0.7-1.1-0.2-1.7c1.4-1.4,0.1-3.2-2.2-3.2h-1.1v3.1"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M300.2,174.4c0.7,0.8,0.1,1.8-1.1,1.8c-0.4,0-0.8-0.1-0.8-0.1
                c-0.1-1.1-0.1-1.9-0.1-2C298.4,173.8,299.8,174,300.2,174.4"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M257.1,179.7c-11.5,2.1-13,18.1-2,21.9c4.5,1.6,9.2,0.7,12.5-2.5
                c0.9-0.9,1-0.7-0.9-2.6c-2.4-2.3-2.1-2.2-3.1-1.4c-5.7,4.5-13.1-2.7-8.3-8.2c1.9-2.2,6.3-2.3,8.5-0.1l0.6,0.5l1.2-1.2
                c3.2-3.1,3.1-2.7,1.5-3.9C264.5,180.1,260.6,179,257.1,179.7"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M206.1,179.9c-6.6,1.5-10.1,7.7-8.4,14.5
                c2.1,8.1,13.3,10.6,20.2,4.4l0.5-0.5l-1.8-1.8l-1.8-1.8l-0.9,0.7c-3.5,2.7-9.5,1.3-10.1-2.3l-0.1-0.6h8.2h8.2v-1.8
                C220,183.2,213.6,178.2,206.1,179.9"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M234.4,179.8c-1.6,0.3-2.7,0.9-3.7,1.9l-1,1l-0.1-1.2v-1.2h-3.1
                h-3.1v10.8v10.8h3.2h3.2l0.1-6.8c0-6.1,0.1-6.9,0.3-7.3c1.6-3,6.3-3,7.6,0c0.2,0.5,0.3,1.7,0.3,7.4v6.8h3.2h3.2v-7.4
                c0-8,0-8.5-0.9-10.3C241.9,180.8,238.2,179.1,234.4,179.8"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M278.7,179.9c-7.4,1.6-11,9.7-7.5,16.7c3.2,6.4,13.2,7.7,19.2,2.4
                l0.6-0.5l-1.8-1.8l-1.8-1.8l-0.9,0.7c-3.6,2.7-10.2,1.1-10.2-2.5v-0.4h8.2h8.2v-1.9C292.6,183.2,286.3,178.2,278.7,179.9"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M140.7,180.1c-11.5,3-10.4,20.7,1.4,21.9c3.3,0.4,6.9-0.9,7.4-2.6
                c0.2-0.7,0.4-0.1,0.4,1v1.2h3.1h3.1v-10.7v-10.7H153h-3.1v1.3c0,1.4-0.1,1.6-0.6,0.8C148.2,180.4,143.8,179.3,140.7,180.1"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M170.8,180.1c-1.4,0.4-2.5,1-3.3,1.9l-0.7,0.8v-1.3v-1.3h-3.2
                h-3.2v15v15h3.2h3.2v-5.5c0-4.3,0.1-5.4,0.2-5.2c2.3,3.6,9.4,3.7,13.2,0.2C188.7,191.9,181.6,177.2,170.8,180.1"/>
            <polyline fillRule="evenodd" clipRule="evenodd" fill="#27426F" points="187.5,190.9 187.5,201.7 190.7,201.7 193.8,201.7 
                193.8,190.9 193.8,180.2 190.7,180.2 187.5,180.2 187.5,190.9 	"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M210.7,185.1c1.7,0.6,2.7,1.6,2.9,2.8l0.1,0.6h-5h-5v-0.4
                C203.8,186,207.9,184.1,210.7,185.1"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M283.4,185.1c1.7,0.6,3,1.9,3,3v0.5h-5h-5v-0.4
                C276.4,186,280.6,184.2,283.4,185.1"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M146.9,186c5.5,2.5,3.7,10.3-2.4,10.3c-3.5,0-5.5-1.9-5.5-5.3
                C139.1,186.8,143,184.3,146.9,186"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M174.6,186.1c2,1,3.3,3.1,3.1,5.2c-0.5,6.8-10.8,6.8-11.2,0
                C166.3,187.1,170.8,184.2,174.6,186.1"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M240.7,212.2c-1.6,0.5-2.6,2.5-2.2,4.3c0.7,2.9,4.9,3.6,6.5,1.1
                C246.8,214.6,244,211.1,240.7,212.2"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M125,212.3c-4.1,0.5-6,2.8-6.3,7.1l-0.1,1.7h-1.8H115v2.9v2.9h1.8
                h1.8v7.9v7.9h3h3v-7.9v-7.9h2.2h2.2v-2.8v-2.8h-2.2h-2.2l0.1-1c0.2-2.1,0.9-2.6,3.4-2.6l1.5,0.1v-2.6v-2.6l-1.2-0.1
                C127.1,212.1,126.3,212.1,125,212.3"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M2.6,227.7v14.9h3h3l0.1-9.1l0.1-9.1l2.4,3.5
                c5.4,7.9,7.2,10.5,7.3,10.4c0.1,0,2.2-3.2,4.8-7l4.7-6.9v9.2v9.2h3.1h3.1v-15v-15h-2.8h-2.8l-5,7.6c-2.8,4.2-5.1,7.6-5.2,7.6
                s-2.4-3.4-5.2-7.6l-5.1-7.6H5.3H2.5L2.6,227.7"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M182.7,216.9v4.1h-1.4H180v2.9v2.9h1.3h1.3l0.1,5.3
                c0.1,6.3,0.3,7.4,1.7,9c1.3,1.5,3.5,2.2,6.6,2.1c2.4-0.1,2.2,0.1,2.2-3v-2.7h-1.3c-2.9,0-3.1-0.4-3.1-6.3v-4.3h2.4h2.4V224v-3h-2.4
                h-2.4v-4.1v-4.1h-3.1h-3.1v4.1"/>
            <polyline fillRule="evenodd" clipRule="evenodd" fill="#27426F" points="319.8,227.8 319.8,242.7 323.1,242.7 326.3,242.7 
                326.3,236.3 326.3,229.9 332.9,229.9 339.5,229.9 339.5,236.3 339.5,242.7 342.7,242.7 346,242.7 346,227.8 346,212.8 342.7,212.8 
                339.5,212.8 339.5,218.4 339.5,223.9 332.9,223.9 326.3,223.9 326.3,218.4 326.3,212.8 323.1,212.8 319.8,212.8 319.8,227.8 	"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M375.8,227.7v14.9h3.1h3.1v-1.3c0-1.5,0.1-1.5,0.7-0.6
                c1,1.4,3.8,2.3,6.6,2.2c10.4-0.6,13.7-14.5,4.9-20.8c-3.3-2.3-9.8-1.8-11.6,0.8c-0.6,0.9-0.6,0.7-0.6-4.9v-5.4h-3.2h-3.2
                L375.8,227.7"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M167.9,220.5c-9.4,1.1-13.7,12.2-7.3,18.9
                c4.4,4.7,12.2,5.1,16.7,0.8l1.2-1.1l-2-1.9l-2-1.9l-0.9,0.7c-4.1,3.3-9.7,0.9-9.7-4.2c0-5.2,5.4-7.6,9.7-4.2l0.8,0.6l1.4-1.3
                c0.8-0.7,1.7-1.6,2-1.9l0.6-0.6l-0.6-0.6C175.6,221.4,171.7,220.1,167.9,220.5"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M46.9,220.9c-12.1,2.8-10.8,21.4,1.5,22.1
                c2.8,0.2,5.6-0.8,6.6-2.2c0.6-0.9,0.7-0.9,0.7,0.6v1.3h3.2h3.2v-10.8v-10.8H59h-3.1v1.4l-0.1,1.4l-0.4-0.6
                C53.9,221.3,50,220.2,46.9,220.9"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M76.6,220.9c-1.6,0.4-3.6,1.8-3.6,2.5c0,0.1-0.1,0.2-0.2,0.2
                s-0.2-0.5-0.2-1.3V221h-3.2h-3.2v10.9v10.9h3.2h3.2v-6.4c0-7.4,0-7.6,1.3-8.9c2.2-2.2,6-1.4,6.8,1.3c0.2,0.7,0.3,2.1,0.3,7.4v6.6
                h3.1h3.1v-6.9c0.1-9.2-0.2-10.5-2.2-12.7C83.3,221,79.7,220.1,76.6,220.9"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M139.4,220.9c-7.8,1.4-11.3,11.1-6.5,18
                c3.3,4.7,11.6,5.7,14.5,1.8l0.5-0.7l0.1,1.3l0.1,1.3h3.1h3.1v-10.9V221h-3.1h-3.1l-0.1,1.3l-0.1,1.3l-0.5-0.7
                C146,221.3,142.5,220.3,139.4,220.9"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M231.3,221c-1.5,0.5-3.4,1.9-3.4,2.6c0,0.1-0.1,0.2-0.2,0.2
                c-0.1,0-0.2-0.6-0.2-1.4V221h-3.1h-3.1v10.8v10.8h3.2h3.2l0.1-6.2c0-5.9,0.1-6.2,0.4-7c1-2.2,2.4-3,5.4-3h2.1v-2.9v-2.9h-1.6
                C232.7,220.7,232,220.8,231.3,221"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M259.7,221c-1.5,0.5-2.3,1-3,1.8l-0.7,0.8l-0.1-1.2l-0.1-1.2h-3.1
                h-3.1v10.9V243h3.2h3.2v-6.6c0-6.6,0-6.6,0.4-7.5c1.4-3.1,5.9-3.3,7.5-0.4c0.4,0.7,0.4,0.9,0.4,7.6l0.1,6.9h3.1h3.1v-7.4
                c0-9.2-0.3-10.6-2.5-12.6C266.1,220.9,262.4,220.1,259.7,221"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M282.1,221c-8.1,1.6-11.2,12.8-5.1,18.8
                c3.6,3.5,10.2,4.1,13.4,1.2l0.7-0.7v0.9c0,1.4-0.5,3-1.3,3.8c-2.2,2.1-7.3,2.1-10.9,0.1l-0.8-0.5l-1.4,2.1
                c-0.8,1.1-1.4,2.2-1.5,2.3c-0.1,0.3,1.1,1.1,2.7,1.7l1.1,0.5h5.9h5.9l1.2-0.6c2.4-1.2,4.1-3.4,4.8-6.3c0.3-1.1,0.3-2.3,0.4-12.3
                l0.1-11.1h-3.1h-3.1l-0.1,1.2l-0.1,1.2l-0.8-0.8C288.6,221,285.5,220.3,282.1,221"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M91.3,228.8c0.1,8.3,0.1,8.3,1,10.5c2,4.4,9.7,5.4,12.5,1.7
                l0.6-0.8v1.3v1.3h3.2h3.2v-10.9V221h-3.2h-3.2l-0.1,6.8c0,6.8-0.1,6.8-0.5,7.6c-1.1,2.3-4.5,2.9-6.3,1.1c-1.1-1.1-1.1-1.4-1.1-8.9
                V221h-3.2H91L91.3,228.8"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M196.4,228.3c0,9.2,0.3,10.9,2.5,12.9c3.1,2.9,9.2,2.5,11.4-0.7
                c0.3-0.4,0.3-0.4,0.3,0.9v1.3h3.1h3.1v-10.8v-10.8h-3.1h-3.1l-0.1,6.8c-0.1,6.6-0.1,6.8-0.4,7.5c-1.7,3.1-6.2,3-7.2-0.2
                c-0.1-0.5-0.2-2.8-0.2-7.4V221h-3.2h-3.2L196.4,228.3"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M238.9,221.1c-0.1,0.1-0.1,4.9-0.1,10.9v10.7h3.2h3.2v-10.9V221
                h-3C240.4,221,239,221.1,238.9,221.1"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M351,221.3c-0.2,0.6,0,14.9,0.3,16c0.7,2.6,2,4.3,4,5.2
                c3.2,1.5,7.6,0.7,9.3-1.6l0.5-0.7v1.3l0.1,1.3h3.1h3.1v-10.9V221h-3.1h-3.1l-0.1,6.9l-0.1,6.9l-0.5,0.8c-1.8,2.9-6.3,2.4-7.1-0.9
                c-0.1-0.4-0.2-3.6-0.2-7.2V221h-3.1C351.7,221,351.1,221.1,351,221.3"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M52.8,226.9c3.3,1.6,4.3,5.5,2,8.3c-2.1,2.5-6.1,2.8-8.4,0.6
                C42,231.5,47.1,224.3,52.8,226.9"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M145.1,227c5.3,2.6,3.5,10.3-2.5,10.3c-5.9,0-7.8-7.7-2.6-10.3
                C141.6,226.2,143.4,226.2,145.1,227"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M288.1,226.9c3.2,1.4,4.3,5.4,2.3,8c-3.3,4.4-10.3,2.3-10.3-3
                C280.1,227.8,284.2,225.3,288.1,226.9"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#27426F" d="M389.9,227c4.8,2.4,3.8,9.4-1.4,10.3c-5.1,0.8-8.7-4.6-5.6-8.6
                C384.5,226.5,387.5,225.8,389.9,227"/>
            <path id="path1" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M0.1,126.2v126.2h200h200V149V45.7l-0.5-0.1
                c-0.6-0.1-1.2-0.7-1.2-1.2c0-0.3,0.3-0.4,0.4-0.1c0.1,0.1,0.4,0.2,0.7,0.2h0.6l-0.6-0.2c-0.7-0.2-1.2-0.7-1.2-1.2
                c0-0.3,0.1-0.3,0.6-0.1c0.3,0.1,0.8,0.2,0.9,0.1c0.2-0.1,0.1-0.2-0.3-0.2c-1.6-0.4-1.6-2.5,0-2.8L400,40V20V0H200H0.1V126.2
                 M179.7,1.5c0,0.1,0.4,0.3,0.8,0.5c2.4,0.9,4.3,2.6,5.7,5c0.5,0.9,1.8,3.1,2.8,4.9c3.1,5.4,3.3,5.5,11.1,6.8c1.7,0.3,3.9,0.7,4.9,1
                c3.8,0.9,4.4,0.6,8-4.5c3.7-5.3,4.2-5.7,8.1-5.5c2.2,0.1,3,0.3,7.3,2.3c1.2,0.6,3.5,1.6,5.1,2.3s3.8,1.7,4.9,2.2
                c2.7,1.2,7.6,3.5,11.4,5.2c6.3,2.8,7.3,3.5,8.4,5.8c0.9,1.9,0.7,2.7-1.3,5.5c-5.3,7.5-5.3,6.7-0.6,10.5c1.5,1.2,3.3,2.8,4.1,3.4
                c3.3,2.9,3.8,3,13.8,2.1c11.3-1,12.4-0.5,17.7,8.8c1.6,2.8,3.3,5.7,3.7,6.5c0.5,0.8,1.1,2,1.5,2.6s1.5,2.6,2.5,4.4
                c2.5,4.2,2.7,5.2,1.6,7c-1.2,1.9-2.1,2.2-8.9,2.7c-9.5,0.8-8.9,0.7-9.6,1.2l-0.7,0.5v3.9c-0.1,5.7-0.3,5.4,7.1,8.7
                c0.9,0.4,2.6,1.2,3.8,1.7c1.1,0.5,2.9,1.3,3.8,1.7c3.8,1.7,5.6,3.8,5.4,6.4c-0.1,1.4,0,1.2-4,6.9c-1.6,2.4-4.2,5.9-5.6,8
                c-3.6,5.2-4.1,5.6-7.2,5.7c-2.5,0.1-3.8-0.3-10.6-3.5c-0.9-0.4-1.6-0.7-1.7-0.7c0,0-0.8-0.3-1.7-0.7c-6.3-3-7.2-3.1-11-1.1
                c-1.1,0.6-2.8,1.4-3.8,1.8c-3.4,1.4-3.4,1.3,0,7.2c3.2,5.5,3.4,5.9,3.5,6.9c0.2,1.8-0.9,3.5-2.8,4.3c-0.9,0.4-1.6,0.5-8.5,1.1
                c-4.1,0.3-8.3,0.7-9.1,0.8c-15.5,1.6-17.7,1.4-20.9-1.8c-1.1-1.1-1.4-1.5-5.9-9.5c-0.9-1.5-2.2-2.9-3.4-3.4
                c-0.8-0.4-1.1-0.5-5.7-1.3c-1.4-0.2-3.7-0.7-5.1-1c-4.7-0.9-5.1-0.7-8,3.4c-3.5,4.9-3.6,5.1-4.4,5.6c-2.5,1.5-5.6,1.3-9.9-0.7
                c-4.3-2-6.1-2.8-8.6-3.9c-1.4-0.6-3.1-1.4-3.8-1.7c-1.4-0.7-10-4.6-12.6-5.7c-4.1-1.9-5.5-2.9-6.5-4.8c-1.1-2.2-0.9-2.7,2.6-7.8
                c3.8-5.5,4.3-4.4-5.8-12.5c-2.6-2.1-3.6-2.2-11.8-1.5c-12,1-13.5,0.4-17.6-6.5c-0.9-1.6-2.5-4.4-3.7-6.2
                c-7.2-12.3-7.5-12.9-7.7-13.7c-0.3-1.7,0.9-3.6,2.6-4.3c1.1-0.5,2-0.6,9.5-1.2c8.5-0.7,7.8-0.2,7.8-5.5c0-5.5,0.3-5.2-7.9-8.9
                c-8.8-4-9.2-4.2-10.3-5.2c-2.5-2.4-2.7-4.4-0.7-7.3c1.3-1.9,8.5-12.3,9.9-14.2c2-2.9,2.8-3.3,6.2-3.3c2.7,0,2.9,0.1,7.2,2
                c1.4,0.6,3.4,1.6,4.5,2.1s3.1,1.4,4.4,2l2.3,1.1h1.9h1.9l2.8-1.4c1.6-0.8,3.4-1.6,4.1-1.9c2.2-0.9,2.3-1.5,0.6-4.3
                c-5.2-8.9-5.4-9.2-4.9-11c0.6-2.3,2.4-3,8-3.5C177.7,1.3,179.7,1.2,179.7,1.5 M173.2,4.3c-0.9,0.1-2.9,0.3-4.4,0.4
                c-3.2,0.3-10.7,0.9-16.9,1.5c-4.8,0.4-5.3,0.5-6.1,1.1c-0.5,0.4-0.7,1.6-0.4,2c0.1,0.2,3.3,5.6,5,8.7c1.8,3.2,1,5.5-2.3,6.9
                c-0.6,0.3-2.5,1.1-4.1,1.9c-5,2.4-6.4,2.4-11.8,0c-12.4-5.7-12.7-5.8-14.7-5.6c-2.1,0.2-1.8-0.1-6.6,6.7c-1.6,2.4-4,5.8-5.3,7.6
                c-2.6,3.7-2.7,3.9-2.3,4.8c0.8,1.5,1.4,2,4.3,3.3c1.8,0.8,3.3,1.5,9.6,4.3c3.2,1.4,3.4,1.6,4.3,2.4c0.5,0.5,1,1.3,1.4,2l0.6,1.2
                v4.2c0,4.7,0,5-1.3,6.1c-1.5,1.3-1.5,1.3-8.9,1.9c-10.8,0.9-11,1-8.7,4.9c0.6,0.9,2.4,4.1,4.1,7s3.3,5.7,3.6,6.2
                c0.3,0.5,1.3,2.2,2.1,3.7c3.4,5.9,4.2,6.2,14.8,5.3c10.3-0.9,10.9-0.7,16,3.7c0.7,0.6,2.2,1.9,3.4,2.8c5.2,4.2,5.3,5.1,1.1,11.2
                c-3.1,4.5-3,4.3-2.8,5c0.5,1.6,1.4,2.3,4.4,3.6c1.1,0.5,2.9,1.3,4,1.8c5,2.3,6.2,2.8,7.7,3.5c0.9,0.4,2.5,1.2,3.7,1.7
                c1.1,0.5,3.4,1.5,5,2.3c1.6,0.7,3.7,1.7,4.7,2.1c3.5,1.6,3.9,1.7,5.6,1.8c2.7,0.2,2.8,0.1,6-4.5c4.4-6.4,5-6.6,12.3-5.2
                c2,0.4,4.7,0.9,6.1,1.1c4.2,0.7,6.4,2.1,8.3,5.4c0.5,0.8,1.3,2.2,1.8,3.1c5.1,8.8,4.7,8.6,16.6,7.6c4.2-0.3,7.7-0.7,7.9-0.7
                s3.6-0.3,7.6-0.7c8.3-0.7,8.8-0.8,9-2.2c0.1-0.5,0.1-0.6-3.1-6.2c-2.8-5-2.9-5.2-2.9-6.4c0-1.8,1-2.9,3.6-4.1
                c0.9-0.4,2.7-1.2,3.9-1.9c4.8-2.4,6.2-2.4,12,0.3c5.4,2.5,6.1,2.8,6.2,2.8c0,0,1,0.4,2.1,1c3.1,1.5,3.8,1.7,5.6,1.7
                c2.5,0,2.4,0,6.1-5.2c1.7-2.5,3.8-5.5,4.7-6.7c4.6-6.5,4.4-6.2,3.7-7.6c-0.7-1.3-1.5-1.8-4.8-3.2c-0.7-0.3-2.4-1.1-3.8-1.7
                s-3.4-1.5-4.4-2c-6.3-2.8-6.7-3.4-6.8-10c-0.1-7.2,0-7.3,10.9-8.2c8-0.7,7.7-0.6,8.3-1.2c1-0.9,1-1.2-1.7-5.8
                c-0.7-1.3-1.6-2.8-2-3.4s-1.3-2.3-2.2-3.8c-0.9-1.5-2.5-4.3-3.6-6.2c-4.4-7.7-5.1-7.9-20.9-6.1c-3.9,0.4-6.4-0.5-10.2-3.8
                c-1.3-1.1-3-2.5-3.8-3.1c-3.2-2.5-4.1-3.8-4.1-5.8c0-1.3,0-1.3,3.9-6.8c3.3-4.6,3.2-4.8-7.2-9.4c-0.8-0.4-2.4-1.1-3.6-1.6
                s-2.9-1.3-3.8-1.7s-2.7-1.2-3.9-1.8c-1.3-0.6-3-1.4-3.8-1.7s-2.6-1.2-3.9-1.8c-5-2.3-6-2.6-8-2.4c-1.8,0.2-2,0.3-4.2,3.4
                c-0.8,1.1-1.9,2.7-2.4,3.5c-2.1,3-5.3,4.2-8.2,3.2c-0.4-0.2-2.6-0.6-4.8-1c-5.8-1-6.4-1.2-7.8-1.8c-1.6-0.8-3.3-2.4-4.3-4.2
                C181.7,3.8,181.3,3.5,173.2,4.3 M186.8,22.5c6.8,0.7,14.1,2.6,21,5.2c1.4,0.5,1.7,0.9,1.1,1.5c0,0-1.3-0.3-2.8-0.8
                c-12.4-4.1-26.4-5-37-2.3c-26.1,6.5-32.4,31.1-15,57.9c0.9,1.4,2.6,3.9,3,4.3c0.1,0.1,0.6-0.3,1.2-0.8c1.7-1.5,3.2-1.7,4.4-0.4
                c0.6,0.6,0.7,1,1.7,4.7c0.4,1.4,1,3.6,1.4,5s0.7,2.6,0.6,2.6c-0.1,0.1-2.9-0.6-9.5-2.3c-3.4-0.9-5.8-1.5-6.1-1.7
                c-0.2-0.1,0.6-0.9,2.2-2.5l2.5-2.5l-0.7-0.8C138.9,71.5,135.6,51.5,146,37C153.7,26.1,169.3,20.6,186.8,22.5 M218.4,26.9
                c3.6,1.7,4.6,5.9,1.9,8.7c-3.1,3.3-8.4,1.7-9.3-2.8C210.4,28.8,214.8,25.2,218.4,26.9 M178.5,35.8c0.8,0.8,0.1,1.3-2.3,1.8
                c-6,1.1-11.5,4.5-15.4,9.3c-4.4,5.5-5.4,15.6-2.4,23.4c0.3,0.9,0.3,1.5-0.3,1.7c-1.6,0.6-3.6-8-3.1-13.4
                c0.8-8.4,4.2-14.2,10.9-18.7C170.1,37.2,177.5,34.8,178.5,35.8 M206.4,39c3.3,1.2,4,1.8,3.1,2.6c-0.7,0.6-5.4-1.2-5.4-2.1
                C204.1,38.5,204.7,38.3,206.4,39 M237.1,43.8c1.2,0.3,3.8,0.9,5.8,1.3c5.2,1.2,7.5,1.8,7.6,1.8c0,0-0.7,0.9-1.6,1.9
                c-0.9,1-2,2.2-2.4,2.6l-0.7,0.8l1.6,1.7c21.4,22.5,20.2,51.6-2.5,63.3c-12.7,6.5-31,6.5-48.8-0.1c-2.4-0.9-2.5-0.9-2.4-1.4
                c0.1-0.7,0.1-0.7,2,0c12,4.1,24.9,5.2,35.8,3.1c18.5-3.6,28.9-16.9,26.7-34.3c-0.7-5.7-3-12.4-6.1-18.3c-2.2-4.1-3.9-6.7-7.9-12
                c-0.2-0.2-0.3-0.1-1,0.6c-2.7,3-4.7,2-6.2-3c-2.7-9.1-2.6-8.5-2.3-8.5C234.8,43.2,235.9,43.5,237.1,43.8 M210.6,47.7
                c8.4,1.1,17.2,3.5,19,5.1c3.3,3.1,1.9,7.9-2.8,9.5c-10.5,3.4-20.3,4.6-31.9,3.9c-8.1-0.5-20.5-3.4-22.9-5.4s-2.3-6.5,0.2-8.3
                c2.2-1.7,12.2-4.2,20-5c1-0.1,2-0.2,2.2-0.3C195.7,47,208.1,47.4,210.6,47.7 M175.6,67.1c15.8,5,34.6,5.1,50.6,0.1l1.4-0.4l0.8,0.4
                c4.3,2.2,3.9,9.2-0.5,10.1c-0.3,0.1-1.5,0.4-2.7,0.8c-15.1,4.8-32.7,4.8-48.4,0c-3.6-1.1-3.9-1.2-4.9-2.2c-2.7-2.5-2-7.4,1.3-8.9
                C174.3,66.6,174,66.6,175.6,67.1 M244.2,73.7c0.4,0.6,1.3,3.5,1.7,5.3c2.7,13.7-4,25.2-17.2,29.7c-2.3,0.8-5.6,1.4-6,1.1
                c-1.3-0.8-0.5-1.6,1.9-1.9c5.8-0.8,12.3-4.8,16.1-9.7c4-5.2,5.1-14.4,2.6-21.9c-0.6-1.8-0.7-2.2-0.5-2.5
                C243,73.2,243.8,73.2,244.2,73.7 M176.7,82.8c15.2,4.6,33.7,4.7,48.9,0.1l2.1-0.6l0.8,0.4c4.1,2,4.2,7.9,0.2,10
                c-2,1-11.5,3.4-16.5,4.1s-15.7,0.6-21.6-0.1c-5.7-0.7-15.4-3.2-17.5-4.4c-3.5-2-3.4-7.8,0.1-9.5C174.2,82.1,174.3,82.1,176.7,82.8
                 M188.5,107.4c3.6,1.7,4.2,6.6,1.1,9.2c-3.5,2.9-8.8,0.3-8.8-4.2C180.9,108.4,185,105.7,188.5,107.4 M192.7,171.5
                c3.1,2.1,1.7,6.8-2.1,6.8c-3,0-4.7-3-3.1-5.6C188.5,171,191,170.4,192.7,171.5 M301.1,171.3c5.5,2,4,10.3-1.9,10.3
                c-5.3,0-7.4-6.5-3.1-9.7C297.4,170.9,299.4,170.7,301.1,171.3 M121.2,171.5c2.4,0.4,4.5,1.5,6.4,3.3c1.5,1.5,1.5,1.3-1.1,3.2
                l-2.2,1.7l-0.9-0.8c-2.6-2.4-7.7-1.9-8,0.7c-0.2,1.7,0.4,2.1,5.2,3.8c7.2,2.5,9.7,5,9.7,9.7c0,5.8-4,9.1-11.1,9.1
                c-5.5,0-9.4-1.8-11.9-5.4c-0.8-1.2-0.9-1.1,1.8-2.9l2.4-1.7l0.4,0.6c3,4.1,10.8,4.7,11.4,0.9c0.3-1.7-1-2.9-4.3-4
                c-6.8-2.3-9.2-4.1-10.1-7.4C107.1,175.5,113.1,170.2,121.2,171.5 M297.3,172.4c-3.1,1.5-3.2,6.3-0.1,7.8c1.6,0.8,4.1,0.4,5.2-0.7
                C305.7,175.8,301.7,170.3,297.3,172.4 M300.2,173.2c1.2,0.5,1.6,2.2,0.7,3.2l-0.4,0.5l0.6,1c0.8,1.3,0.8,1.4,0.1,1.4
                c-0.5,0-0.6-0.1-1.2-1.1c-1-1.6-1.6-1.6-1.6,0v1.1h-0.5h-0.5v-3.2V173h1.2C299.1,173,299.9,173.1,300.2,173.2 M298.3,175.1v0.9
                l0.8-0.1c1.7-0.1,1.7-1.8,0.1-1.8h-0.9V175.1 M262.5,179.7c2.3,0.6,5.6,2.6,6,3.6c0.1,0.3-1,1.5-3.1,3.3l-1,0.9l-0.5-0.5
                c-3.8-3.6-9.9-1.1-9.9,4c0,4.8,5.7,7.2,9.7,4c0.9-0.8,0.7-0.9,2.9,1.3c2.2,2.2,2.2,2.1,0.9,3.2c-6.9,5.9-17.9,2.7-19.8-5.7
                C245.8,184.9,253.6,177.5,262.5,179.7 M212.8,180.1c4.5,1.4,7.3,5.4,7.3,10.4v2.3h-8.2c-9.2,0-8.5-0.1-7.8,1.3
                c1.4,3.1,7.2,3.7,10.1,1l0.5-0.4l1.9,1.8c2.2,2.1,2.2,1.9,0.8,3c-5.9,5-15.8,3.5-19-2.9c-1.7-3.4-1.7-7.8,0-11.2
                C200.9,180.6,207.2,178.3,212.8,180.1 M285,179.9c4.8,1.3,7.9,5.7,7.8,11l-0.1,1.8h-8.2h-8.2l0.1,0.6c0.7,3.5,7,4.6,10.1,1.9
                l0.7-0.6l1.8,1.8c2.2,2.1,2.2,2,1,3c-4.1,3.3-9.9,4-14.6,1.7c-7.7-3.8-7.7-15.9-0.1-20.1C278,179.6,282.1,179.1,285,179.9
                 M146.5,180.1c1.2,0.4,1.9,0.9,2.6,1.7l0.6,0.6v-1.2V180h3.2h3.2v10.9v10.9H153h-3.2v-1.1v-1.1l-0.9,0.9c-2.3,2.1-6.9,2.4-10.4,0.7
                c-7.9-4-7.9-16.5,0.1-20.4C140.9,179.6,144.2,179.3,146.5,180.1 M175.6,179.9c8.6,1.6,11.6,13.4,5,19.5c-3.6,3.4-9.8,3.9-12.9,1
                l-0.7-0.7v5.2v5.2h-3.3h-3.3V195v-15h3.3h3.3l0.1,1.1l0.1,1.1l0.6-0.7C169.2,180,172.3,179.3,175.6,179.9 M238.8,179.9
                c2.8,0.8,4.4,2.6,5.3,5.7c0.3,1.2,0.3,1.8,0.3,8.8v7.5h-3.3h-3.3l-0.1-7c-0.1-6.1-0.1-7.1-0.4-7.5c-1.1-2.1-4.5-2.4-6.3-0.7
                c-1.3,1.2-1.3,1.3-1.4,8.7l-0.1,6.6h-3.2h-3.2v-10.9V180h3.2h3.2v1.2v1.2l0.8-0.8C232.3,179.8,236,179.1,238.8,179.9 M194,191v10.9
                h-3.3h-3.3V191v-11h3.3h3.3V191 M206.9,185.3c-1.4,0.5-2.9,1.9-2.9,2.8c0,0.3,0.2,0.3,4.8,0.3c5.3,0,5,0.1,4.6-1.1
                C212.5,185.4,209.4,184.4,206.9,185.3 M279.5,185.3c-1.5,0.5-2.9,1.9-2.9,2.8c0,0.3,0.2,0.3,4.8,0.3c5.3,0,5.2,0,4.5-1.2
                C284.9,185.4,281.8,184.4,279.5,185.3 M143.1,185.9c-6.1,1.7-4.9,10.4,1.4,10.4c4.9,0,7.4-5.3,4.2-8.7
                C147.1,186,145,185.3,143.1,185.9 M170.8,185.9c-5.1,1.2-5.6,8.2-0.8,10c3.8,1.4,7.5-1,7.5-4.9C177.5,187.4,174.4,185,170.8,185.9
                 M243.1,212c2.7,0.8,3.5,4.7,1.3,6.4c-1.4,1-2.6,1.2-4,0.6C236.2,217,238.7,210.7,243.1,212 M129.6,212.2c0.1,0.1,0.2,1.3,0.2,2.8
                v2.7l-1.4-0.1c-2.5-0.1-3.4,0.4-3.5,2.2l-0.1,1h2.2h2.2v2.9c0.1,3.4,0.2,3.2-2.4,3.2h-2l-0.1,7.9l-0.1,7.9h-3.1h-3.1v-7.9v-7.9
                h-1.8h-1.8v-3v-3h1.7h1.7l0.1-1.7c0.2-3.9,2.5-6.5,6.1-7C125.7,212,129.3,212,129.6,212.2 M13.2,220.1c2.8,4.1,5.1,7.5,5.1,7.6
                c0.1,0.1,2.4-3.3,5.2-7.5l5.1-7.6h2.9h2.9v15.1v15.1h-3.1h-3.1v-9.1v-9.1l-1.1,1.6c-1.1,1.6-3.7,5.4-6.7,9.9
                c-0.9,1.3-1.7,2.4-1.8,2.4c-0.1,0-2-2.7-7.7-11.2L9,224.9l-0.1,8.9l-0.1,8.9H5.6H2.5v-14.9c0-8.2,0-15,0-15.1
                c0.1-0.2,0.8-0.2,2.9-0.2h2.8L13.2,220.1 M189.1,216.7v4.1h2.4h2.4v3v3h-2.4h-2.4l0.1,4.5c0.1,5.6,0.2,6,3.1,5.9h1.2v2.8
                c0.1,3.4,0.2,3.2-2.7,3.2c-6.7,0-8.2-2.2-8.2-12.3v-4h-1.3l-1.3-0.1v-3v-3h1.4h1.4v-3.9c0-2.1,0-4,0-4.1c0.1-0.2,0.8-0.2,3.3-0.2
                h3.2v4.1 M326.5,218.2v5.6h6.4h6.4v-5.6v-5.6h3.4h3.4v15v15h-3.3h-3.3v-6.4v-6.4h-6.4h-6.4v6.4v6.4h-3.3h-3.3v-15.1v-15.1h3.4h3.4
                v5.8 M382.2,218.1v5.4l0.3-0.6c1.5-2.5,7.2-3.2,10.9-1.2c9.6,5,7.1,20.2-3.5,21.4c-3.1,0.4-6.3-0.6-7.3-2.2l-0.3-0.5l-0.1,1.2
                l-0.1,1.2h-3.2h-3.2v-14.9c0-8.2,0-15,0-15.1c0.1-0.2,0.9-0.2,3.3-0.2h3.3L382.2,218.1 M173.3,220.8c1.5,0.5,3.1,1.5,4.4,2.6l1,1
                l-2.1,2c-2.4,2.2-2.1,2.1-2.8,1.5c-3-2.7-8.2-1.7-9.4,1.8c-2.1,5.8,4.8,10.2,9.5,6l0.6-0.5l2,2c2.4,2.4,2.3,2,0.8,3.3
                c-8.9,7.4-22.3-0.4-19.6-11.4C159.4,222.6,166.7,218.7,173.3,220.8 M51.3,220.8c1.4,0.3,3.1,1.2,3.7,1.9l0.5,0.6l0.1-1.2l0.1-1.2
                h3.2h3.2v10.9v10.9h-3.2h-3.2l-0.1-1.2l-0.1-1.2l-0.5,0.6c-2.3,2.8-8.8,2.8-12.5-0.1C33.4,233.8,40.1,218.4,51.3,220.8 M81.6,220.7
                c2.6,0.7,4.7,2.8,5.5,5.6c0.3,1,0.3,1.9,0.3,8.8v7.7h-3.2H81l-0.1-7c-0.1-6.9-0.1-7-0.5-7.6c-1.6-2.6-5.9-2.3-7.2,0.5
                c-0.2,0.5-0.3,1.6-0.4,7.3l-0.1,6.8h-3.2h-3.2v-10.9V221h3.2h3.2l0.1,1.2l0.1,1.2l0.3-0.5C74.4,221,78.7,220,81.6,220.7
                 M143.3,220.7c1.4,0.2,3,1.1,3.7,1.9l0.6,0.7l0.1-1.1l0.1-1.1h3.2h3.2V232v10.9H151h-3.2l-0.1-1.1l-0.1-1.1l-0.8,0.8
                c-3.2,3.1-10.4,2.1-13.7-1.9C126.4,231.2,133.2,218.8,143.3,220.7 M235.6,220.9c0.1,0.2,0.1,1.6,0,3.1l-0.1,2.7h-2.1
                c-2.4,0-3.2,0.3-4.4,1.4c-1.3,1.3-1.4,1.4-1.5,8.5l-0.1,6.2h-3.2H221v-10.9V221h3.2h3.2l0.1,1.2l0.1,1.2l0.8-0.8
                c1.4-1.5,2.8-2,5.4-2C235.5,220.5,235.6,220.5,235.6,220.9 M264.9,220.8c2.8,0.7,4.6,2.6,5.4,5.7c0.5,1.7,0.7,15.9,0.2,16.2
                c-0.2,0.1-1.1,0.2-3.3,0.2h-3.1l-0.1-6.8c-0.1-7.7-0.1-7.9-1.4-8.9c-1.5-1.1-4.3-0.9-5.6,0.4c-1,1.2-1,0.8-1.1,8.3l-0.1,6.9h-3.2
                h-3.2v-10.9V221h3.2h3.2v1.1l0.1,1.1l0.6-0.6C258.3,220.8,261.9,220,264.9,220.8 M286.7,220.8c1.4,0.3,2.4,0.8,3.4,1.7l0.8,0.7v-1
                c0-1.4-0.2-1.4,3.4-1.3h3.1V232c0,11,0,11.1-0.4,12.4c-0.8,2.8-2,4.4-4.2,5.9l-1.2,0.8H285h-6.5l-1.2-0.6c-1.7-0.9-2.3-1.2-2.3-1.4
                c0-0.1,2.7-4.3,3-4.7c0,0,0.6,0.2,1.2,0.5c5.8,2.9,10.9,1.6,11.8-3c0.2-1.3,0.2-1.5-0.5-0.9c-2.4,2.3-8.5,2.4-11.8,0.3
                c-7.4-4.8-6.3-16.8,1.9-20.1C282.3,220.5,284.7,220.3,286.7,220.8 M97.8,227.9c0.1,6.3,0.1,7.1,0.4,7.6c1.1,2.2,4.4,2.5,6.1,0.6
                c1-1.1,1-1,1.1-8.4l0.1-6.8h3.2h3.2v10.9v10.9h-3.2h-3.2l-0.1-1.1l-0.1-1.1l-0.7,0.7c-4,4-11.6,1.7-13-4
                c-0.3-1.1-0.6-15.7-0.3-16.3c0.1-0.2,0.6-0.3,3.3-0.2h3.2V227.9 M202.9,227.9c0.1,7.4,0.1,7.6,0.9,8.4c1.2,1.4,3.9,1.4,5.3,0.1
                c1.2-1.2,1.2-1.1,1.3-8.7l0.1-6.8h3.2h3.2v10.9v10.9H214c-3.8,0.1-3.7,0.1-3.7-1.2v-1.1l-0.5,0.6c-1,1.3-3.2,2.1-5.6,2.1
                c-4,0-6.7-2.1-7.7-5.9c-0.3-1.1-0.6-15.7-0.3-16.3c0.1-0.2,0.6-0.2,3.3-0.2h3.2L202.9,227.9 M245.2,231.9v10.9H242h-3.2V232
                c0-5.9,0-10.9,0-11c0.1-0.2,0.8-0.2,3.3-0.2h3.2v11.1 M357.4,228l0.1,7l0.5,0.8c1.6,2.6,5.6,2,6.7-1c0.1-0.3,0.2-2.9,0.2-7
                c0-3.6,0.1-6.6,0.1-6.7c0.1-0.2,0.9-0.2,3.3-0.2h3.1v10.9v10.9h-3.2H365l-0.1-1.1l-0.1-1.1l-0.6,0.6c-2.3,2.6-8,2.6-10.9,0.1
                c-2.3-2.1-2.6-3.3-2.6-12.9v-7.7h3.2h3.2L357.4,228 M49.2,226.7c-4,0.6-5.7,5.8-2.9,8.8c3.2,3.4,9.1,1.4,9.5-3.2
                C56.2,228.8,53,226.1,49.2,226.7 M141.5,226.7c-3.3,0.5-5.2,4-4.1,7c2.1,5.6,10.6,4.1,10.6-1.8C148,228.5,145,226.1,141.5,226.7
                 M284.6,226.7c-4.2,0.7-5.8,6-2.7,9c2.1,2,6,1.8,7.9-0.3C293.3,231.5,289.9,225.8,284.6,226.7 M386.4,226.7c-5,0.8-6.1,7.6-1.6,9.9
                c2.1,1.1,5,0.7,6.5-0.9c2.9-2.9,1.3-8.4-2.6-8.9c-0.4-0.1-0.9-0.1-1.1-0.2C387.4,226.6,386.9,226.6,386.4,226.7"/>
            <path id="path2" fillRule="evenodd" clipRule="evenodd" fill="#6BB1F1" d="M164.9,25.1c-0.1,0.2,0.3,0.4,0.7,0.3
                c0.1-0.1,0.3-0.2,0.3-0.3C165.8,24.9,165,24.9,164.9,25.1 M162.7,25.4c-0.1,0.1,0,0.3,0.1,0.3s0.5,0,0.8,0.1c0.7,0.1,0.7,0-0.1-0.3
                C162.7,25.1,162.8,25.1,162.7,25.4 M161.4,25.8c0,0.1-0.1,0.2-0.3,0.3c-0.2,0.1-0.2,0-0.2-0.1c0.1-0.1,0-0.1-0.2-0.1
                c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.1-0.1,0.1-0.3,0c-0.2,0-0.3,0-0.3,0.1s-0.1,0.1-0.3,0.1c-0.2-0.1-0.3,0-0.2,0.2s0,0.3-0.2,0.2
                c-0.6-0.2-4.6,2.1-7.3,4.2c-9.4,7.1-13.8,20.1-11.1,32.4c0.8,3.6,2.1,7.8,2.4,7.8c0.1,0,0.1,0.1,0.1,0.2c-0.2,0.3,2.8,6.7,3.2,6.7
                c0.1,0,0.1,0.1,0.1,0.1c-0.1,0.2,1.6,2.9,1.8,2.9c0.1,0,0.1,0.1,0.1,0.2c-0.1,0.1,0,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.2
                c-0.1,0.2,1.2,1.9,1.4,1.8c0.1-0.1,0.1,0,0,0.1c-0.1,0.2,1.2,2.1,1.4,2c0.1,0,0.1,0,0.1,0.1s0.1,0.3,0.3,0.4
                c0.2,0.2,0.2,0.2,0.1,0.2c-0.1-0.1,0.1,0.3,0.6,0.9c1.2,1.4,2.6,2.9,2.7,2.9c0.6-0.2-0.3,1-2.2,2.9l-2.4,2.4l1,0.3
                c0.5,0.2,1.2,0.3,1.5,0.3s0.6,0.1,0.6,0.2s0.2,0.1,0.3,0.1c0.2,0,0.3,0,0.3,0.1s0.2,0.1,0.3,0.1c0.2-0.1,0.3,0,0.3,0.1
                s0.1,0.1,0.4,0.1c0.3-0.1,0.3-0.1,0.2,0.1s0,0.2,0.4,0.1c0.5-0.1,0.5-0.1,0.3,0.1s-0.2,0.2,0.2,0.1c0.3,0,0.4,0,0.4,0.1
                c-0.1,0.1,0.1,0.1,0.3,0.1s0.4,0,0.4,0c-0.1,0.2,2.6,0.8,2.9,0.7c0.1,0,0.2,0,0.2,0.1s0.1,0.2,0.3,0.1s0.3,0,0.3,0.1
                s0.2,0.1,0.5,0.1c0.4-0.1,0.4-0.1,0.2,0.1s-0.1,0.2,0.3,0.1s0.5-0.1,0.3,0.1s-0.2,0.2,0.2,0.1c0.3-0.1,0.4,0,0.4,0.1
                c-0.1,0.1,0.5,0.3,1.3,0.5c0.9,0.2,1.4,0.3,1.4,0.1c0-0.4-0.2-1-0.4-0.9c-0.1,0.1-0.1-0.1,0-0.4s0.1-0.4-0.1-0.3s-0.2,0-0.1-0.3
                c0.1-0.4,0.1-0.4-0.1-0.3c-0.2,0.1-0.2,0.1-0.1-0.2c0.1-0.2,0.1-0.4,0-0.5s-0.1-0.4-0.2-0.5c-0.1-0.8-0.8-3.1-1-3.1
                c-0.1,0.1-0.1-0.1,0-0.4c0.1-0.4,0.1-0.4-0.1-0.3c-0.2,0.1-0.2,0-0.1-0.2c0.1-0.2,0-0.4-0.1-0.4s-0.2-0.1-0.1-0.3
                c0.1-0.2,0-0.3,0-0.3c-0.1,0-0.2-0.2-0.2-0.5c0-3.6-3-5-5.5-2.7c-0.9,0.9-1.8,1.1-1.7,0.4c0-0.2-1.4-2.2-1.6-2.2
                c-0.1,0-0.1-0.1-0.1-0.1c0.1-0.2-0.9-1.7-1.1-1.7c-0.1,0-0.1,0-0.1-0.1c0-0.3-1.8-3.2-1.9-3.2c-0.1,0-0.1-0.1-0.1-0.2
                c0-0.4-1.4-2.7-1.5-2.6s-0.1,0,0-0.2s0-0.3-0.1-0.4s-0.2-0.2-0.2-0.3c0-0.1-0.4-1.1-0.9-2.1c-6.9-14.9-6.2-28.8,1.9-38.5
                c2.5-3,6.5-6,10.4-7.8c0.5-0.2,1-0.5,1-0.6s0.2-0.1,0.3-0.1c0.5,0,0.6-0.1,0.5-0.3c-0.1-0.1,0.1-0.4,0.2-0.5c0.4-0.3,2-0.6,1.8-0.3
                c-0.1,0.2,0.1,0.3,0.4,0c0.2-0.1,0.2-0.2,0-0.2s-0.4-0.1-0.5-0.2c-0.4-0.4-0.7-0.4-1.3-0.1c-0.7,0.4-1.5,0.3-1.3-0.1
                c0.1-0.4-0.1-0.7-0.3-0.4c-0.1,0.2-0.1,0.2-0.2,0c0-0.1-0.1-0.2-0.3-0.2C161.5,25.6,161.4,25.7,161.4,25.8 M166.3,26.1
                c-0.1,0.1,0.1,0.2,0.3,0.2c0.3,0.1,0.4,0,0.2-0.2S166.4,25.9,166.3,26.1 M214.4,27c-0.9,0.3-2.7,2-2.5,2.4c0.1,0.1,0,0.2-0.1,0.2
                s-0.1,0.1-0.1,0.2c0.1,0.1,0,0.2-0.1,0.2s-0.1,0.1-0.1,0.4c0.1,0.3,0.1,0.3-0.1,0.2c-0.2-0.1-0.2,0-0.1,0.4
                c0.1,0.4,0.1,0.5-0.1,0.4c-0.1-0.1-0.2,0.1-0.2,0.6c0,0.6,0,0.7,0.2,0.5c0.1-0.2,0.2-0.1,0.1,0.4c-0.1,0.5-0.1,0.6,0.1,0.5
                c0.1-0.1,0.2,0,0.1,0.3c-0.1,0.3-0.1,0.4,0.1,0.2c0.2-0.1,0.2-0.1,0.1,0.2c-0.1,0.2,0,0.3,0.1,0.3s0.1,0.1,0.1,0.2
                c-0.1,0.2,1.7,2.1,2,2c0.1-0.1,0.2,0,0.2,0.1s0.1,0.1,0.3,0.1c0.2-0.1,0.2,0,0.1,0.1c-0.1,0.2,0,0.2,0.2,0.1
                c0.3-0.1,0.3-0.1,0.3,0.1c-0.1,0.1,0.1,0.2,0.5,0.1c0.5-0.1,0.6-0.1,0.3,0.1c-0.3,0.1-0.1,0.2,0.6,0.2c0.7,0,0.9,0,0.6-0.2
                c-0.3-0.1-0.2-0.1,0.3-0.1c0.5,0.1,0.6,0,0.5-0.1s0-0.2,0.2-0.1c0.2,0.1,0.4,0,0.4-0.1s0.1-0.1,0.2-0.1c0.1,0.1,0.2,0,0.2-0.1
                s0.1-0.1,0.2-0.1c0.2,0.1,1.6-1.1,1.4-1.3c-0.1-0.1,0-0.1,0.1-0.1c0.9,0.2,1.5-4,0.7-5.5C220,27.2,217,26.1,214.4,27 M234.8,43.6
                c-0.1,0.2,0.6,2.8,0.8,2.6c0.1,0,0.1,0.1,0,0.3c-0.1,0.3,0,0.3,0.1,0.2c0.2-0.1,0.2,0,0.1,0.2c-0.1,0.2,0,0.4,0.1,0.4
                s0.1,0.2,0.1,0.4c-0.1,0.3,0,0.3,0.1,0.2c0.2-0.1,0.2,0,0.1,0.2c-0.1,0.2,0,0.4,0.1,0.4s0.1,0.1,0.1,0.2c-0.1,0.1,0,0.3,0,0.4
                c0.1,0.1,0.1,0.3,0.2,0.5c0.1,0.6,0.6,2.4,0.8,2.3c0.1,0,0.1,0.1,0,0.3c0,0.2,0,0.5,0.2,0.7s0.2,0.4,0.2,0.6
                c-0.1,0.2-0.1,0.3,0.1,0.2c0.2-0.1,0.2,0,0.1,0.2c-0.1,0.2,0,0.4,0.1,0.4s0.1,0.1,0.1,0.3c-0.1,0.3,1.2,1.7,1.6,1.6
                c0.1-0.1,0.3,0,0.4,0.1c0.1,0.2,1.1,0.2,1.1,0c0-0.1,0.1-0.1,0.3-0.1c0.1,0.1,0.3,0,0.3-0.1s0.1-0.1,0.2-0.1
                c0.2,0.1,1.1-0.6,0.9-0.8c-0.1-0.1,0-0.1,0.1-0.1s0.2,0,0.2-0.2c0-0.1,0-0.2,0.1-0.2s0.3-0.1,0.6-0.3c0.4-0.4,0.4-0.4,0.7-0.1
                c0.1,0.2,0.3,0.3,0.4,0.3c0.1,0,0.1,0,0.1,0.1c-0.1,0.2,1.1,1.7,1.3,1.6c0.1-0.1,0.1,0,0.1,0.1c-0.1,0.1,0.1,0.4,0.3,0.6
                c0.2,0.2,0.4,0.5,0.4,0.5c-0.1,0.1,1.2,1.8,1.3,1.7c0,0,0.1,0,0.1,0.1c0,0.2,1.7,2.8,1.9,3c0.1,0,0.1,0.2,0.1,0.3
                c0.1,0.4,1.3,2.3,1.5,2.3c0.1,0,0.1,0.1,0.1,0.2c-0.1,0.1,0,0.2,0.1,0.2s0.1,0.1,0.1,0.2c-0.1,0.1,0,0.2,0.1,0.2s0.2,0.1,0.1,0.1
                c-0.1,0.1,1.1,2.6,1.4,2.8c0.1,0,0.1,0.2,0.1,0.3c0,0.3,1.4,3.5,1.6,3.6c0.1,0,0.1,0.2,0.1,0.3c0,0.1,0.4,1.2,0.8,2.6
                c6.1,18,0.5,33.6-14.4,40.5c-0.6,0.3-1.2,0.6-1.2,0.6c-0.1,0.1-1.7,0.8-2.6,1c-0.4,0.1-0.6,0.3-0.6,0.4s-0.1,0.2-0.2,0.2
                c-0.4,0-1.1,0.4-1.1,0.5c0,0.2,0.9,0.1,1.9-0.2c0.5-0.2,0.7-0.1,1,0.1l0.4,0.3l-0.6-0.1c-0.3-0.1-0.7,0-0.8,0.1s-0.4,0.2-0.6,0.2
                c-0.2,0-0.5,0.1-0.5,0.1c0,0.1-0.3,0.2-0.5,0.3c-0.4,0.2-0.3,0.2,0.5,0.2c0.5,0,0.9,0,1,0.1c0,0.1,0.1,0.1,0.1,0s0.1-0.2,0.2-0.1
                c0.1,0.1,0.2,0,0.1-0.2c-0.1-0.2,0-0.3,0-0.3c0.1,0,0.2,0.1,0.2,0.2c0.1,0.2,1.6,0.3,1.6,0c0-0.1,0.2-0.2,0.4-0.2s0.4-0.1,0.4-0.2
                c0.1-0.1,0-0.2-0.1-0.1s-0.2,0-0.3-0.1s0-0.2,0.5-0.1c0.6,0.1,1.7-0.2,1.6-0.4c-0.1-0.1,0-0.1,0.2-0.1c0.7,0.3,6.6-3.3,9.2-5.5
                c9.6-8.3,13.2-21.9,9.4-34.9c-0.2-0.7-0.4-1.2-0.5-1.2s-0.1-0.1-0.1-0.2c0.2-0.3-1.5-4.7-1.7-4.5c-0.1,0.1-0.1,0,0-0.1
                s0-0.4-0.1-0.5s-0.2-0.4-0.3-0.5c-0.1-0.6-1-2.2-1.1-2.1s-0.1,0-0.1-0.1c0.2-0.3-0.1-0.7-0.3-0.6c-0.1,0.1-0.1,0-0.1-0.1
                c0.1-0.2-1.4-3-1.6-3c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.3-1-1.8-1.5-2.2
                c-0.2-0.2-0.2-0.3-0.1-0.2c0.2,0.1,0-0.2-1-1.6c-0.2-0.2-0.3-0.3-0.4-0.3c-0.1,0.1-0.1,0,0-0.1c0.1-0.2-1.3-2.1-1.6-2.1
                c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.1-0.2-0.4-0.5-0.6c-0.3-0.3-0.4-0.4-0.3-0.3c0.4,0.2-1.5-1.9-2.7-3.1c-1.3-1.2-1.4-0.8,1.3-3.7
                c2.4-2.5,2.5-2.8,1.5-2.7c-0.3,0.1-0.5,0-0.4,0c0.3-0.2-2.8-0.9-3.1-0.8c-0.1,0-0.2,0-0.2-0.1s-0.1-0.2-0.4-0.1
                c-0.3,0.1-0.4,0-0.4-0.1s-0.2-0.1-0.5-0.1c-0.3,0.1-0.4,0.1-0.3-0.1c0.1-0.2,0-0.2-0.3-0.1c-0.3,0.1-0.4,0.1-0.3-0.1
                c0.1-0.1,0-0.2-0.5-0.1c-0.4,0.1-0.5,0.1-0.4,0c0.3-0.2-3.8-1.2-4.6-1.1c-0.3,0-0.4,0-0.4-0.2c0.1-0.1,0-0.2-0.4-0.1
                c-0.3,0.1-0.4,0.1-0.3-0.1c0.1-0.1,0-0.2-0.4-0.1c-0.4,0.1-0.5,0.1-0.4,0s-0.1-0.2-0.3-0.3C235.1,43.4,234.9,43.4,234.8,43.6
                 M248.7,48.6c0,0-0.2,0.3-0.6,0.6l-0.6,0.5l0.5-0.6C248.6,48.6,248.7,48.5,248.7,48.6 M279.9,48.7c0.3,0,0.7,0,0.9,0
                c0.3,0,0-0.1-0.5-0.1S279.6,48.6,279.9,48.7 M285.6,52.9c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C285.8,52.9,285.6,52.7,285.6,52.9
                 M173.6,67.1c-3.8,1.4-4.3,7.6-0.7,9.5c2.4,1.2,11.1,3.5,16.3,4.2c4.7,0.7,5.1,0.7,12.1,0.7c9.9,0,15.9-0.9,24.3-3.5
                c1-0.3,2.2-0.7,2.6-0.8c2.8-0.8,4.1-4.9,2.5-7.6c-0.8-1.4-2.7-2.7-3.6-2.3c-13.2,5-34.9,5.2-50.3,0.5c-1-0.3-2-0.6-2.2-0.7
                C174.4,66.9,174,67,173.6,67.1 M173.8,82.6c0.1,0.1,0,0.1-0.2,0.1c-0.2-0.1-0.3,0-0.3,0.1s-0.1,0.1-0.2,0.1c-0.1-0.1-0.2,0-0.2,0.1
                s0,0.2-0.1,0.1c-0.2-0.2-0.8,0.2-0.7,0.4c0.1,0.1,0,0.1-0.1,0.1s-0.2,0-0.2,0.2c0,0.1,0,0.2-0.1,0.2s-0.1,0-0.2,0.1
                c0,0.1-0.2,0.3-0.4,0.5s-0.3,0.5-0.3,0.6c0.1,0.1,0,0.2-0.1,0.2c-0.1-0.1-0.2,0.2-0.3,0.5c-0.3,1.1-0.2,3.6,0.2,3.4
                c0.1-0.1,0.1,0,0,0.3s-0.1,0.3,0.1,0.3c0.1-0.1,0.2-0.1,0.1,0.1c-0.1,0.1,0,0.3,0.2,0.5c0.2,0.1,0.2,0.2,0.1,0.1
                c-0.2-0.1-0.2,0,0,0.1s0.3,0.3,0.2,0.3c-0.2,0.2,1.1,1.2,1.3,1.1c0.1-0.1,0.1,0,0.1,0.1c-0.1,0.2,1,0.8,1.3,0.6
                c0.1-0.1,0.2,0,0.2,0.1s0.1,0.2,0.3,0.1c0.1-0.1,0.3,0,0.3,0.1s0.2,0.1,0.3,0.1c0.2-0.1,0.3,0,0.3,0.1s0.1,0.1,0.3,0.1
                c0.2-0.1,0.2,0,0.2,0.1c-0.1,0.2,1.3,0.7,1.6,0.5c0.1-0.1,0.2-0.1,0.1,0c-0.1,0.2,2.4,0.9,2.7,0.8c0.1-0.1,0.2,0,0.2,0.1
                s0.2,0.2,0.4,0.1c0.3-0.1,0.4,0,0.4,0.1s0.2,0.2,0.5,0.1c0.4-0.1,0.4-0.1,0.2,0.1s-0.1,0.2,0.3,0.1c0.3-0.1,0.5,0,0.5,0.1
                c-0.1,0.1,0.3,0.2,0.8,0.3c2.6,0.5,4.6,0.8,4.9,0.8c0.2-0.1,0.4,0,0.4,0.1s0.2,0.2,0.7,0.1s0.7-0.1,0.7,0.1s0.2,0.2,0.9,0.1
                s0.8-0.1,0.6,0.1s0,0.2,0.9,0.1c0.7-0.1,1,0,0.8,0c-0.6,0.3,1.7,0.4,8.2,0.4s8.8-0.1,8.2-0.4c-0.2-0.1,0.1-0.1,0.8,0
                c0.9,0.1,1.1,0,0.9-0.1c-0.2-0.2-0.1-0.2,0.5-0.1c0.5,0.1,0.7,0,0.7-0.1s0.2-0.2,0.6-0.1c0.4,0.1,0.6,0,0.7-0.1
                c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0.1,0.4,0,0.6-0.1s0.3-0.2,0.4-0.1c0.1,0.1,1.1-0.1,2.3-0.3c1.2-0.2,2.2-0.4,2.3-0.4s0.1-0.1-0.1-0.2
                c-0.2-0.1-0.1-0.1,0.3-0.1c0.4,0.1,0.5,0.1,0.5-0.1c-0.1-0.1,0-0.2,0.3-0.1s0.4,0.1,0.3-0.1c-0.1-0.1,0-0.2,0.3-0.1
                c0.4,0.1,0.4,0.1,0.3-0.1c-0.1-0.2-0.1-0.2,0.2-0.1c0.2,0.1,0.4,0.1,0.5,0s0.3-0.1,0.5-0.1c0.6-0.1,3.1-0.8,3-0.9
                c0-0.1,0.1-0.1,0.3,0c0.3,0.1,0.3,0,0.2-0.1c-0.1-0.2-0.1-0.2,0.1-0.1c0.2,0.1,0.3,0,0.3-0.1s0.2-0.1,0.4-0.1
                c0.3,0.1,0.3,0.1,0.2-0.1s-0.1-0.2,0.1-0.1c0.5,0.2,2.5-1.2,2.5-1.8c0-0.1,0.2-0.3,0.4-0.5s0.3-0.5,0.2-0.7s0-0.3,0.1-0.2
                c0.2,0.1,0.4-0.5,0.4-1.7s-0.1-1.8-0.4-1.7c-0.1,0.1-0.1,0-0.1-0.2c0.1-0.4-0.3-1.2-0.7-1.5c-0.2-0.1-0.3-0.3-0.3-0.4
                s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.2-0.1s-0.2-0.3-0.5-0.5s-0.5-0.3-0.4-0.1c0.1,0.1,0,0.1-0.1-0.1s-0.3-0.3-0.4-0.2
                c-0.1,0.1-0.2,0-0.2,0c0-0.1-0.2-0.1-0.3-0.1s-0.3,0.1-0.3,0.1c0,0.1-0.2,0.1-0.3,0c-0.2-0.1-0.3,0-0.3,0.1s-0.1,0.2-0.1,0.1
                c-0.2-0.1-2.8,0.6-2.7,0.8c0.1,0.1,0,0.1-0.2,0.1c-0.5-0.1-1.3,0.1-1.1,0.3c0.1,0.1,0,0.1-0.3,0.1c-0.3-0.1-0.5,0-0.5,0.1
                s-0.1,0.1-0.3,0.1c-0.2-0.1-0.3,0-0.3,0.1s-0.2,0.1-0.4,0.1c-0.3-0.1-0.4,0-0.4,0.1s-0.1,0.2-0.3,0.1c-0.4-0.1-4.3,0.7-4,0.8
                c0.1,0.1-0.1,0.1-0.6,0c-0.6,0-0.8,0-0.5,0.1c0.2,0.1,0.1,0.1-0.5,0.1c-0.6-0.1-0.8,0-0.6,0.1s0,0.2-0.7,0.1s-0.9-0.1-0.8,0.1
                c0.1,0.2-0.1,0.2-0.7,0.1c-0.6-0.1-0.8-0.1-0.8,0.1s-0.2,0.2-0.9,0.1c-0.5-0.1-0.9,0-1.1,0.1c-0.1,0.1-2.7,0.1-7.3,0.1
                c-3.9,0-7.5-0.1-8.1-0.1s-1-0.1-1-0.2s-0.3-0.1-0.9-0.1c-0.7,0.1-0.8,0.1-0.6-0.1s0-0.2-0.7-0.1s-0.9,0.1-0.8-0.1
                c0.1-0.2-0.1-0.2-0.6-0.1c-0.6,0.1-0.7,0.1-0.4-0.1c0.3-0.1,0.1-0.1-0.6-0.1c-0.5,0-0.8,0-0.6,0s-0.6-0.3-1.7-0.5
                c-1.1-0.2-2.1-0.3-2.3-0.3c-0.1,0-0.2,0-0.2-0.1s-0.1-0.2-0.4-0.1c-0.3,0.1-0.4,0-0.4-0.1s-0.2-0.2-0.4-0.1
                c-0.3,0.1-0.4,0.1-0.3-0.1c0.1-0.1,0-0.2-0.4-0.1s-0.5,0.1-0.4,0c0.1-0.2-0.7-0.4-1.1-0.3c-0.2,0.1-0.3,0-0.2-0.1
                c0.1-0.2-2.4-0.9-2.7-0.8c-0.1,0-0.1,0-0.1-0.1s-0.1-0.2-0.3-0.1s-0.3,0-0.3-0.1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0.1-0.3,0-0.3-0.1
                c0.1-0.1-0.2-0.2-0.6-0.2C173.9,82.4,173.7,82.5,173.8,82.6 M195.9,96.9c-0.2,0-0.5,0-0.7,0s-0.1-0.1,0.3-0.1
                C195.8,96.8,196,96.8,195.9,96.9 M185.5,107.2c0,0.1-0.2,0.1-0.4,0.1c-0.2-0.1-0.4,0-0.4,0.1s-0.1,0.1-0.2,0.1
                c-0.3-0.1-1.9,0.9-1.7,1.2c0.1,0.1,0,0.1-0.1,0.1c-0.1-0.1-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.3c0.1,0,0,0.1-0.2,0.2
                s-0.3,0.2-0.2,0.3c0.1,0.1,0,0.2-0.1,0.2s-0.1,0.1-0.1,0.2c0.1,0.2-0.1,0.7-0.2,0.6c-0.2-0.1-0.5,1.6-0.4,2.4
                c0.1,0.4,0.2,0.7,0.2,0.6c0.1,0,0.1,0.1,0,0.3c-0.1,0.3,0,0.3,0.1,0.2c0.2-0.1,0.2-0.1,0.1,0.1c-0.1,0.2,0,0.3,0.1,0.3
                s0.1,0.1,0.1,0.2c-0.1,0.1,0.1,0.3,0.3,0.4s0.3,0.3,0.3,0.3c-0.2,0.4,1.9,1.8,2.7,1.8c0.3,0,0.4,0.1,0.4,0.1
                c-0.1,0.1,0.4,0.2,1,0.2c0.8,0,1.1-0.1,1-0.2s0-0.2,0.2-0.1s0.4,0,0.4-0.1s0.1-0.1,0.2-0.1c0.3,0.1,1.6-0.7,2.1-1.3
                c0.2-0.3,0.2-0.4,0.1-0.3c-0.1,0.1-0.1,0,0.2-0.2c0.8-0.6,1.3-2.8,0.9-4.4c-0.1-0.3-0.2-0.5-0.2-0.5c-0.1,0-0.1-0.1-0.1-0.3
                s-0.1-0.4-0.2-0.3c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0-0.2,0.1-0.1s-0.1-0.1-0.4-0.5c-0.3-0.3-0.4-0.4-0.2-0.2l0.4,0.3l-0.4-0.5
                c-0.5-0.6-1.8-1.4-2-1.2c-0.1,0.1-0.2,0-0.2-0.1s-0.2-0.1-0.5-0.1c-0.3,0.1-0.4,0.1-0.3-0.1c0.1-0.1-0.1-0.2-0.7-0.2
                C185.8,107,185.5,107.1,185.5,107.2 M233.4,119.1c0.2,0.2,0.9,0.3,1,0.1c0-0.1-0.2-0.2-0.5-0.2C233.5,119,233.3,119,233.4,119.1
                 M284.2,125.8c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1S284,125.7,284.2,125.8 M156.1,191.4c0,5.6,0,7.9,0,5.1c0-2.9,0-7.5,0-10.3
                C156.2,183.4,156.1,185.7,156.1,191.4 M265.8,186.1c-0.4,0.4-0.7,0.8-0.7,0.8S265.5,186.6,265.8,186.1c0.5-0.4,0.8-0.8,0.8-0.8
                S266.2,185.6,265.8,186.1 M292.7,190.9c0,0.6,0,0.8,0.1,0.5c0-0.3,0-0.8,0-1C292.7,190,292.7,190.3,292.7,190.9 M208.5,202.3
                c0.3,0,0.8,0,1,0c0.3,0,0-0.1-0.6-0.1C208.4,202.3,208.2,202.3,208.5,202.3 M281.2,202.3c0.3,0,0.8,0,1.1,0c0.3,0,0.1-0.1-0.6-0.1
                C281.1,202.3,280.8,202.3,281.2,202.3 M382.1,217.4c0,2.3,0,3.2,0.1,2.1c0-1.2,0-3,0-4.2C382.1,214.2,382.1,215.1,382.1,217.4
                 M176.1,226.7l-0.8,0.9L176.1,226.7c0.5-0.4,0.9-0.8,0.9-0.8C177.1,225.7,176.9,225.9,176.1,226.7 M50,226.4c0.2,0,0.4,0,0.6,0
                c0.2,0,0-0.1-0.3-0.1C49.9,226.4,49.8,226.4,50,226.4 M142.3,226.4c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                C142.2,226.4,142.1,226.4,142.3,226.4 M177.9,239.9l-0.5,0.6L177.9,239.9c0.6-0.4,0.7-0.6,0.6-0.6
                C178.4,239.3,178.1,239.6,177.9,239.9 M382.1,241.4c0,0.7,0,1,0.1,0.6c0-0.4,0-0.9,0-1.3C382.1,240.4,382.1,240.7,382.1,241.4
                 M284.2,246.6c0.3,0,0.8,0,1.1,0c0.3,0,0.1-0.1-0.6-0.1C284.2,246.6,283.9,246.6,284.2,246.6 M282,251.2c0.2,0,0.6,0,0.8,0
                c0.2,0,0-0.1-0.4-0.1S281.8,251.2,282,251.2 M287.5,251.2c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1S287.3,251.2,287.5,251.2"/>
            <path id="path3" fillRule="evenodd" clipRule="evenodd" fill="#A4C5E6" d="M154.8,3.4c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C154.8,3.3,154.6,3.3,154.8,3.4 M175.4,4c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C175.4,4,175.3,4,175.4,4 M159.5,5.4
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S159.4,5.4,159.5,5.4 M157.6,5.6c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C157.5,5.5,157.4,5.5,157.6,5.6 M155.7,5.8c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C155.6,5.7,155.5,5.7,155.7,5.8 M206.9,20.1
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S206.7,20.1,206.9,20.1 M175.6,22.4c-4.3,0.4-10.5,1.5-10.2,1.9c0.1,0.1,0,0.1-0.1,0.1
                c-0.3-0.2-1.2,0.1-1.2,0.4c0,0.2,0,0.2-0.1,0.1c-0.1-0.2-0.4-0.1-1.2,0.1c-1.8,0.6-3.1,1.2-3,1.4c0.1,0.1,0.2,0.1,0.2,0
                c0.1-0.1,0.3-0.2,0.4-0.1c0.2,0.1,0.2,0,0.2-0.1c-0.1-0.1,0-0.2,0.2-0.1s0.4,0,0.5-0.1c0.3-0.3,0.6-0.3,0.6,0c0,0.2,0,0.2,0.2,0
                c0.2-0.3,0.4,0,0.3,0.4c-0.2,0.5,0.6,0.5,1.4,0.1c0.3-0.2,0.6-0.3,0.5-0.2c-0.1,0.1,0,0.1,0.2,0.1c0.1-0.1,0.4,0,0.5,0.2
                s0.4,0.3,0.6,0.3c0.2,0,0.3,0,0,0.2s-0.6,0.2-0.4,0c0.1-0.1-0.1-0.2-0.7-0.1c-0.8,0.1-1.5,0.6-1.4,0.9c0.1,0.1,0,0.2-0.1,0.3
                c-0.2,0.1-0.1,0.1,0.1,0c0.2-0.1,1.1-0.4,2.1-0.7c1.7-0.6,2.1-0.9,1.4-0.9c-0.3,0-0.3,0-0.2-0.2c0.2-0.2,0.3-0.1,0.5,0.1
                c0.3,0.3,0.4,0.3,1.2,0c6.6-1.9,16.1-2.3,24.7-1.1c2.7,0.4,2.3,0.3,5.6,1c3.6,0.8,8.4,2.1,10.1,2.8c0.2,0.1,0.3,0,0.5-0.2
                c0.2-0.4,0.4-0.3-2.4-1.4C196.3,23.5,184.6,21.7,175.6,22.4 M165.8,25.1c0,0.1-0.1,0.2-0.3,0.3c-0.3,0.1-0.8-0.1-0.7-0.3
                C165,24.9,165.8,24.9,165.8,25.1 M163.5,25.4c0.8,0.3,0.8,0.4,0.1,0.3c-0.3,0-0.6-0.1-0.8-0.1c-0.1,0-0.2-0.1-0.1-0.3
                C162.8,25.1,162.7,25.1,163.5,25.4 M159.1,26.5c-0.2,0.1-0.2,0.2,0.1,0.3c0.4,0.1,0.5,0.1,0.3-0.2
                C159.4,26.4,159.3,26.4,159.1,26.5 M157.3,27.4c-0.7,0.4-1.4,0.8-1.5,0.9s0.5-0.2,1.4-0.6c1.4-0.7,1.8-0.9,1.5-0.9
                C158.7,26.7,158,27,157.3,27.4 M254.4,26.7c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C254.5,26.7,254.4,26.6,254.4,26.7 M212.6,28.2
                l-0.5,0.6L212.6,28.2c0.6-0.4,0.7-0.6,0.6-0.6C213.1,27.7,212.8,27.9,212.6,28.2 M162.2,28c0,0.1-0.4,0.3-0.9,0.5s-1.1,0.5-1.3,0.7
                s0.2,0,1-0.4c1.7-0.8,1.6-0.8,1.4-0.9C162.3,27.9,162.2,27.9,162.2,28 M137.8,28.5c0.3,0,0.7,0,0.9,0c0.3,0,0-0.1-0.5-0.1
                S137.6,28.5,137.8,28.5 M154.4,29.1c-0.6,0.4-0.8,0.6-0.3,0.3c0.4-0.2,1-0.7,0.9-0.7C155,28.7,154.7,28.9,154.4,29.1 M158.4,30.1
                c-0.3,0.2-0.7,0.4-0.8,0.5s0.2,0,0.5-0.2C159.4,29.7,159.5,29.5,158.4,30.1 M155.5,32c-0.4,0.3-0.9,0.7-0.9,0.8
                c-0.1,0.1,0.3-0.1,0.8-0.5c0.8-0.6,1.1-0.9,0.9-0.9C156.3,31.5,156,31.7,155.5,32 M150.2,32.3l-0.6,0.6L150.2,32.3
                c0.6-0.5,0.8-0.6,0.6-0.6C150.9,31.6,150.6,31.9,150.2,32.3 M153.7,33.5c-0.3,0.3-0.4,0.4-0.3,0.4c0.3-0.2,1.1-0.9,0.9-0.9
                C154.3,33,154,33.2,153.7,33.5 M147.9,34.7l-0.6,0.6L147.9,34.7c0.6-0.5,0.8-0.6,0.6-0.6C148.5,34,148.2,34.3,147.9,34.7 M152,35.1
                l-0.8,0.9L152,35.1c0.9-0.7,1-0.9,0.9-0.9C152.9,34.2,152.5,34.6,152,35.1 M220.1,35.8l-0.5,0.6L220.1,35.8
                c0.6-0.4,0.7-0.6,0.6-0.6C220.6,35.2,220.4,35.5,220.1,35.8 M176.4,35.8c-6.6,1.2-13.6,5.5-17.2,10.6c-4.3,6.1-5.4,16.1-2.5,23.8
                c0.6,1.5,0.8,1.9,1.3,1.7c0.6-0.2,0.6-0.6-0.1-2.7c-2.7-8.1-1.3-17.7,3.5-23.1c4.1-4.7,9.7-7.8,15.8-8.8c1.4-0.2,1.8-0.8,1.1-1.4
                C178,35.5,178.1,35.5,176.4,35.8 M100.6,39.3c0,0.5,0,0.7,0.1,0.5c0-0.3,0-0.7,0-0.9C100.6,38.6,100.6,38.8,100.6,39.3 M204.6,38.8
                c-0.3,0.2-0.4,0.9-0.1,1.1c1,0.6,4.3,1.7,4.7,1.6c1.2-0.5,0.4-1.3-2.1-2.2C205.2,38.7,204.9,38.6,204.6,38.8 M146.8,42.7
                c-0.2,0.3-0.2,0.6-0.2,0.6c0.1,0,0.6-1,0.6-1.1C147.1,42.1,146.9,42.3,146.8,42.7 M249.6,47.7l-0.5,0.6L249.6,47.7
                c0.6-0.4,0.7-0.6,0.6-0.6C250.2,47.2,249.9,47.4,249.6,47.7 M192.5,47.6c-8.4,0.8-21.1,4.2-20.4,5.4c0.1,0.1,0,0.1-0.1,0.1
                c-0.6-0.4-1.5,1.7-1.5,3.5c0,2.8,1.3,4.5,4.3,5.4c0.6,0.2,1.4,0.4,1.8,0.6c0.4,0.2,1.8,0.5,3,0.8s2.5,0.6,2.9,0.8
                c8,2.7,26.6,2.6,37.4-0.2c9.3-2.4,10.9-3.3,11.2-6.8c0.3-4.3-2.6-6-14.8-8.4C209.6,47.5,199,46.9,192.5,47.6 M276.9,48.8
                c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1C276.8,48.8,276.7,48.8,276.9,48.8 M275,49c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C274.9,48.9,274.8,49,275,49 M246.9,50.6l-0.5,0.6L246.9,50.6c0.6-0.4,0.7-0.6,0.6-0.6C247.4,50.1,247.2,50.3,246.9,50.6 M280,51.1
                c0.3,0,0.8,0,1,0c0.3,0,0-0.1-0.6-0.1S279.7,51,280,51.1 M277.9,51.2c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C277.8,51.2,277.7,51.2,277.9,51.2 M275.9,51.4c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1C275.9,51.3,275.8,51.4,275.9,51.4 M246.6,53.2
                c0.6,0.6,1.1,1,1.1,1s-0.4-0.5-0.9-1c-0.6-0.6-1.1-1-1.1-1C245.6,52.1,246,52.6,246.6,53.2 M140,53.8c0,0.3,0,0.5,0.1,0.3
                c0-0.2,0-0.4,0-0.6S140,53.5,140,53.8 M120.7,57.5c0,1,0,1.4,0.1,0.9c0-0.5,0-1.4,0-1.9C120.8,56,120.7,56.5,120.7,57.5 M140,57.6
                c0,0.3,0,0.5,0.1,0.3c0-0.2,0-0.4,0-0.6C140.1,57.1,140,57.3,140,57.6 M116.7,62.9c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C116.6,62.8,116.5,62.8,116.7,62.9 M114.8,63c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C114.8,63,114.6,63,114.8,63 M116.7,65.2
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C116.6,65.2,116.5,65.2,116.7,65.2 M114.8,65.4c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C114.8,65.3,114.6,65.4,114.8,65.4 M172.1,67.9C171.6,68.4,171.6,68.4,172.1,67.9c0.3-0.1,0.6-0.4,0.7-0.5c0.1-0.1,0.1-0.1,0-0.1
                S172.4,67.6,172.1,67.9 M177.5,67.9c0.2,0.1,0.5,0.1,0.6,0.1c0.2,0,0.1-0.1-0.1-0.1c-0.2-0.1-0.5-0.1-0.6-0.1
                C177.3,67.7,177.3,67.8,177.5,67.9 M229.4,67.9c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C229.5,67.9,229.4,67.8,229.4,67.9
                 M194.4,70.9c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1S194.2,70.8,194.4,70.9 M207.6,70.9c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                C207.5,70.8,207.4,70.8,207.6,70.9 M197.1,71.1c0.3,0,0.8,0,1.1,0s0.1-0.1-0.6-0.1C197,71,196.7,71,197.1,71.1 M204.6,71.1
                c0.3,0,0.8,0,1,0c0.3,0,0-0.1-0.6-0.1C204.5,71,204.3,71,204.6,71.1 M242.9,73.6c-0.3,0.1-0.3,0.7,0.3,2.3
                c2.1,6.4,2.1,11.7-0.2,18.2c-2.2,6.4-9.8,12.1-18.3,13.8c-2.1,0.4-2.5,0.6-2.5,1.1c0,1.8,8.3-0.5,13-3.7c4.4-3,7.9-7,9.2-10.7
                c2.4-6.6,2.5-12.4,0.4-19C244.1,73.6,243.8,73.3,242.9,73.6 M229.9,76l-0.6,0.6L229.9,76c0.4-0.3,0.7-0.6,0.7-0.6
                C230.6,75.3,230.5,75.4,229.9,76 M293.1,78.6c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C293,78.5,292.9,78.5,293.1,78.6 M291.1,78.8
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C291,78.7,290.9,78.7,291.1,78.8 M289.3,78.9c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1
                S289.1,78.9,289.3,78.9 M290.6,81c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C290.5,80.9,290.3,80.9,290.6,81 M288.5,81.1c0.2,0,0.5,0,0.7,0
                s0-0.1-0.4-0.1C288.4,81.1,288.3,81.1,288.5,81.1 M192.5,81.3c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                C192.5,81.2,192.4,81.3,192.5,81.3 M209.1,81.3c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C209.1,81.2,208.9,81.3,209.1,81.3 M195,81.5
                c0.5,0,1.3,0,1.7,0s0-0.1-0.9-0.1S194.5,81.5,195,81.5 M205.8,81.5c0.5,0,1.3,0,1.7,0s0-0.1-0.9-0.1
                C205.6,81.4,205.3,81.5,205.8,81.5 M279.4,84.5c0,0.5,0,0.6,0.1,0.4c0-0.3,0-0.6,0-0.9C279.4,83.8,279.4,84.1,279.4,84.5
                 M281.6,85.2c0,0.7,0,0.9,0.1,0.6c0-0.4,0-0.9,0-1.2C281.6,84.3,281.6,84.6,281.6,85.2 M258.5,88.5c0,1.4,0,1.9,0.1,1.2
                c0-0.7,0-1.8,0-2.5C258.6,86.6,258.5,87.2,258.5,88.5 M196.8,86.4c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                C196.8,86.4,196.6,86.4,196.8,86.4 M205.5,86.4c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C205.5,86.4,205.3,86.4,205.5,86.4
                 M231.5,87.8c0,0.5,0,0.6,0.1,0.4c0-0.3,0-0.6,0-0.9C231.5,87.1,231.5,87.3,231.5,87.8 M281.6,88.2c0,0.6,0,0.9,0.1,0.6
                c0-0.3,0-0.8,0-1.1C281.6,87.3,281.6,87.6,281.6,88.2 M262.6,89.1c0,0.7,0,0.9,0.1,0.6c0-0.4,0-0.9,0-1.2
                C262.6,88.2,262.6,88.5,262.6,89.1 M279.4,89.5c0,0.7,0,0.9,0.1,0.6c0-0.4,0-0.9,0-1.2C279.4,88.5,279.4,88.8,279.4,89.5
                 M153.1,92.8c-1.1,1.1-2,2.1-2,2.1s1-0.9,2.1-2.1c1.1-1.1,2-2.1,2-2.1C155.2,90.8,154.2,91.7,153.1,92.8 M114.4,92
                c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C114.5,92,114.4,91.9,114.4,92 M131.9,92.6c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                S131.7,92.6,131.9,92.6 M130,92.8c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C130,92.7,129.8,92.7,130,92.8 M128.2,92.9c0.2,0,0.4,0,0.6,0
                c0.2,0,0-0.1-0.3-0.1C128.2,92.9,128.1,92.9,128.2,92.9 M122,93.5c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C121.9,93.4,121.8,93.4,122,93.5
                 M281.2,94.1c0.3,0.3,0.6,0.6,0.7,0.6s-0.2-0.3-0.5-0.6c-0.3-0.3-0.6-0.6-0.7-0.6S280.9,93.8,281.2,94.1 M130.8,94.8
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C130.8,94.7,130.6,94.8,130.8,94.8 M128.8,95c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                S128.6,95,128.8,95 M127,95.2c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1S126.8,95.1,127,95.2 M259.1,103.1c-0.1,0.3-0.2,0.5-0.2,0.5
                s0.2-0.2,0.4-0.5c0.1-0.3,0.2-0.5,0.2-0.5S259.2,102.8,259.1,103.1 M251.4,108.4l-0.5,0.6L251.4,108.4c0.6-0.4,0.7-0.6,0.6-0.6
                C251.9,107.9,251.6,108.1,251.4,108.4 M254.3,110l-0.7,0.7L254.3,110c0.7-0.6,0.9-0.7,0.7-0.7C255,109.2,254.6,109.6,254.3,110
                 M249.6,110.1l-0.7,0.7L249.6,110.1c0.7-0.6,0.9-0.7,0.7-0.7C250.3,109.4,250,109.7,249.6,110.1 M252.1,112.1l-0.6,0.6L252.1,112.1
                c0.6-0.5,0.8-0.6,0.6-0.6C252.7,111.5,252.5,111.7,252.1,112.1 M246.6,112.6c-0.3,0.3-0.4,0.4-0.2,0.3c0.3-0.2,0.9-0.7,0.7-0.7
                C247.1,112.1,246.9,112.3,246.6,112.6 M245.3,113.4c-0.8,0.6-0.9,0.7-0.2,0.3c0.3-0.2,0.6-0.4,0.7-0.5
                C246.1,112.9,246,112.9,245.3,113.4 M249.3,114.3l-0.5,0.4L249.3,114.3c0.3-0.1,0.6-0.3,0.6-0.4
                C249.9,113.8,249.8,113.8,249.3,114.3 M242.5,115.1c-1.8,1-1.8,1.1-0.1,0.3c1-0.5,2-1.1,1.7-1.1
                C244.1,114.2,243.3,114.6,242.5,115.1 M193.8,115.5c-0.1,0.1-0.1,0.3-0.1,0.3c0,0.3,7.1,2.7,10.9,3.7c9.5,2.4,17.9,3,26.2,1.8
                c4.8-0.7,10.3-2.2,8-2.2c-0.3,0-0.5-0.1-0.5-0.2s-0.1-0.2-0.2-0.2s-0.1,0.1-0.1,0.3c0.1,0.2,0,0.2-0.1,0.2c-0.1-0.1-0.2,0-0.3,0.1
                c0,0.1-0.1,0.1-0.1,0s-0.4-0.2-0.9-0.1c-0.8,0-0.8,0-0.5-0.2c0.2-0.1,0.5-0.2,0.5-0.3c0,0,0.3-0.1,0.5-0.1s0.5-0.1,0.6-0.2
                s0.5-0.1,0.8-0.1l0.6,0.1l-0.4-0.3c-0.3-0.2-0.5-0.3-1-0.1c-1,0.3-1.9,0.4-1.9,0.2c0-0.2,0.8-0.5,1.1-0.5c0.1,0,0.2-0.1,0.2-0.2
                s0.4-0.3,0.8-0.4c1.4-0.4,2.4-0.9,2.4-1s-0.4,0-0.9,0.2c-2.2,0.9-3.1,1.2-5,1.7c-6,1.5-11.5,2.1-17.5,1.7
                c-8.3-0.5-13.8-1.6-22.8-4.4C194,115.3,193.8,115.3,193.8,115.5 M264.1,116c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1
                C264.1,115.9,264,116,264.1,116 M242.5,117.9c0.2,0.2-0.9,0.5-1.6,0.4c-0.4-0.1-0.6-0.1-0.5,0.1c0.1,0.1,0.2,0.1,0.3,0.1
                c0.1-0.1,0.1,0,0.1,0.1c-0.1,0.1-0.3,0.2-0.5,0.2s-0.3,0.1-0.3,0.2s-0.1,0.2-0.3,0.2s-0.2,0-0.2,0.1c0.1,0.1,3.4-1.1,3.4-1.2
                C242.9,118.1,242.8,118,242.5,117.9C242.5,117.8,242.4,117.8,242.5,117.9 M234.4,119.2c-0.1,0.2-0.7,0.1-1-0.1
                c-0.1-0.1,0-0.1,0.4-0.1C234.2,119,234.4,119.1,234.4,119.2 M251.3,123.7c0,0.5,0,0.6,0.1,0.4c0-0.3,0-0.6,0-0.9
                C251.4,123,251.3,123.2,251.3,123.7 M284.4,123.5c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C284.3,123.5,284.2,123.5,284.4,123.5 M259.7,136
                c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7C259.7,135.4,259.7,135.6,259.7,136 M219.3,137.7c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6
                C219.5,137.6,219.3,137.5,219.3,137.7 M251.2,138.2c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C251.2,138.2,251,138.2,251.2,138.2
                 M258.5,138.8l-0.6,0.6L258.5,138.8c0.6-0.5,0.8-0.6,0.6-0.6C259.1,138.1,258.8,138.4,258.5,138.8 M249.4,138.4c0.2,0,0.5,0,0.7,0
                s0-0.1-0.4-0.1C249.3,138.3,249.1,138.4,249.4,138.4 M247.4,138.6c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S247.2,138.5,247.4,138.6
                 M231.7,139.9c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C231.6,139.9,231.5,139.9,231.7,139.9 M229.9,140.1c0.2,0,0.5,0,0.7,0
                s0-0.1-0.4-0.1S229.7,140.1,229.9,140.1 M227.9,140.3c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S227.7,140.2,227.9,140.3 M252.7,140.5
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S252.5,140.4,252.7,140.5 M250.6,140.6c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                S250.3,140.6,250.6,140.6 M248.8,140.8c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C248.7,140.7,248.6,140.8,248.8,140.8 M246.9,141
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S246.7,140.9,246.9,141 M232.9,142.2c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                C232.8,142.1,232.7,142.1,232.9,142.2 M231,142.3c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S230.8,142.3,231,142.3 M229,142.5
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C228.9,142.4,228.8,142.5,229,142.5 M117.6,171.4c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                S117.4,171.4,117.6,171.4 M119.3,171.4c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1C119.2,171.3,119.1,171.4,119.3,171.4 M297.3,173.2
                c0,0.1-0.1,1.5,0,3.1v2.9h0.4c0.4,0,0.4,0,0-0.1s-0.4-0.1-0.4-3V173.2l1.2-0.1l1.1-0.1h-1.1C297.8,173,297.4,173.1,297.3,173.2
                 M126.5,173.9c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C126.6,173.9,126.5,173.8,126.5,173.9 M194.2,174.7c0,0.4,0,0.6,0.1,0.4
                c0-0.2,0-0.6,0-0.8C194.3,174.1,194.2,174.3,194.2,174.7 M304.5,176.2c0,0.4,0,0.6,0.1,0.4c0-0.2,0-0.6,0-0.8
                C304.5,175.6,304.5,175.8,304.5,176.2 M298.6,176.2c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1S298.4,176.1,298.6,176.2 M298.3,177
                c0,0,0.3,0.1,0.6,0.1c0.3,0,0.5,0,0.5-0.1C299.3,176.9,298.3,176.9,298.3,177 M117.9,177.4c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                S117.7,177.3,117.9,177.4 M119.3,177.4c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1C119.2,177.3,119.1,177.3,119.3,177.4 M115.3,179.8
                c0,0.4,0,0.6,0.1,0.4c0-0.2,0-0.6,0-0.8C115.3,179.2,115.3,179.4,115.3,179.8 M300.9,179.3c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1
                C300.9,179.2,300.7,179.2,300.9,179.3 M208.5,179.6c0.3,0,0.8,0,1,0c0.3,0,0-0.1-0.6-0.1C208.4,179.5,208.2,179.6,208.5,179.6
                 M281.1,179.6c0.3,0,0.7,0,0.9,0c0.3,0,0-0.1-0.5-0.1S280.8,179.6,281.1,179.6 M142.7,179.8c0.3,0,0.8,0,1.2,0s0.1-0.1-0.6-0.1
                C142.7,179.7,142.4,179.7,142.7,179.8 M172.8,179.8c0.3,0,0.8,0,1.1,0s0.1-0.1-0.6-0.1C172.7,179.7,172.5,179.7,172.8,179.8
                 M149.8,180.2c-0.1,0.4-0.2,2.5,0,2.4c0.1,0,0.1-0.6,0.1-1.3L149.8,180.2l3.2-0.1l3.1-0.1H153C150.7,180,149.9,180.1,149.8,180.2
                 M160.4,180.2c0,0.1-0.1,6.9,0,15.1v14.9v-15V180.2l3.2-0.1l3.1-0.1h-3.1C161.3,180,160.5,180.1,160.4,180.2 M187.4,180.2
                c0,0.1-0.1,5,0,10.9v10.7h3h3l-2.9-0.1l-2.9-0.1v-10.8L187.4,180.2l3.2-0.1l3.1-0.1h-3.1C188.3,180,187.5,180.1,187.4,180.2
                 M223.3,180.2c0,0.1-0.1,5,0,10.9v10.7V191V180.2l3.2-0.1l3.1-0.1h-3.1C224.2,180,223.4,180.1,223.3,180.2 M135.8,182.8l-0.7,0.7
                L135.8,182.8c0.7-0.6,0.9-0.7,0.7-0.7C136.5,182.1,136.2,182.4,135.8,182.8 M272.9,182.9l-0.7,0.7L272.9,182.9
                c0.7-0.6,0.9-0.7,0.7-0.7C273.6,182.2,273.3,182.5,272.9,182.9 M200.2,183l-0.6,0.6L200.2,183c0.6-0.5,0.8-0.6,0.6-0.6
                C200.8,182.4,200.5,182.7,200.2,183 M208.6,184.9c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C208.6,184.8,208.4,184.9,208.6,184.9
                 M281.3,184.9c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C281.2,184.8,281.1,184.9,281.3,184.9 M144.2,185.6c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C144.2,185.5,144,185.6,144.2,185.6 M171.8,185.6c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1C171.7,185.5,171.5,185.6,171.8,185.6
                 M244.3,194.7v7l-3.2,0.1l-3.2,0.1h3.2c2.4,0,3.2,0,3.3-0.2c0-0.1,0.1-3.3,0-7.1v-6.9L244.3,194.7 M206.2,188.5c1.4,0,3.7,0,5,0
                c1.4,0,0.3-0.1-2.5-0.1C205.9,188.5,204.8,188.5,206.2,188.5 M278.8,188.5c1.4,0,3.7,0,5,0c1.4,0,0.3-0.1-2.5-0.1
                C278.6,188.5,277.5,188.5,278.8,188.5 M220.1,190.8c0,0.7,0,1,0.1,0.6c0-0.4,0-0.9,0-1.3C220.1,189.8,220.1,190.1,220.1,190.8
                 M132.5,190.9c0,0.7,0,1,0.1,0.6c0-0.4,0-0.9,0-1.3C132.6,189.9,132.5,190.2,132.5,190.9 M177.7,190.9c0,0.5,0,0.7,0.1,0.5
                c0-0.3,0-0.7,0-0.9C177.7,190.2,177.7,190.4,177.7,190.9 M253.9,190.9c0,0.5,0,0.6,0.1,0.4s0-0.6,0-0.9
                C253.9,190.2,253.9,190.4,253.9,190.9 M197.1,191.1c0,0.5,0,0.7,0.1,0.5c0-0.3,0-0.7,0-0.9C197.2,190.4,197.1,190.6,197.1,191.1
                 M150.1,191c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7C150.2,190.5,150.1,190.6,150.1,191 M130.1,193.1c0,0.4,0,0.5,0.1,0.3
                c0-0.2,0-0.5,0-0.7C130.2,192.5,130.1,192.7,130.1,193.1 M123.5,193.4c0,0.4,0,0.5,0.1,0.3s0-0.5,0-0.7
                C123.5,192.9,123.5,193,123.5,193.4 M263.9,194.8l-0.4,0.5L263.9,194.8c0.4-0.2,0.6-0.4,0.7-0.4c0,0,0,0-0.1,0
                C264.4,194.4,264.1,194.6,263.9,194.8 M289.4,196.8c0.7,0.8,0.9,0.9,0.9,0.8C290.3,197.5,289.9,197.2,289.4,196.8l-0.8-0.8
                L289.4,196.8 M118.4,196.4c0.3,0,0.8,0,1.1,0c0.3,0,0.1-0.1-0.6-0.1S118.1,196.3,118.4,196.4 M217.1,197.1c0.7,0.8,0.9,0.9,0.9,0.8
                C217.9,197.9,217.6,197.5,217.1,197.1l-0.8-0.8L217.1,197.1 M108.7,198.5c0.7,0.8,0.9,0.9,0.9,0.8
                C109.6,199.2,109.2,198.9,108.7,198.5l-0.8-0.8L108.7,198.5 M200.2,199.1c0.3,0.3,0.6,0.6,0.7,0.6s-0.2-0.3-0.5-0.6
                s-0.6-0.6-0.7-0.6S199.9,198.7,200.2,199.1 M272.3,198.5c0,0,0.2,0.3,0.6,0.6l0.6,0.5L273,199C272.4,198.5,272.3,198.4,272.3,198.5
                 M180.6,199.4l-0.5,0.6L180.6,199.4c0.6-0.4,0.7-0.6,0.6-0.6C181.1,198.8,180.9,199.1,180.6,199.4 M127.6,199.5l-0.5,0.6
                L127.6,199.5c0.6-0.4,0.7-0.6,0.6-0.6C128.1,199,127.9,199.2,127.6,199.5 M149.8,200.6c0,0.8,0,1,0.1,0.6c0-0.4,0-1,0-1.4
                C149.8,199.5,149.8,199.8,149.8,200.6 M148.8,200.4l-0.5,0.6L148.8,200.4c0.4-0.2,0.6-0.5,0.6-0.5
                C149.4,199.8,149.3,199.9,148.8,200.4 M151.6,201.8c0.9,0,2.3,0,3.2,0s0.2-0.1-1.6-0.1C151.4,201.8,150.7,201.8,151.6,201.8
                 M225,201.8c0.8,0,2.2,0,3,0s0.2-0.1-1.5-0.1C224.9,201.8,224.2,201.8,225,201.8 M141.8,202.2c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C141.8,202.1,141.6,202.1,141.8,202.2 M144.3,202.2c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C144.3,202.1,144.2,202.1,144.3,202.2
                 M174.2,202.2c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S174.1,202.1,174.2,202.2 M117.7,202.3c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C117.7,202.3,117.5,202.3,117.7,202.3 M119.6,202.3c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1S119.4,202.3,119.6,202.3 M129.6,215
                c0,1.5,0,2.1,0.1,1.3c0-0.7,0-1.9,0-2.6S129.6,213.6,129.6,215 M3.9,212.8c0.8,0,2,0,2.8,0s0.1-0.1-1.4-0.1S3.1,212.7,3.9,212.8
                 M30,212.8c0.8,0,2,0,2.8,0s0.1-0.1-1.4-0.1C29.9,212.7,29.3,212.7,30,212.8 M185.8,212.8l3.1,0.1l0.1,4.1l0.1,4.1V217v-4.1
                L185.8,212.8l-3.1-0.1L185.8,212.8 M319.7,212.9c0,0.1-0.1,6.9,0,15.1v14.9v-15V212.9h3.3h3.2v5.5v5.5h6.5h6.5v-5.5v-5.5l3.2-0.1
                l3.2-0.1h-3.2h-3.2v5.5v5.5h-6.4h-6.4v-5.5v-5.5h-3.3C320.6,212.7,319.8,212.7,319.7,212.9 M376.8,212.8c2.7,0.1,5.4,0.1,5.4,0
                s-1.5-0.1-3.3-0.1S376.1,212.7,376.8,212.8 M118.5,220c0,0.5,0,0.7,0.1,0.5c0-0.3,0-0.7,0-0.9C118.5,219.3,118.5,219.5,118.5,220
                 M124.7,220.3c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7C124.7,219.7,124.7,219.9,124.7,220.3 M78.3,220.6c0.5,0,1.1,0,1.5,0
                s0-0.1-0.8-0.1C78.2,220.6,77.9,220.6,78.3,220.6 M232.9,220.6c1.2,0.1,2.6,0.1,2.5,0c0-0.1-0.7-0.1-1.6-0.1
                S232.6,220.6,232.9,220.6 M261.6,220.6c0.5,0,1.1,0,1.5,0s0-0.1-0.8-0.1C261.5,220.6,261.1,220.6,261.6,220.6 M284.3,220.6
                c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1C284.3,220.6,284.2,220.6,284.3,220.6 M388.2,220.6c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1
                S388.1,220.6,388.2,220.6 M114.9,223.9c0,1.6,0,2.3,0.1,1.5c0-0.8,0-2.2,0-3S114.9,222.3,114.9,223.9 M179.9,223.9
                c0,1.6,0,2.3,0.1,1.5c0-0.8,0-2.2,0-3C179.9,221.6,179.9,222.3,179.9,223.9 M193.7,223.9c0,1.6,0,2.3,0.1,1.5c0-0.8,0-2.2,0-3
                C193.8,221.6,193.7,222.3,193.7,223.9 M245.2,231.9c0,6,0,8.5,0,5.4c0-3,0-8,0-10.9S245.2,225.9,245.2,231.9 M249.5,231.9
                c0,6,0,8.5,0,5.4s0-8,0-10.9S249.5,225.9,249.5,231.9 M297.3,230.9c0,5.5,0,7.7,0,5s0-7.2,0-9.9
                C297.4,223.2,297.3,225.4,297.3,230.9 M357.3,227.6c0,3.7,0,5.1,0.1,3.3c0-1.9,0-4.9,0-6.7C357.4,222.4,357.3,223.9,357.3,227.6
                 M371.5,231.9c0,6,0,8.5,0,5.4s0-8,0-10.9S371.5,225.9,371.5,231.9 M55.5,222.4c0,0.7,0,1.2,0.1,1.2s0.1-0.5,0.1-1.2
                C55.7,220.8,55.6,220.8,55.5,222.4 M72.7,222.4c0,0.7,0,1.2,0.1,1.2s0.1-0.4,0.1-0.9C72.8,221.2,72.7,221,72.7,222.4 M97.7,227.8
                c0,3.7,0,5.1,0.1,3.3c0-1.9,0-4.9,0-6.7C97.7,222.6,97.7,224.1,97.7,227.8 M111.9,232c0,6,0,8.4,0,5.4s0-7.9,0-10.9
                C111.9,223.6,111.9,226,111.9,232 M147.7,222.1c0,0.5,0,0.7,0.1,0.5c0-0.3,0-0.7,0-0.9C147.8,221.3,147.7,221.5,147.7,222.1
                 M154.3,231.9c0,5.9,0,8.3,0,5.3s0-7.8,0-10.8C154.3,223.5,154.3,226,154.3,231.9 M210.5,227.6c0,3.6,0,5.1,0,3.3s0-4.7,0-6.5
                S210.5,224,210.5,227.6 M221.1,232c0,6,0,8.4,0,5.4s0-7.9,0-10.9C221.1,223.6,221.1,226,221.1,232 M227.6,222.4c0,0.7,0,1,0.1,0.6
                c0-0.4,0-0.9,0-1.3C227.6,221.4,227.6,221.7,227.6,222.4 M382.1,222.1c0,0.3,0,0.5,0.1,0.3c0-0.2,0-0.4,0-0.6
                C382.1,221.6,382.1,221.7,382.1,222.1 M228.6,222.4l-0.5,0.6L228.6,222.4c0.6-0.4,0.7-0.6,0.6-0.6
                C229.1,221.9,228.9,222.1,228.6,222.4 M277,223.6l-0.5,0.6L277,223.6c0.6-0.4,0.7-0.6,0.6-0.6C277.5,223.1,277.3,223.3,277,223.6
                 M177.9,223.8c0.3,0.3,0.6,0.6,0.7,0.6s-0.2-0.3-0.5-0.6s-0.6-0.6-0.7-0.6C177.4,223.2,177.6,223.5,177.9,223.8 M76.7,226.3
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C76.6,226.2,76.5,226.2,76.7,226.3 M259.8,226.3c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1
                S259.6,226.2,259.8,226.3 M232,226.6c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S231.8,226.6,232,226.6 M234.2,226.6c0.2,0,0.4,0,0.6,0
                c0.2,0,0-0.1-0.3-0.1S234.1,226.6,234.2,226.6 M189,231.1c0,2.4,0,3.4,0.1,2.2c0-1.2,0-3.2,0-4.4C189,227.7,189,228.7,189,231.1
                 M118.5,233.9c0,3.9,0,5.5,0,3.6s0-5.1,0-7C118.6,228.5,118.5,230.1,118.5,233.9 M124.7,234.9c0,4.4,0,6.2,0,4s0-5.8,0-7.9
                C124.7,228.7,124.7,230.5,124.7,234.9 M138.5,228.2l-0.6,0.6L138.5,228.2c0.6-0.5,0.8-0.6,0.6-0.6
                C139.1,227.5,138.8,227.8,138.5,228.2 M174.9,227.9c-0.3,0.3-0.4,0.3-0.7,0.1s-0.3-0.2,0,0.1C174.5,228.4,174.5,228.4,174.9,227.9
                c0.3-0.2,0.5-0.4,0.5-0.4C175.3,227.5,175.1,227.7,174.9,227.9 M80.9,236.1c0,3.6,0,5.1,0.1,3.3c0-1.8,0-4.8,0-6.6
                S80.9,232.4,80.9,236.1 M72.7,236.2c0,3.5,0,5,0.1,3.2c0-1.8,0-4.6,0-6.4C72.8,231.3,72.7,232.7,72.7,236.2 M264.2,236.3
                c0,3.6,0,5.1,0.1,3.3c0-1.8,0-4.7,0-6.5C264.2,231.3,264.2,232.8,264.2,236.3 M326.4,236.2c0,3.5,0,5,0.1,3.2c0-1.8,0-4.6,0-6.4
                C326.4,231.3,326.4,232.7,326.4,236.2 M339.4,236.3c0,3.6,0,5.1,0,3.3s0-4.7,0-6.5S339.4,232.8,339.4,236.3 M227.6,236.8
                c0,3.2,0,4.6,0.1,2.9c0-1.6,0-4.3,0-5.9C227.6,232.2,227.6,233.5,227.6,236.8 M381.8,231.9c0,0.6,0,0.8,0.1,0.5c0-0.3,0-0.8,0-1
                C381.8,231.1,381.8,231.3,381.8,231.9 M399.4,231.9c0,0.6,0,0.8,0.1,0.5c0-0.3,0-0.8,0-1C399.4,231.1,399.4,231.3,399.4,231.9
                 M137,231.9c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7C137,231.3,137,231.5,137,231.9 M202.8,233.7c0,0.5,0,0.7,0.1,0.5
                c0-0.3,0-0.7,0-0.9C202.8,232.9,202.8,233.2,202.8,233.7 M289.7,235.5l-0.6,0.6L289.7,235.5c0.4-0.3,0.7-0.6,0.7-0.6
                C290.4,234.8,290.3,234.9,289.7,235.5 M382.7,235.1c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C382.9,235.1,382.7,235,382.7,235.1
                 M176.6,237.4c1.1,1.1,2,2,2,2s-0.8-0.9-1.9-2c-1.1-1.1-2-2-2-2C174.7,235.4,175.5,236.3,176.6,237.4 M206.2,237.6
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S206,237.5,206.2,237.6 M159.8,238.9c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6
                C160,238.8,159.8,238.7,159.8,238.9 M41.2,239.7c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C41.3,239.7,41.2,239.6,41.2,239.7
                 M55.5,241.1c0.1,1.5,0.2,1.6,0.2,0.3c0-0.7,0-1.2-0.1-1.2C55.5,240.2,55.5,240.6,55.5,241.1 M147.7,240.3c0,0.1,0,0.6,0,1.2
                l0.1,1.1v-1.2C147.9,240.3,147.8,239.9,147.7,240.3 M210.5,241.5c0,0.7,0,0.9,0.1,0.6c0-0.4,0-0.9,0-1.2
                C210.5,240.5,210.5,240.8,210.5,241.5"/>
            <path id="path4" fillRule="evenodd" clipRule="evenodd" fill="#C8D4E7" d="M178.5,1.5c0.5,0,0.9,0.1,1,0.2s0.2,0.2,0.3,0.2
                s0-0.1-0.1-0.3C179.6,1.5,179.2,1.4,178.5,1.5l-0.9-0.1L178.5,1.5 M174.7,1.7c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                S174.6,1.6,174.7,1.7 M152,3.5c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S151.8,3.5,152,3.5 M150,3.7c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                S149.8,3.7,150,3.7 M148.2,3.9c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C148.1,3.8,148,3.8,148.2,3.9 M176.6,4.1c0.3,0,0.7,0,0.9,0
                c0.3,0,0-0.1-0.5-0.1S176.4,4,176.6,4.1 M184.3,4.5c0.3,0.3,0.6,0.6,0.7,0.6s-0.2-0.3-0.5-0.6c-0.3-0.3-0.6-0.6-0.7-0.6
                C183.7,3.9,184,4.2,184.3,4.5 M164.3,5.1c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1S164.1,5,164.3,5.1 M162.3,5.3c0.3,0,0.6,0,0.9,0
                c0.2,0,0-0.1-0.5-0.1C162.2,5.2,162,5.2,162.3,5.3 M160.6,5.4c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                C160.5,5.3,160.4,5.4,160.6,5.4 M220,12.1c0.3,0,0.7,0,0.9,0c0.3,0,0-0.1-0.5-0.1S219.8,12,220,12.1 M236.9,16
                c0.2,0.1,0.5,0.2,0.6,0.2s0-0.1-0.3-0.2c-0.2-0.1-0.5-0.2-0.6-0.2C236.6,15.7,236.7,15.8,236.9,16 M189.9,17.6
                c0.4,0.4,0.8,0.8,0.8,0.8C190.8,18.5,190.7,18.3,189.9,17.6l-0.8-0.8L189.9,17.6 M179.6,22.2c0.5,0,1.3,0,1.8,0s0.1-0.1-0.9-0.1
                S179.1,22.1,179.6,22.2 M175.9,22.3c0.3,0,0.8,0,1,0c0.3,0,0-0.1-0.6-0.1C175.8,22.3,175.6,22.3,175.9,22.3 M183.7,22.3
                c0.3,0,0.8,0,1,0c0.3,0,0-0.1-0.6-0.1C183.6,22.3,183.4,22.3,183.7,22.3 M174,22.5c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                C173.9,22.4,173.8,22.5,174,22.5 M185.7,22.5c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1C185.6,22.4,185.4,22.5,185.7,22.5 M172.4,22.7
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C172.4,22.6,172.2,22.6,172.4,22.7 M188.4,22.8c1.9,0.3,2.7,0.3,1,0c-0.7-0.1-1.6-0.2-1.9-0.2
                C187.2,22.6,187.6,22.7,188.4,22.8 M206.8,22.7c0.3,0,0.8,0,1,0c0.3,0,0-0.1-0.6-0.1C206.7,22.6,206.5,22.6,206.8,22.7 M130.1,23
                c0.2,0.1,0.5,0.2,0.6,0.2s0-0.1-0.3-0.2c-0.2-0.1-0.5-0.2-0.6-0.2C129.7,22.7,129.9,22.9,130.1,23 M194.8,23.9
                c0.7,0.2,1.4,0.3,1.5,0.3c0.4,0-1.9-0.6-2.4-0.6C193.7,23.6,194.1,23.8,194.8,23.9 M197,24.4c0.2,0.1,0.5,0.1,0.7,0.1h0.3l-0.3-0.1
                c-0.2-0.1-0.5-0.1-0.7-0.1h-0.3L197,24.4 M179,24.6c0.4,0,1.2,0,1.6,0c0.4,0,0.1-0.1-0.8-0.1S178.6,24.5,179,24.6 M184.5,24.6
                c0.4,0,1.1,0,1.5,0s0-0.1-0.8-0.1S184,24.5,184.5,24.6 M176.7,24.7c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                C176.7,24.7,176.5,24.7,176.7,24.7 M187.7,24.7c0.3,0,0.7,0,0.9,0c0.3,0,0-0.1-0.5-0.1S187.5,24.7,187.7,24.7 M198.4,24.8
                c0.2,0.1,0.6,0.2,0.9,0.3c0.4,0.1,0.5,0.1,0.3,0s-0.6-0.2-0.9-0.3C198.2,24.6,198.1,24.7,198.4,24.8 M175.4,24.9c0.2,0,0.4,0,0.6,0
                c0.2,0,0-0.1-0.3-0.1S175.3,24.9,175.4,24.9 M190.9,25.1c1,0.1,1.9,0.2,2.1,0.2c0.3-0.1-2.7-0.5-3.3-0.4
                C189.4,24.8,190,24.9,190.9,25.1 M193.3,25.4c0,0,0.5,0.1,1.1,0.2c0.7,0.1,0.9,0,0.5,0C194.4,25.3,193.2,25.2,193.3,25.4
                 M200.7,25.4c0,0.1,4,1.3,4,1.3s-0.7-0.3-1.6-0.6C200.9,25.3,200.7,25.3,200.7,25.4 M137.9,25.9c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                S137.7,25.9,137.9,25.9 M159,26.4c-0.4,0.2-0.7,0.4-0.6,0.4c0.1,0,0.5-0.2,0.9-0.4c0.4-0.2,0.7-0.4,0.6-0.4S159.4,26.2,159,26.4
                 M201,26.9c3,0.8,3.2,0.8,2.9,0.6c-0.3-0.2-4.5-1.2-4.8-1.2C199,26.3,199.9,26.6,201,26.9 M215.9,26.6c0.3,0,0.8,0,1.2,0
                s0.1-0.1-0.6-0.1S215.5,26.6,215.9,26.6 M205.1,26.8c0.1,0.1,1,0.4,2,0.8c2,0.7,2,0.8,1.8,1.2c-0.2,0.2-0.4,0.2-2.3-0.4
                c-1.1-0.4-2.1-0.6-2.2-0.6c-0.1,0,0.9,0.4,2.2,0.8s2.3,0.7,2.4,0.7c0.6-0.6,0.3-1-1.5-1.7C205.6,26.8,204.7,26.5,205.1,26.8
                 M149.1,33.3l-1.4,1.5L149.1,33.3c0.9-0.8,1.5-1.4,1.5-1.4C150.6,31.7,150.4,31.9,149.1,33.3 M152.2,35.1l-1.2,1.2L152.2,35.1
                c0.7-0.6,1.3-1.2,1.3-1.2C153.5,33.8,153.3,33.9,152.2,35.1 M178.4,35.9c0.4,0.4,0.3,0.9-0.2,1.2c-0.3,0.1-0.3,0.2-0.2,0.2
                c0.8,0,1.1-1.3,0.4-1.6C178.1,35.6,178.1,35.6,178.4,35.9 M173.2,36.5c-0.3,0.1-0.5,0.2-0.3,0.2c0.1,0,0.5-0.1,0.9-0.2
                c0.3-0.1,0.5-0.2,0.3-0.2S173.5,36.4,173.2,36.5 M171.7,37c-0.4,0.2-0.4,0.2-0.1,0.1c0.5-0.1,1-0.4,0.8-0.4
                C172.3,36.8,172,36.9,171.7,37 M176.2,37.4c-0.3,0.1-0.1,0.1,0.4,0s0.9-0.1,0.9-0.2C177.7,37.2,176.7,37.3,176.2,37.4 M216.1,37.4
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C216.1,37.3,215.9,37.3,216.1,37.4 M168.7,38.3c-0.5,0.3-1,0.5-1,0.6s2-0.9,2.2-1.1
                C170.1,37.6,169.5,37.9,168.7,38.3 M171.4,38.9c-0.5,0.2-1,0.5-1.1,0.5c-0.1,0.1,0,0.1,0.2,0s0.7-0.3,1.2-0.5s0.9-0.4,0.9-0.4
                C172.8,38.4,172.3,38.5,171.4,38.9 M204.5,38.7C204.2,39,204.2,39,204.5,38.7c0.4-0.1,0.6-0.1,1,0.1c0.3,0.1,0.6,0.2,0.6,0.1
                c0.1-0.1-0.9-0.4-1.1-0.4C204.9,38.5,204.7,38.6,204.5,38.7 M250.1,39.4c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7
                C250.2,38.9,250.1,39,250.1,39.4 M204.1,39.4c0,0.5,1.5,1.5,1.8,1.2c0,0-0.2-0.2-0.6-0.3C204.5,40.1,204.3,39.9,204.1,39.4
                C204.2,39.1,204.1,39.1,204.1,39.4 M165.7,40.1c-0.5,0.3-0.9,0.6-0.9,0.7c0,0,0.4-0.2,0.9-0.5c0.8-0.5,1.1-0.7,0.9-0.7
                C166.6,39.5,166.2,39.8,165.7,40.1 M209,40.2c0.7,0.4,0.8,1.1,0.2,1.4c-0.2,0.1-0.3,0.2-0.1,0.2c0.4,0,0.7-0.3,0.7-0.8
                c0-0.4-0.7-1.1-1.1-1.1C208.5,39.8,208.7,40,209,40.2 M168.1,40.5c-0.5,0.3-0.9,0.6-0.9,0.6c0,0.1,1.5-0.7,1.8-1
                C169.3,39.9,169,40,168.1,40.5 M163.7,41.6l-0.9,0.8L163.7,41.6c0.8-0.6,1.1-0.8,0.9-0.8C164.5,40.9,164.1,41.2,163.7,41.6
                 M165.2,42.5l-0.7,0.6L165.2,42.5c0.7-0.4,0.9-0.6,0.7-0.6C165.9,41.9,165.6,42.2,165.2,42.5 M161.6,43.5c-0.6,0.6-1.2,1.3-1.4,1.6
                s0.2-0.2,1.1-1c0.8-0.8,1.5-1.6,1.4-1.6C162.6,42.4,162.2,42.9,161.6,43.5 M252,43.3c0.7,0.8,0.9,0.9,0.9,0.8
                C252.8,44,252.5,43.7,252,43.3l-0.8-0.8L252,43.3 M101.9,42.8c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6
                C102,42.8,101.9,42.7,101.9,42.8 M162.8,44.7c-1.5,1.5-2.1,2.2-2.5,2.9c-0.1,0.1,0.1,0,0.3-0.3c0.3-0.3,1.3-1.4,2.2-2.4
                s1.7-1.8,1.6-1.8C164.4,43.1,163.6,43.8,162.8,44.7 M159,46.5c-0.5,0.7-1.2,1.9-1,1.7s1.6-2.4,1.6-2.4
                C159.5,45.8,159.3,46.1,159,46.5 M193.8,47.3c-0.6,0.1,2.5,0.1,6.8,0.1s7,0,5.9-0.1C204,47.2,195.1,47.2,193.8,47.3 M191.8,47.6
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C191.7,47.6,191.5,47.6,191.8,47.6 M208.8,47.6c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                S208.6,47.6,208.8,47.6 M190,47.8c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C189.9,47.7,189.8,47.8,190,47.8 M210.5,47.8
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C210.4,47.7,210.3,47.8,210.5,47.8 M185.4,48.5c-2,0.4-4.5,1-6.8,1.6
                c-0.7,0.2-1.5,0.4-1.9,0.5s-0.6,0.2-0.6,0.3c0,0,1.3-0.3,2.9-0.7c2.4-0.7,5.3-1.3,8.8-1.9c0.7-0.1,0.8-0.2,0.3-0.2
                C187.7,48.1,186.5,48.3,185.4,48.5 M215.6,48.6c2.7,0.6,3.9,0.7,2.8,0.4c-0.8-0.2-5-1-5.3-1C212.9,48.1,214,48.3,215.6,48.6
                 M157.4,49.1c-0.2,0.4-0.3,0.8-0.3,0.8C157.1,50,157.3,49.7,157.4,49.1C158,48.2,157.9,48.1,157.4,49.1 M248.1,49.4l-0.7,0.7
                L248.1,49.4c0.4-0.3,0.8-0.6,0.8-0.7C248.9,48.6,248.7,48.8,248.1,49.4 M159,49.6c-0.2,0.4-0.3,0.7-0.3,0.8
                C158.7,50.4,158.9,50.1,159,49.6C159.6,48.7,159.5,48.6,159,49.6 M272.3,49.2c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                S272.1,49.1,272.3,49.2 M219.3,49.4c0.2,0.1,0.6,0.1,0.8,0.1c0.3,0,0.3,0-0.1-0.1c-0.2-0.1-0.6-0.1-0.8-0.1
                C219,49.3,219,49.3,219.3,49.4 M270.2,49.4c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1S270,49.3,270.2,49.4 M268.5,49.5
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C268.4,49.4,268.3,49.5,268.5,49.5 M220.9,49.8c0.2,0.1,1.3,0.4,2.3,0.7s2.1,0.6,2.4,0.7
                c0.6,0.2,1,0.2,0.4,0c-0.7-0.3-5.1-1.5-5.3-1.5C220.5,49.6,220.6,49.7,220.9,49.8 M266.3,49.7c0.2,0,0.6,0,0.8,0
                c0.2,0,0-0.1-0.4-0.1S266.1,49.7,266.3,49.7 M156.7,51c-0.2,0.4-0.2,0.8-0.2,0.9c0,0,0.2-0.3,0.3-0.7c0.2-0.4,0.2-0.8,0.2-0.9
                C157,50.3,156.8,50.6,156.7,51 M116.9,50.5c0.2,0.2,1.2,0.6,1.2,0.5S117.2,50.4,116.9,50.5C116.9,50.4,116.9,50.5,116.9,50.5
                 M173.6,51.7c-3.8,1.2-4.5,7.2-1,9.5c0.7,0.5,4.2,1.8,4.7,1.8c0.3,0-1.2-0.6-1.7-0.7c-3.2-0.6-4.9-2.6-4.9-5.6
                c0-1.8,0.9-3.9,1.5-3.5c0.1,0.1,0.1,0,0.1-0.1c-0.2-0.2,0.4-0.7,1.4-1.1C174.5,51.5,174.5,51.4,173.6,51.7 M119.1,51.7
                c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C119.3,51.7,119.1,51.5,119.1,51.7 M228.1,52c4.1,2,3.9,8.2-0.3,9.7
                c-0.5,0.2-1.5,0.5-2.1,0.7c-0.7,0.2-1.2,0.4-1.2,0.5c0.1,0.1,3.2-0.9,4-1.3c3.9-2,3.5-8.8-0.6-9.8C227.6,51.7,227.7,51.8,228.1,52
                 M266.4,52.3c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1S266.1,52.2,266.4,52.3 M144.3,53.2c0,0.4,0,0.6,0.1,0.4c0-0.2,0-0.6,0-0.8
                C144.4,52.6,144.3,52.7,144.3,53.2 M157.2,54.7c-0.1,0.7-0.3,1.3-0.3,1.5s0.2-0.3,0.3-1.1c0.2-0.8,0.3-1.5,0.3-1.5
                C157.5,53.5,157.3,54,157.2,54.7 M242.8,55.1l-0.7,0.7L242.8,55.1c0.5-0.3,0.8-0.6,0.8-0.7C243.6,54.3,243.4,54.4,242.8,55.1
                 M139.9,55.9c0,0.4,0,0.6,0.1,0.4c0-0.2,0-0.6,0-0.8C139.9,55.3,139.9,55.5,139.9,55.9 M155.2,56.9c-0.4,2.5-0.1,9.7,0.3,9.2
                c0,0,0-0.7-0.1-1.4c-0.3-1.7-0.3-6.1,0-7.8c0.1-0.7,0.2-1.4,0.1-1.4C155.5,55.4,155.3,56,155.2,56.9 M123.3,57.5c0,1.1,0,1.6,0.1,1
                c0-0.6,0-1.5,0-2.1C123.3,55.9,123.3,56.4,123.3,57.5 M240.2,56.4c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S240,56.3,240.2,56.4
                 M156.6,58.6c0,0.4,0,0.6,0.1,0.4c0-0.2,0-0.6,0-0.8C156.7,58,156.6,58.2,156.6,58.6 M156.6,62.8c0,0.5,0,0.6,0.1,0.4
                c0-0.3,0-0.6,0-0.9C156.7,62.1,156.6,62.3,156.6,62.8 M177.4,63c0,0.1,3.5,1,4.4,1.2c1.5,0.3,0.2-0.2-2-0.7
                C177.2,62.8,177.4,62.9,177.4,63 M223.1,63.2c-0.4,0.1-1.5,0.4-2.3,0.6c-0.8,0.2-1.4,0.4-1.3,0.4c0.2,0.1,4.7-1.1,4.8-1.2
                C224.4,62.8,223.9,62.9,223.1,63.2 M112,63.2c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1S111.8,63.2,112,63.2 M110,63.4c0.2,0,0.6,0,0.8,0
                c0.2,0,0-0.1-0.4-0.1C110,63.3,109.8,63.3,110,63.4 M108.2,63.5c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1
                C108.1,63.5,108,63.5,108.2,63.5 M217.8,64.5c-0.1,0.1-5.5,1-8.3,1.3c-1.5,0.2-2.5,0.3-2.2,0.3c0.8,0,5-0.5,7.7-0.9
                s3.9-0.7,3.2-0.8C218,64.5,217.8,64.5,217.8,64.5 M185.8,65c1.2,0.3,5.6,0.9,6.3,0.9c0.4,0-3.7-0.7-6.4-1.1
                C184.9,64.8,184.9,64.8,185.8,65 M157.2,66.5c0.1,0.8,0.4,1.9,0.5,1.9c0,0-0.1-0.6-0.3-1.3C157.2,66.4,157.1,66.2,157.2,66.5
                 M193.1,66.1c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1S192.9,66.1,193.1,66.1 M105.8,66.3c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1
                C105.7,66.2,105.6,66.2,105.8,66.3 M198.7,66.3c1.4,0,3.6,0,5,0c1.3,0,0.2-0.1-2.5-0.1C198.4,66.2,197.3,66.3,198.7,66.3 M100.7,68
                c0,0.3,0,0.5,0.1,0.3c0-0.2,0-0.4,0-0.6C100.8,67.6,100.7,67.7,100.7,68 M253.5,69c0,0.1,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.2,0.3
                c0-0.1-0.1-0.4-0.2-0.6C253.6,69,253.5,68.9,253.5,69 M156.3,69.4c0.1,0.2,0.2,0.7,0.3,0.9c0.2,0.4,0.2,0.4,0.1,0.1
                c-0.1-0.2-0.2-0.7-0.3-0.9C156.3,69.1,156.3,69.1,156.3,69.4 M211.8,70.4c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                S211.7,70.3,211.8,70.4 M195.4,70.9c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S195.3,70.8,195.4,70.9 M156.9,71.1
                c0,0.1,0.2,0.4,0.4,0.6l0.4,0.4l-0.3-0.5C156.9,70.9,156.9,70.9,156.9,71.1 M200,71.1c0.8,0,2,0,2.8,0s0.1-0.1-1.4-0.1
                C199.8,71,199.2,71,200,71.1 M231.5,72.2c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7C231.5,71.7,231.5,71.8,231.5,72.2 M242.8,73.5
                c-0.1,0.1,0,0.2,0.2,0.1c0.5-0.2,1,0,1.1,0.4c0.1,0.2,0.2,0.3,0.2,0.3c0.1-0.1,0-0.3-0.2-0.5C243.9,73.3,243,73.2,242.8,73.5
                 M242.6,74.2c0,0.2,0.2,0.8,0.3,1.2c0.3,0.8,0.3,0.6-0.1-0.9C242.7,74,242.6,73.9,242.6,74.2 M244.4,74.6c0.1,0.4,1.2,3.7,1.2,3.5
                C245.6,77.8,244.6,74.5,244.4,74.6C244.5,74.5,244.4,74.5,244.4,74.6 M300.2,79l-0.5,0.6L300.2,79c0.6-0.4,0.7-0.6,0.6-0.6
                C300.8,78.5,300.5,78.7,300.2,79 M288.2,78.9c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1S288.1,78.9,288.2,78.9 M286.5,79.1
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C286.4,79,286.2,79.1,286.5,79.1 M244.1,79.3c0,0.1,0.1,0.7,0.2,1.3c0.1,0.6,0.3,2.2,0.3,3.6
                l0.1,2.6l-0.1-2.7C244.6,81.8,244.2,78.3,244.1,79.3 M284.4,79.3c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1S284.2,79.2,284.4,79.3
                 M295.2,80.6c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1C295.1,80.6,295,80.6,295.2,80.6 M293.5,80.8c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C293.4,80.7,293.3,80.8,293.5,80.8 M199.2,81.7c1.1,0,2.9,0,4,0s0.2-0.1-2-0.1S198.1,81.6,199.2,81.7 M246.2,82.3
                c0,0.5,0,0.6,0.1,0.4c0-0.3,0-0.6,0-0.9C246.2,81.6,246.2,81.8,246.2,82.3 M281.6,83.5c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7
                C281.6,83,281.6,83.1,281.6,83.5 M246.4,84.7c0,0.5,0,0.6,0.1,0.4c0-0.3,0-0.6,0-0.9C246.4,84,246.4,84.2,246.4,84.7 M279.4,86.8
                c0,0.9,0,1.3,0.1,0.8c0-0.4,0-1.2,0-1.6C279.4,85.6,279.4,85.9,279.4,86.8 M262.8,86.8c0,0.8,0,1,0.1,0.6c0-0.4,0-1,0-1.4
                C262.8,85.7,262.8,86,262.8,86.8 M193.1,86.1c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C193,86,192.9,86.1,193.1,86.1 M209.2,86.1
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1S209,86.1,209.2,86.1 M195.2,86.3c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                C195.1,86.2,195,86.2,195.2,86.3 M206.9,86.3c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1C206.8,86.2,206.6,86.2,206.9,86.3 M198.2,86.4
                c0.4,0,1.1,0,1.5,0s0.1-0.1-0.7-0.1C198.2,86.4,197.8,86.4,198.2,86.4 M202.9,86.5c0.5,0,1.3,0,1.7,0s0-0.1-0.9-0.1
                C202.7,86.4,202.4,86.4,202.9,86.5 M246.2,87c0,0.4,0,0.6,0.1,0.4c0-0.2,0-0.6,0-0.8C246.2,86.4,246.2,86.6,246.2,87 M244.5,87.4
                c0,0.3-0.1,1.1-0.2,1.6c-0.1,0.5-0.1,1-0.1,1.1c0.1,0.1,0.5-2.3,0.4-2.8C244.5,86.8,244.5,86.8,244.5,87.4 M157.9,87.8
                c-0.4,0.4-0.7,0.8-0.7,0.8s0.5-0.3,0.9-0.8C159.1,86.8,159,86.8,157.9,87.8 M281.6,89.8c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7
                C281.6,89.3,281.6,89.5,281.6,89.8 M116.5,90.9c0.4,0.4,0.7,0.7,0.8,0.7s-0.2-0.3-0.6-0.7c-0.4-0.4-0.7-0.7-0.8-0.7
                C115.9,90.3,116.1,90.6,116.5,90.9 M171.8,91.4c0.3,0.3,0.6,0.6,0.7,0.6c0,0-0.2-0.3-0.5-0.6c-0.3-0.3-0.6-0.6-0.7-0.6
                S171.5,91,171.8,91.4 M258.3,91.6c0,0.3,0,0.5,0.1,0.3c0-0.2,0-0.4,0-0.6C258.4,91.2,258.3,91.3,258.3,91.6 M245,92.7
                c-0.2,0.5-0.2,1-0.2,1s0.2-0.4,0.3-0.9c0.2-0.5,0.2-1,0.2-1C245.4,91.8,245.2,92.2,245,92.7 M135.6,92.3c0.3,0,0.7,0,0.9,0
                c0.3,0,0-0.1-0.5-0.1C135.6,92.2,135.3,92.2,135.6,92.3 M127.2,92.9c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1
                C127.2,92.9,127.1,92.9,127.2,92.9 M125.3,93.1c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1C125.2,93,125,93.1,125.3,93.1 M120.9,93.3
                c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1S120.7,93.2,120.9,93.3 M123.4,93.3c0.2,0,0.6,0,0.8,0c0.2,0,0-0.1-0.4-0.1
                C123.3,93.2,123.2,93.2,123.4,93.3 M243.1,93.4c0,0-0.2,0.6-0.4,1.3c-0.2,0.6-0.4,1.1-0.3,1C242.6,95.4,243.3,93.5,243.1,93.4
                C243.1,93.3,243.1,93.3,243.1,93.4 M135.6,94.5c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1S135.3,94.4,135.6,94.5 M133.6,94.7
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C133.6,94.6,133.4,94.6,133.6,94.7 M131.8,94.8c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                C131.8,94.7,131.7,94.8,131.8,94.8 M243.2,97.1c-0.1,0.2-0.3,0.6-0.5,0.8c-0.2,0.4-0.2,0.4,0,0.1c0.3-0.4,0.8-1.3,0.7-1.3
                C243.5,96.8,243.3,96.9,243.2,97.1 M198.9,97.2c1.7,0,4.5,0,6.2,0s0.3-0.1-3.1-0.1C198.6,97.2,197.2,97.2,198.9,97.2 M241.6,99.6
                l-0.9,1.2l0.8-0.9c0.7-0.8,1.2-1.5,1.1-1.5C242.5,98.5,242.1,99,241.6,99.6 M238.5,100.6l-1.4,1.5L238.5,100.6
                c0.9-0.8,1.5-1.4,1.5-1.4C240,99.1,239.8,99.3,238.5,100.6 M239.4,102l-1,1.1l1.1-1.1c0.6-0.6,1.1-1.1,1.1-1.2
                C240.7,100.7,240.3,101.1,239.4,102 M235.6,103.1c-1.2,0.9-1.4,1.1-1,0.9s2.2-1.6,2.2-1.7C236.8,102.2,236.7,102.2,235.6,103.1
                 M236.6,104.5c-0.6,0.5-0.6,0.5,0,0.2c0.3-0.2,0.6-0.4,0.7-0.5C237.3,103.8,237.2,103.9,236.6,104.5 M233.5,104.5
                c-0.8,0.5-1.1,0.7-0.4,0.4c0.5-0.3,1.3-0.8,1.1-0.8C234.1,104.1,233.8,104.3,233.5,104.5 M235.3,105.3c-0.8,0.6-0.9,0.7-0.3,0.4
                c0.5-0.3,1-0.8,0.8-0.8C235.8,105,235.6,105.1,235.3,105.3 M233.8,106.3c-0.6,0.3-0.6,0.5,0,0.2c0.3-0.1,0.5-0.3,0.5-0.3
                C234.4,106,234.3,106,233.8,106.3 M228.2,106.9c-0.6,0.2-1,0.4-0.9,0.4c0.2,0,2.3-0.7,2.3-0.8C229.6,106.4,229.4,106.5,228.2,106.9
                 M185.9,107c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1C185.8,106.9,185.6,106.9,185.9,107 M230.9,107.7c-0.3,0.2-0.5,0.3-0.4,0.3
                s0.4-0.1,0.8-0.3s0.5-0.3,0.4-0.3S231.3,107.5,230.9,107.7 M222.5,108.4c-0.6,0.5-0.4,1.5,0.3,1.5c0.1,0,0-0.1-0.2-0.3
                c-0.7-0.5-0.4-1.2,0.8-1.5c0.6-0.1,0.6-0.1,0.1-0.1C223.2,108,222.8,108.2,222.5,108.4 M226.5,109.2c-0.8,0.2-1.1,0.4-0.8,0.3
                c0.7-0.1,2.9-0.7,2.5-0.7C228.1,108.8,227.3,108.9,226.5,109.2 M253.6,110.8l-1.2,1.2L253.6,110.8c1.2-1.1,1.4-1.2,1.2-1.2
                C254.8,109.6,254.2,110.1,253.6,110.8 M193.6,115.5c-0.1,0.2-0.1,0.5,0,0.6c0.2,0.2,1.6,0.7,1.7,0.6c0,0-0.3-0.2-0.7-0.4
                c-0.9-0.3-1-0.4-0.8-0.8c0.1-0.2,0.3-0.2,1.4,0.1c1.7,0.5,1.8,0.4,0.1-0.2C193.6,114.9,193.7,114.9,193.6,115.5 M190,116.1
                l-0.5,0.6L190,116.1c0.6-0.4,0.7-0.6,0.6-0.6C190.5,115.6,190.3,115.8,190,116.1 M263,116c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C263,115.9,262.8,116,263,116 M265.2,116c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1S265,116,265.2,116 M198.5,116.7c0.1,0.1,3.3,0.9,3.4,0.9
                c0.1,0-0.6-0.3-1.6-0.5C198.6,116.6,198.3,116.5,198.5,116.7 M198.2,117.8c0.7,0.3,1.4,0.5,1.5,0.4c0.2-0.1-2.4-1-2.7-1
                C196.9,117.3,197.4,117.5,198.2,117.8 M185.8,117.7c0.3,0,0.8,0,1,0c0.3,0,0-0.1-0.6-0.1C185.7,117.7,185.5,117.7,185.8,117.7
                 M206.3,118.5c0.1,0.1,3.1,0.6,3.6,0.6c0.2,0-0.4-0.2-1.4-0.3C206.6,118.5,206.2,118.4,206.3,118.5 M203.1,119.3
                c0.3,0.2,3.4,0.9,3.5,0.8c0,0-0.7-0.2-1.6-0.5C204.1,119.4,203.2,119.2,203.1,119.3C202.8,119.1,202.8,119.1,203.1,119.3
                 M210.6,119.3c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C210.6,119.2,210.5,119.2,210.6,119.3 M212.1,119.4c0.2,0,0.5,0,0.7,0
                s0-0.1-0.4-0.1C212,119.4,211.9,119.4,212.1,119.4 M227.4,119.4c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S227.2,119.4,227.4,119.4
                 M213.6,119.6c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1S213.4,119.6,213.6,119.6 M215.9,119.8c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1
                S215.7,119.7,215.9,119.8 M223.9,119.8c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1C223.9,119.7,223.7,119.7,223.9,119.8 M219.5,120
                c0.4,0,1.1,0,1.5,0s0-0.1-0.8-0.1S219.1,119.9,219.5,120 M212.5,121.3c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1
                C212.5,121.2,212.4,121.3,212.5,121.3 M213.8,121.5c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C213.7,121.4,213.6,121.4,213.8,121.5
                 M215.1,121.7c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S214.9,121.6,215.1,121.7 M216.9,121.8c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                S216.7,121.8,216.9,121.8 M227.3,121.8c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C227.2,121.8,227.1,121.8,227.3,121.8 M219.1,122
                c0.3,0,0.8,0,1.1,0s0.1-0.1-0.6-0.1C219,121.9,218.8,122,219.1,122 M224.7,122c0.3,0,0.7,0,0.9,0c0.3,0,0-0.1-0.5-0.1
                C224.6,121.9,224.4,122,224.7,122 M195.1,124.2c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1C195,124.2,194.8,124.2,195.1,124.2
                 M212.5,126.6c0.3,0.3,0.6,0.6,0.7,0.6s-0.2-0.3-0.5-0.6S212,126,212,126C211.9,126,212.2,126.3,212.5,126.6 M246.4,138.6
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C246.3,138.5,246.2,138.5,246.4,138.6 M244.6,138.8c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                S244.4,138.7,244.6,138.8 M242.5,138.9c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1C242.5,138.9,242.3,138.9,242.5,138.9 M226.9,140.3
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1S226.7,140.2,226.9,140.3 M225.1,140.5c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                S224.9,140.4,225.1,140.5 M239.6,141.7c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C239.5,141.6,239.4,141.6,239.6,141.7 M237.6,141.8
                c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C237.5,141.8,237.4,141.8,237.6,141.8 M235.7,142c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                C235.6,141.9,235.5,142,235.7,142 M233.9,142.2c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C233.9,142.1,233.7,142.1,233.9,142.2
                 M225.5,142.9c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C225.5,142.8,225.3,142.8,225.5,142.9 M298.8,171.9c0.3,0,0.7,0,0.9,0
                c0.3,0,0-0.1-0.5-0.1S298.6,171.9,298.8,171.9 M110.8,174.3l-0.6,0.6L110.8,174.3c0.6-0.5,0.8-0.6,0.6-0.6
                C111.4,173.7,111.1,174,110.8,174.3 M186.9,174.7c0,0.5,0,0.7,0.1,0.5c0-0.3,0-0.7,0-0.9C186.9,174,186.9,174.2,186.9,174.7
                 M298.6,174.1c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1S298.4,174.1,298.6,174.1 M293.9,176.3c0,0.7,0,0.9,0.1,0.6c0-0.4,0-0.9,0-1.2
                C293.9,175.4,293.9,175.7,293.9,176.3 M187.5,176.8c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C187.7,176.8,187.5,176.7,187.5,176.8
                 M123.2,178.7c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C123.4,178.7,123.2,178.6,123.2,178.7 M258.9,179.4c0.4,0,1.1,0,1.5,0
                s0-0.1-0.8-0.1C258.7,179.4,258.4,179.4,258.9,179.4 M166.8,181.3l0.1,1.3l0.1-1.2c0-0.6,0-1.2-0.1-1.3
                C166.8,180.1,166.8,180.6,166.8,181.3 M193.8,191l0.1,11v-10.8c0-6,0-10.9-0.1-11C193.8,180.1,193.8,185,193.8,191 M250.6,183
                c-0.4,0.4-0.7,0.8-0.7,0.8s0.4-0.3,0.8-0.8c0.4-0.4,0.7-0.8,0.7-0.8S251,182.6,250.6,183 M267.9,182.8c0.3,0.3,0.6,0.6,0.7,0.6
                s-0.2-0.3-0.5-0.6s-0.6-0.6-0.7-0.6S267.5,182.5,267.9,182.8 M267.5,184.6l-0.9,1L267.5,184.6c1-0.8,1.1-1,1-1
                C268.5,183.6,268,184,267.5,184.6 M110.8,185.6c0.3,0.3,0.6,0.6,0.7,0.6c0,0-0.2-0.3-0.5-0.6s-0.6-0.6-0.7-0.6
                C110.2,185,110.4,185.2,110.8,185.6 M204.7,186.5l-0.5,0.6L204.7,186.5c0.6-0.4,0.7-0.6,0.6-0.6C205.2,186,205,186.2,204.7,186.5
                 M244.3,187.1c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7C244.3,186.5,244.3,186.7,244.3,187.1 M148.4,187.3c0.3,0.3,0.6,0.6,0.7,0.6
                s-0.2-0.3-0.5-0.6s-0.6-0.6-0.7-0.6S148.1,186.9,148.4,187.3 M237.9,195c0,3.7,0,5.2,0.1,3.4c0-1.9,0-4.9,0-6.8
                C237.9,189.8,237.9,191.3,237.9,195 M220,189.1c0,0.3,0,0.5,0.1,0.3c0-0.2,0-0.4,0-0.6C220.1,188.6,220,188.7,220,189.1 M269.8,191
                c0,0.8,0,1.2,0.1,0.8c0-0.4,0-1.1,0-1.5C269.8,189.9,269.8,190.2,269.8,191 M220,192.3v0.4h-8.1c-4.5,0-8.1,0.1-8.2,0.1
                c0,0.1,3.6,0.1,8.1,0.1c8.6,0,8.6,0,8.3-0.8C220.1,192,220,192.1,220,192.3 M276.4,192.8c-0.1,0.1,6,0.1,13.5,0
                c1.6,0-0.8-0.1-5.2-0.1C280.1,192.7,276.4,192.7,276.4,192.8 M140,194.2c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6
                C140.1,194.2,140,194.1,140,194.2 M266.5,196.3c1.1,1.1,2,2,2,2s-0.8-0.9-1.9-2s-2-2-2-2S265.4,195.2,266.5,196.3 M214.3,195
                C214.1,195.3,214.1,195.3,214.3,195c0.3-0.2,0.5-0.1,1.1,0.5l0.7,0.7l-0.7-0.7C214.7,194.6,214.7,194.6,214.3,195 M287,195
                C286.8,195.3,286.8,195.3,287,195c0.3-0.2,0.5-0.1,1.1,0.5l0.7,0.7l-0.7-0.7C287.4,194.6,287.3,194.6,287,195 M144.1,196.4
                c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1C144.1,196.3,143.9,196.3,144.1,196.4 M171.8,196.4c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1
                C171.7,196.3,171.5,196.3,171.8,196.4 M259.1,196.5c0.3,0,0.8,0,1,0c0.3,0,0-0.1-0.6-0.1C259,196.5,258.8,196.5,259.1,196.5
                 M208.7,196.9c0.4,0,1,0,1.4,0s0-0.1-0.7-0.1C208.6,196.8,208.3,196.8,208.7,196.9 M281.3,196.9c0.4,0,1,0,1.4,0s0-0.1-0.7-0.1
                C281.2,196.8,280.9,196.8,281.3,196.9 M250.6,198.8c0.7,0.8,0.9,0.9,0.9,0.8C251.5,199.6,251.1,199.2,250.6,198.8l-0.8-0.8
                L250.6,198.8 M267.9,199l-0.5,0.6L267.9,199c0.6-0.4,0.7-0.6,0.6-0.6C268.5,198.5,268.2,198.7,267.9,199 M166.9,204.7v5.3l-3.3,0.1
                l-3.3,0.1h3.3c2.5,0,3.3,0,3.4-0.2C167,209.9,167.1,207.4,166.9,204.7l0.1-5.3L166.9,204.7 M167.2,199.9c0,0,0.2,0.3,0.6,0.6
                l0.6,0.5l-0.5-0.6C167.3,199.9,167.2,199.8,167.2,199.9 M177,201.6c-0.4,0.2-0.4,0.2-0.1,0.1c0.5-0.1,1-0.4,0.8-0.4
                C177.6,201.4,177.3,201.5,177,201.6 M126.3,212.1c0.4,0,1,0,1.4,0s0-0.1-0.7-0.1C126.2,212,125.9,212.1,126.3,212.1 M239.2,213
                l-0.5,0.6L239.2,213c0.6-0.4,0.7-0.6,0.6-0.6C239.7,212.5,239.5,212.7,239.2,213 M244.1,212.5c0,0,0.2,0.3,0.6,0.6l0.6,0.5
                l-0.5-0.6C244.2,212.5,244.1,212.4,244.1,212.5 M34.2,227.6v15.1l-3.1,0.1l-3.1,0.1h3.1c2.4,0,3.1,0,3.2-0.2
                C34.3,242.6,34.4,235.8,34.2,227.6l0.1-15.1L34.2,227.6 M2.5,227.8v15h3.2h3.2l0.1-9c0-5,0-9,0-9c-0.1,0-0.1,4-0.1,9v9H5.6h-3
                L2.5,227.8v-15V227.8 M182.6,216.8c0,2.2,0,3.1,0.1,2c0-1.1,0-2.9,0-4C182.7,213.6,182.6,214.5,182.6,216.8 M346,227.7v15l-3.3,0.1
                l-3.3,0.1h3.3c2.5,0,3.3,0,3.4-0.2c0-0.1,0.1-6.9,0-15.1v-14.9L346,227.7 M375.6,227.8v15h3.2h3.2l-3.2-0.1l-3.2-0.1V227.8v-15
                V227.8 M127.4,217.6c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1S127.1,217.5,127.4,217.6 M238.8,217.7c0,0,0.2,0.3,0.6,0.6l0.6,0.5
                l-0.5-0.6C238.9,217.6,238.8,217.5,238.8,217.7 M118.5,218.6c0,0.3,0,0.5,0.1,0.3c0-0.2,0-0.4,0-0.6
                C118.5,218.2,118.5,218.3,118.5,218.6 M241.6,219.3c0.3,0,0.6,0,0.9,0c0.2,0,0-0.1-0.5-0.1S241.3,219.2,241.6,219.3 M48.1,220.6
                c0.2,0,0.4,0,0.6,0c0.2,0,0-0.1-0.3-0.1C48,220.6,47.9,220.6,48.1,220.6 M55.7,221c0,0.1,1.4,0.1,3.2,0.1l3.2-0.1v10.9v10.9h-3.2
                c-1.8,0-3.2,0-3.1,0.1c0.1,0.1,1.6,0.1,3.3,0.1h3.2v-10.9v-10.9h-3.2C57.2,220.9,55.7,220.9,55.7,221 M66.2,221.1
                c0,0.1-0.1,5.1,0,11v10.8h3.2c1.7,0,3.2,0,3.3-0.1c0.1-0.1-1.3-0.1-3.1-0.1h-3.2v-10.9L66.2,221.1h3.3c1.8,0,3.2,0,3.2-0.1
                C72.6,220.7,66.3,220.8,66.2,221.1 M91.2,221.1c0,0.1-0.1,3.2,0,6.9v6.7v-6.8V221.1h3.3c1.8,0,3.2,0,3.2-0.1
                C97.5,220.7,91.3,220.8,91.2,221.1 M105.4,221.1c0,0.1-0.1,3,0,6.4v6.2v-6.3V221.1h3.3c1.8,0,3.2,0,3.2-0.1
                C111.7,220.7,105.5,220.8,105.4,221.1 M115.9,221c0.5,0,1.3,0,1.8,0s0.1-0.1-0.9-0.1S115.4,220.9,115.9,221 M126.9,221l2.1,0.1
                l0.1,2.9l0.1,2.9V224v-2.9L126.9,221l-2.1-0.1L126.9,221 M147.8,221c0,0.1,1.4,0.1,3.2,0.1s3.3-0.1,3.2-0.1c0-0.1-1.5-0.1-3.2-0.1
                S147.8,220.9,147.8,221 M180.6,221c0.4,0,1,0,1.4,0s0-0.1-0.7-0.1C180.5,220.9,180.2,220.9,180.6,221 M190.1,221c0.6,0,1.7,0,2.4,0
                s0.2-0.1-1.1-0.1C190.1,220.9,189.5,220.9,190.1,221 M196.3,221.1c0,0.1-0.1,3.3,0,7.1v6.9V228V221.1h3.3c1.8,0,3.2,0,3.2-0.1
                C202.6,220.7,196.4,220.8,196.3,221.1 M210.5,221c0,0.1,1.4,0.1,3.2,0.1s3.3-0.1,3.2-0.1c0-0.1-1.5-0.1-3.2-0.1
                S210.6,220.9,210.5,221 M221.1,221c0,0.1,1.4,0.1,3.2,0.1s3.3-0.1,3.2-0.1c0-0.1-1.5-0.1-3.2-0.1S221.2,220.9,221.1,221
                 M235.6,223.2c0,1.4,0,1.9,0.1,1.2c0-0.7,0-1.8,0-2.5C235.6,221.3,235.6,221.9,235.6,223.2 M238.7,221.1c0,0.1-0.1,5.1,0,11v10.8
                h3.2h3.2l-3.2-0.1l-3.2-0.1v-10.8V221.1l3.3-0.1l3.2-0.1H242C239.6,220.9,238.8,220.9,238.7,221.1 M252.8,221l3.1,0.1l0.1,1.2
                l0.1,1.2v-1.3v-1.3L252.8,221l-3.1-0.1L252.8,221 M292.5,221c0.9,0,2.4,0,3.2,0c0.9,0,0.1-0.1-1.7-0.1
                C292.3,220.9,291.6,221,292.5,221 M352.5,221c0.9,0,2.4,0,3.2,0c0.9,0,0.1-0.1-1.7-0.1C352.3,220.9,351.6,221,352.5,221 M366.7,221
                c0.9,0,2.4,0,3.2,0c0.9,0,0.1-0.1-1.7-0.1C366.5,220.9,365.8,221,366.7,221 M365,227.5c0,3.5,0,5,0,3.2s0-4.6,0-6.4
                C365.1,222.6,365,224,365,227.5 M217,232v10.7l-3.1,0.1l-3.1,0.1h3.1c2.4,0,3.1,0,3.2-0.2C217.1,242.6,217.2,237.7,217,232
                l0.1-10.7L217,232 M202.8,227.3c0,3.1,0,4.4,0.1,2.9c0-1.5,0-4.1,0-5.6S202.8,224.2,202.8,227.3 M290.8,222.4c0,0.3,0,0.5,0.1,0.3
                c0-0.2,0-0.4,0-0.6C290.8,221.9,290.8,222.1,290.8,222.4 M41.6,223.6l-0.7,0.7L41.6,223.6c0.7-0.6,0.9-0.7,0.7-0.7
                C42.3,222.9,42,223.2,41.6,223.6 M160.7,223.9l-0.9,1L160.7,223.9c1-0.8,1.1-1,1-1C161.6,222.9,161.2,223.3,160.7,223.9
                 M177.6,225.4l-0.9,1L177.6,225.4c1-0.8,1.1-1,1-1C178.6,224.4,178.1,224.9,177.6,225.4 M169.3,226.3c0.3,0,0.6,0,0.9,0
                c0.2,0,0-0.1-0.5-0.1S169,226.2,169.3,226.3 M233.1,226.6c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C233,226.5,232.9,226.6,233.1,226.6
                 M115.9,227c0.5,0,1.3,0,1.8,0s0.1-0.1-0.9-0.1S115.4,226.9,115.9,227 M126,227c0.5,0,1.4,0,2,0c0.5,0,0.1-0.1-1-0.1
                C125.9,226.9,125.5,226.9,126,227 M181.2,227l1.3,0.1v4.3v4.3v-4.4v-4.4L181.2,227l-1.3-0.1L181.2,227 M190.1,227
                c0.6,0,1.7,0,2.4,0s0.2-0.1-1.1-0.1C190.1,226.9,189.5,226.9,190.1,227 M53.8,227.7c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6
                C54,227.7,53.8,227.6,53.8,227.7 M131,228.2c-0.1,0.3-0.2,0.6-0.2,0.7c0,0.1,0.1-0.1,0.3-0.5c0.1-0.4,0.2-0.7,0.2-0.7
                C131.2,227.7,131.1,227.9,131,228.2 M87.4,235c0,3.7,0,5.1,0,3.3c0-1.9,0-4.9,0-6.7C87.5,229.8,87.4,231.3,87.4,235 M270.7,235.8
                c0,3.6,0,5.1,0.1,3.3c0-1.8,0-4.7,0-6.5C270.7,230.8,270.7,232.2,270.7,235.8 M329.7,230c1.8,0,4.7,0,6.5,0s0.3-0.1-3.3-0.1
                S327.9,230,329.7,230 M157.5,231.9c0,0.9,0,1.3,0.1,0.8c0-0.5,0-1.3,0-1.7C157.5,230.5,157.5,230.9,157.5,231.9 M38.3,231
                c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7C38.4,230.5,38.3,230.6,38.3,231 M256,236.4c0,3.3,0,4.7,0.1,3c0-1.7,0-4.4,0-6.1
                C256,231.7,256,233.1,256,236.4 M130.5,232c0,0.7,0,1,0.1,0.6c0-0.4,0-0.9,0-1.3C130.5,231,130.5,231.3,130.5,232 M44.8,231.9
                c0,0.5,0,0.6,0.1,0.4c0-0.3,0-0.6,0-0.9C44.9,231.2,44.8,231.4,44.8,231.9 M164,231.9c0,0.5,0,0.6,0.1,0.4c0-0.3,0-0.6,0-0.9
                C164,231.2,164,231.4,164,231.9 M148.1,231.9c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7C148.1,231.3,148.1,231.5,148.1,231.9
                 M392.9,231.9c0,0.4,0,0.5,0.1,0.3c0-0.2,0-0.5,0-0.7C392.9,231.3,392.9,231.5,392.9,231.9 M38.3,232.6c0,0.3,0,0.5,0.1,0.3
                c0-0.2,0-0.4,0-0.6C38.4,232.2,38.3,232.3,38.3,232.6 M46.3,235.6c0.3,0.3,0.6,0.6,0.7,0.6c0,0-0.2-0.3-0.5-0.6s-0.6-0.6-0.7-0.6
                C45.8,235,46,235.3,46.3,235.6 M281.2,235.1c0,0,0.2,0.3,0.6,0.6l0.6,0.5l-0.5-0.6C281.3,235.1,281.2,235,281.2,235.1 M104,236.5
                l-0.5,0.6L104,236.5c0.4-0.2,0.6-0.5,0.6-0.5C104.6,235.8,104.5,235.9,104,236.5 M285.3,237.2c0.2,0,0.6,0,0.8,0
                c0.2,0,0-0.1-0.4-0.1S285,237.2,285.3,237.2 M386.4,237.2c0.2,0,0.4,0,0.6,0s0-0.1-0.3-0.1C386.3,237.1,386.2,237.2,386.4,237.2
                 M193.4,240.2c0,1.6,0,2.2,0.1,1.4c0-0.8,0-2,0-2.8C193.4,238,193.4,238.6,193.4,240.2 M178,239.9l-0.5,0.6L178,239.9
                c0.6-0.4,0.7-0.6,0.6-0.6C178.6,239.3,178.3,239.6,178,239.9 M396.1,240.1c-0.4,0.4-0.7,0.8-0.7,0.8S395.8,240.6,396.1,240.1
                c0.5-0.4,0.8-0.8,0.8-0.8S396.5,239.7,396.1,240.1 M133.8,240.3c0.3,0.3,0.6,0.6,0.7,0.6c0,0-0.2-0.3-0.5-0.6s-0.6-0.6-0.7-0.6
                C133.3,239.7,133.5,239.9,133.8,240.3 M105.4,241.7v1.1h3.2h3.2l-3.1-0.1l-3.1-0.1L105.4,241.7v-1.1V241.7 M353.2,241.1
                c0.3,0.3,0.6,0.6,0.7,0.6s-0.2-0.3-0.5-0.6s-0.6-0.6-0.7-0.6S352.8,240.8,353.2,241.1 M365,241.7v1.1h3.2h3.2l-3.1-0.1l-3.1-0.1
                L365,241.7v-1.1V241.7 M297.3,241.5c0,0.5,0,0.6,0.1,0.4c0-0.3,0-0.6,0-0.9C297.3,240.8,297.3,241,297.3,241.5 M54.6,241.4
                l-0.5,0.6L54.6,241.4c0.4-0.2,0.6-0.5,0.6-0.5C55.2,240.8,55.1,240.9,54.6,241.4 M81,242.8c0,0.1,1.5,0.1,3.2,0.1s3.2-0.1,3.2-0.1
                c0-0.1-1.4-0.1-3.2-0.1C82.4,242.6,80.9,242.7,81,242.8 M147.8,242.8c0,0.1,1.5,0.1,3.2,0.1s3.2-0.1,3.2-0.1c0-0.1-1.4-0.1-3.2-0.1
                C149.2,242.6,147.8,242.7,147.8,242.8 M224.4,242.7l-3.2,0.1h3.2c2,0,3.2,0,3.3-0.1s0.1-0.2,0.1-0.1
                C227.6,242.6,226.1,242.7,224.4,242.7 M253.1,242.7l-2.9,0.1h2.9c1.8,0,2.9,0,3-0.1s0.1-0.2,0.1-0.1
                C256,242.6,254.7,242.7,253.1,242.7 M297.1,243.1c0,0.3,0,0.5,0.1,0.3c0-0.2,0-0.4,0-0.6C297.2,242.6,297.1,242.7,297.1,243.1
                 M323.1,242.7l-3.3,0.1h3.3c2,0,3.3,0,3.4-0.1s0.1-0.2,0.1-0.1C326.4,242.6,324.9,242.7,323.1,242.7 M120,242.9c0.8,0,2.2,0,3.1,0
                s0.2-0.1-1.5-0.1C119.9,242.8,119.2,242.8,120,242.9 M265.8,242.9c0.9,0,2.3,0,3.2,0c0.9,0,0.2-0.1-1.6-0.1
                C265.6,242.8,264.9,242.8,265.8,242.9 M49,243.2c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1C48.9,243.1,48.8,243.2,49,243.2 M141,243.2
                c0.2,0,0.6,0,0.8,0s0-0.1-0.4-0.1C140.9,243.1,140.8,243.2,141,243.2 M388.2,243.2c0.2,0,0.5,0,0.7,0s0-0.1-0.4-0.1
                C388.1,243.1,388,243.2,388.2,243.2 M168.8,243.4c0.4,0,1.1,0,1.5,0s0-0.1-0.8-0.1C168.6,243.3,168.3,243.3,168.8,243.4
                 M189.8,243.4c0.4,0,1.1,0,1.5,0s0-0.1-0.8-0.1C189.7,243.3,189.3,243.3,189.8,243.4"/>
        </g>
    </svg>
    );
}

export default Icon;
