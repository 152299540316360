import {createWithEqualityFn} from "zustand/traditional";

import {
    PaginationRequest,
    PaginationResult,
} from "./ProjectModels";
import {shallow} from "zustand/shallow";
import {MilestoneProduct, MilestoneProductFilter} from "./MilestoneProductModels";

interface MilestoneProductState {
    milestoneProducts: MilestoneProduct[];
    milestoneProduct?: MilestoneProduct;
    operation: "delete" | "select" | "import" | "clone" | "none";
    paginationRequest: PaginationRequest;
    paginationResult: PaginationResult;
    activeFilter: MilestoneProductFilter,
    setMilestoneProducts: (milestoneProducts: MilestoneProduct[]) => void;
    setPaginationRequest: (pagination: PaginationRequest) => void;
    setPaginationResult: (pagination: PaginationResult) => void;
    setActiveFilter: (filter: MilestoneProductFilter) => void;
    setMilestoneProduct: (operation:  "delete" | "select" | "import" | "clone" | "none", milestoneProduct?: MilestoneProduct) => void;
}

export const useMilestoneProductsStore = createWithEqualityFn<MilestoneProductState>((set)  => ({
    milestoneProducts: [],
    milestoneProduct: undefined,
    operation: "none",
    paginationRequest: PaginationRequest.default(),
    paginationResult: PaginationResult.empty(),
    activeFilter: MilestoneProductFilter.default(),
    setMilestoneProducts: (milestoneProducts: MilestoneProduct[]) => {
        set((state: MilestoneProductState) => ({
            milestoneProducts: milestoneProducts
        }));
    },
    setPaginationRequest: (pagination: PaginationRequest) => {
        set((state: MilestoneProductState) => ({
            paginationRequest: pagination
        }));
    },
    setPaginationResult: (pagination: PaginationResult) => {
        set((state: MilestoneProductState) => ({
            paginationResult: pagination
        }));
    },
    setActiveFilter: (filter: MilestoneProductFilter) => {
        set((state: MilestoneProductState) => ({
            activeFilter: filter
        }));
    },
    setOperation: (o:  "delete" | "none") => {
        set(() => ({
            operation: o
        }));
    },
    setMilestoneProduct: (operation:  "delete" | "select" | "import" | "clone" | "none" , milestoneProduct?: MilestoneProduct) => {
        set(() => ({
            milestoneProduct: milestoneProduct,
            operation: operation
        }));
    },
}), shallow)