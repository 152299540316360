import { TopologyIncomingNode, TopologyOutgoingNode, TopologyRestNode, TopologyRouteNode, } from '../model/TopologyDefinition.js';
import { ComponentApi } from './ComponentApi.js';
import { CamelDefinitionApiExt } from './CamelDefinitionApiExt.js';
import { CamelDisplayUtil } from './CamelDisplayUtil.js';
import { CamelUtil } from './CamelUtil.js';
const outgoingDefinitions = ['ToDefinition', 'KameletDefinition', 'ToDynamicDefinition', "PollEnrichDefinition", "EnrichDefinition", "WireTapDefinition", "SagaDefinition"];
export class ChildElement {
    name;
    className;
    multiple;
    constructor(name = '', className = '', multiple = false) {
        this.name = name;
        this.className = className;
        this.multiple = multiple;
    }
}
export class TopologyUtils {
    constructor() { }
    static getOutgoingDefinitions = () => {
        return outgoingDefinitions;
    };
    static isElementInternalComponent = (element) => {
        const uri = element.uri;
        const component = ComponentApi.findByName(uri);
        return component !== undefined &&
            (TopologyUtils.isComponentInternal(component.component.label) || TopologyUtils.hasInternalUri(element));
    };
    static getConnectorType = (element) => {
        return CamelUtil.isKameletComponent(element) ? 'kamelet' : 'component';
    };
    static cutKameletUriSuffix = (uri) => {
        if (uri.endsWith("-sink")) {
            return uri.substring(0, uri.length - 5);
        }
        else if (uri.endsWith("-source")) {
            return uri.substring(0, uri.length - 7);
        }
        else if (uri.endsWith("-action")) {
            return uri.substring(0, uri.length - 7);
        }
        else {
            return uri;
        }
    };
    static getUniqueUri = (element) => {
        const uri = element.uri || '';
        let result = uri.startsWith("kamelet") ? TopologyUtils.cutKameletUriSuffix(uri).concat(":") : uri.concat(":");
        const className = element.dslName;
        if (className === 'FromDefinition' || className === 'ToDefinition') {
            if (!CamelUtil.isKameletComponent(element)) {
                const requiredProperties = CamelUtil.getComponentProperties(element).filter(p => p.required);
                for (const property of requiredProperties) {
                    const value = CamelDefinitionApiExt.getParametersValue(element, property.name, property.kind === 'path');
                    if (value !== undefined && property.type === 'string' && value.trim().length > 0) {
                        result = result + property.name + "=" + value + "&";
                    }
                }
            }
            else {
                const requiredProperties = CamelUtil.getKameletProperties(element, true);
                for (const property of requiredProperties) {
                    const value = CamelDefinitionApiExt.getParametersValue(element, property.id);
                    if (value !== undefined && property.type === 'string' && value.trim().length > 0) {
                        result = result + property.id + "=" + value + "&";
                    }
                }
            }
        }
        return result.endsWith("&") ? result.substring(0, result.length - 1) : result;
    };
    static isComponentInternal = (label) => {
        const labels = label.split(",");
        if (labels.includes('core') && (labels.includes('transformation')
            || labels.includes('testing')
            || labels.includes('scheduling')
            || labels.includes('monitoring')
            || labels.includes('transformation')
            || labels.includes('java')
            || labels.includes('endpoint')
            || labels.includes('script')
            || labels.includes('validation'))) {
            return true;
        }
        else if (label === 'transformation') {
            return true;
        }
        return false;
    };
    static hasInternalUri = (element) => {
        return this.hasDirectUri(element) || this.hasSedaUri(element);
    };
    static hasDirectUri = (element) => {
        return this.hasUriStartWith(element, 'direct');
    };
    static hasSedaUri = (element) => {
        return this.hasUriStartWith(element, 'seda');
    };
    static hasUriStartWith = (element, text) => {
        if (element.uri && typeof element.uri === 'string') {
            return element.uri.startsWith(text);
        }
        else if (element.dslName === 'SagaDefinition') {
            const completion = element.completion || '';
            const compensation = element.compensation || '';
            return completion.startsWith(text) || compensation.startsWith(text);
        }
        else {
            return false;
        }
    };
    static findTopologyRestNodes = (integration) => {
        const result = [];
        integration.forEach(i => {
            const filename = i.metadata.name;
            const routes = i.spec.flows?.filter(flow => flow.dslName === 'RestDefinition');
            routes?.forEach((rest) => {
                const uris = [];
                rest?.get?.forEach((d) => {
                    if (d.to)
                        uris.push(d.to);
                });
                rest?.post?.forEach((d) => {
                    if (d.to)
                        uris.push(d.to);
                });
                rest?.put?.forEach((d) => {
                    if (d.to)
                        uris.push(d.to);
                });
                rest?.delete?.forEach((d) => {
                    if (d.to)
                        uris.push(d.to);
                });
                rest?.patch?.forEach((d) => {
                    if (d.to)
                        uris.push(d.to);
                });
                rest?.head?.forEach((d) => {
                    if (d.to)
                        uris.push(d.to);
                });
                const title = 'REST: ' + (rest.description ? rest.description : rest.id);
                result.push(new TopologyRestNode(rest.path || '', '' + rest.id, uris, title, filename, rest));
            });
        });
        return result;
    };
    static findTopologyIncomingNodes = (integration) => {
        const result = [];
        integration.forEach(i => {
            const filename = i.metadata.name;
            const routes = i.spec.flows?.filter(flow => flow.dslName === 'RouteDefinition');
            const routeElements = routes?.map(r => {
                const id = 'incoming-' + r.id;
                const title = CamelDisplayUtil.getStepDescription(r.from);
                const type = TopologyUtils.isElementInternalComponent(r.from) ? 'internal' : 'external';
                const connectorType = TopologyUtils.getConnectorType(r.from);
                const uniqueUri = TopologyUtils.getUniqueUri(r.from);
                return new TopologyIncomingNode(id, type, connectorType, r.id, title, filename, r.from, uniqueUri);
            }) || [];
            result.push(...routeElements);
        });
        return result;
    };
    static findTopologyRouteNodes = (integration) => {
        const result = [];
        integration.forEach(i => {
            const filename = i.metadata.name;
            const routes = i.spec.flows?.filter(flow => flow.dslName === 'RouteDefinition');
            const routeElements = routes?.map(r => {
                const id = 'route-' + r.id;
                const title = '' + (r.description ? r.description : r.id);
                return new TopologyRouteNode(id, r.id, title, filename, r.from, r);
            }) || [];
            result.push(...routeElements);
        });
        return result;
    };
    static findTopologyOutgoingNodes = (integrations) => {
        const result = [];
        integrations.forEach(i => {
            const filename = i.metadata.name;
            const routes = i.spec.flows?.filter(flow => flow.dslName === 'RouteDefinition');
            routes?.forEach(route => {
                const from = route.from;
                const elements = TopologyUtils.findOutgoingInStep(from, []);
                elements.forEach((e) => {
                    const id = 'outgoing-' + route.id + '-' + e.id;
                    const title = CamelDisplayUtil.getStepDescription(e);
                    const type = TopologyUtils.isElementInternalComponent(e) ? 'internal' : 'external';
                    const connectorType = TopologyUtils.getConnectorType(e);
                    const uniqueUri = TopologyUtils.getUniqueUri(e);
                    result.push(new TopologyOutgoingNode(id, type, connectorType, route.id, title, filename, e, uniqueUri));
                });
            });
        });
        return result;
    };
    static findOutgoingInStep = (step, result) => {
        if (step !== undefined) {
            const el = step;
            if (outgoingDefinitions.includes(el.dslName)) {
                result.push(step);
            }
            else {
                const childElements = CamelDefinitionApiExt.getElementChildrenDefinition(el.dslName);
                childElements.forEach(child => {
                    if (child.multiple) {
                        const sub = el[child.name];
                        TopologyUtils.findOutgoingInSteps(sub, result);
                    }
                    else {
                        const sub = el[child.name];
                        TopologyUtils.findOutgoingInStep(sub, result);
                    }
                });
            }
        }
        return result;
    };
    static findOutgoingInSteps = (steps, result) => {
        if (steps !== undefined && steps.length > 0) {
            steps.forEach(step => TopologyUtils.findOutgoingInStep(step, result));
        }
        return result;
    };
    static getNodeIdByUriAndName(tins, uri, name) {
        if (uri && name) {
            const node = tins
                .filter(r => r.from.uri === uri && r?.from?.parameters?.name === name).at(0);
            if (node) {
                return node.id;
            }
        }
    }
    static getNodeIdByUri(tins, uri) {
        const parts = uri.split(":");
        if (parts.length > 1) {
            return TopologyUtils.getNodeIdByUriAndName(tins, parts[0], parts[1]);
        }
    }
    static getRouteIdByUriAndName(tins, uri, name) {
        if (uri && name) {
            const node = tins
                .filter(r => r.from.uri === uri && r?.from?.parameters?.name === name).at(0);
            if (node) {
                return 'route-' + node.routeId;
            }
        }
    }
    static getNodeIdByUniqueUri(tins, uniqueUri) {
        const node = tins
            .filter(r => r.uniqueUri === uniqueUri).at(0);
        if (node) {
            return node.id;
        }
    }
    static getRouteIdByUri(tins, uri) {
        const parts = uri.split(":");
        if (parts.length > 1) {
            return TopologyUtils.getRouteIdByUriAndName(tins, parts[0], parts[1]);
        }
    }
}
