import React, { useEffect, useState } from "react";
import {
    Button,
    Flex,
    FlexItem,
    Panel,
    PanelHeader,
    PanelMainBody, Label, Title, Accordion, AccordionItem, AccordionContent, AccordionToggle, PageSection, Tooltip
} from "@patternfly/react-core";
import AngleLeftIcon from "@patternfly/react-icons/dist/esm/icons/angle-left-icon";
import PlusIcon from "@patternfly/react-icons/dist/esm/icons/plus-icon";
import MinusIcon from "@patternfly/react-icons/dist/esm/icons/minus-icon";
import { parseDiff, Diff, Hunk, HunkData } from 'react-diff-view'
import 'react-diff-view/style/index.css';
import "./file-diff-view.css";
import { DiffFileProps } from "./VersionHistoryModel";
import { ProjectService } from "../../api/ProjectService";


const FileDiffView = ({ showFileDiff, setShowFileDiff }: DiffFileProps) => {
    const [expanded, setExpanded] = React.useState('ex-toggle-0');
    const [parsedDiff, setParsedDiff] = useState<any[]>([]);


    useEffect(() => {
        const getCommitDiff = async () => {
            const diffData: any = await ProjectService.getCommitDiff(showFileDiff?.commitId);
            if (diffData) {
                const parsed = parseDiff(diffData?.data);
                setParsedDiff(parsed);
            }

        }
        getCommitDiff();
    }, []);


    const totalCount = (data: any[], keyAction: string) => {
        return data.reduce((total, file) => {
            const changeDetect = file.hunks.reduce((count: any, hunk: any) => {
                return count + hunk.changes.filter((change: any) => change[keyAction]).length;
            }, 0);
            return total + changeDetect;
        }, 0);
    };

    const totalDeletions = totalCount(parsedDiff, "isDelete");
    const totalAdd = totalCount(parsedDiff, "isInsert");

    const getTotalKeyCount = (arr: any[], actionKey: string): number => {
        return arr.reduce((acc, item) => {
            if (item[actionKey]) {
                acc += 1;
            }
            return acc;
        }, 0);
    };

    const totalItemChange = (hunks: any[]): any => {
        let deleteCount = 0;
        let addCount = 0;

        if (hunks.length > 0 && hunks[0].changes) {
            deleteCount = getTotalKeyCount(hunks[0].changes, "isDelete");
            addCount = getTotalKeyCount(hunks[0].changes, "isInsert");
        }
        return {
            deleteCount, addCount, totalCount: deleteCount + addCount
        }
    };

    const onToggle = (id: string) => {
        if (id === expanded) {
            setExpanded('');
        } else {
            setExpanded(id);
        }
    };

    const calculatePercentage = (part: number, total: number): number => {
        if (total === 0) return 0;
        return (part / total) * 100;
    };

    const getPercent = (value: number, totalVal: number,) => {
        return calculatePercentage(value, totalVal);
    }

    return (
        <>
            <Flex className="toolbar" direction={{ default: "row" }} justifyContent={{ default: "justifyContentFlexStart" }}>
                <FlexItem className="bottom-space">
                    <Button
                        className="dev-action-button "
                        size="sm"
                        variant="secondary"
                        icon={<AngleLeftIcon />}
                        onClick={e => {
                            setShowFileDiff({ ...showFileDiff, author: "" });
                        }}
                    >
                        Back
                    </Button>
                </FlexItem>
            </Flex>

            <Panel>
                <PanelHeader>
                    <Title headingLevel="h2" style={{ marginBottom: "30px" }}>{showFileDiff.lastCommitMessage}</Title>
                    <Flex className="toolbar" direction={{ default: "row" }} justifyContent={{ default: "justifyContentFlexStart" }}>
                        <FlexItem align={{ default: 'alignLeft' }}>
                            <h2>
                                <span className="smh-avatar">{showFileDiff?.author?.split("")[0]}</span>
                                <b> {showFileDiff?.author} </b><small style={{ marginLeft: "10px" }}>{new Date(showFileDiff?.lastCommitTimestamp).toLocaleString()}</small>
                            </h2>
                        </FlexItem>
                        <FlexItem align={{ default: 'alignRight' }}>
                            Commit<Label style={{ marginLeft: "10px" }} color="grey">{showFileDiff.commitId}</Label>
                        </FlexItem>
                    </Flex>
                </PanelHeader>
                <PageSection className="change-section">
                    <span><PlusIcon /> <MinusIcon /></span>
                    <span> {parsedDiff.length}  changed file</span> with
                    <span> {totalAdd} additions </span>
                    <span> {totalDeletions} deletions</span>
                </PageSection>
                <PanelMainBody>
                    <Accordion togglePosition="start">
                        {parsedDiff.map(({ oldRevision, newRevision, type, hunks }, index) => {
                            return <AccordionItem>
                                <AccordionToggle
                                    onClick={() => onToggle('ex-toggle-' + index)}
                                    isExpanded={expanded.includes('ex-toggle-' + index)}
                                    id={"ex-toggle-" + index}
                                >
                                    <Title className="diff-detail-stats" headingLevel="h4">
                                        <span>{totalItemChange(hunks).totalCount} </span>
                                        <Tooltip content={`${totalItemChange(hunks).totalCount} changes: ${totalItemChange(hunks).addCount} additions and ${totalItemChange(hunks).deleteCount} deletions`} position={"top"}>
                                            <span className="commit-icon">
                                                <span style={{ width: `${getPercent(totalItemChange(hunks).addCount, totalItemChange(hunks).totalCount)}%` }}></span>
                                                <span style={{ width: `${getPercent(totalItemChange(hunks).deleteCount, totalItemChange(hunks).totalCount)}%` }}></span>
                                            </span>
                                        </Tooltip>
                                        <span>{parsedDiff[index]?.newPath}</span>
                                    </Title>
                                </AccordionToggle>
                                <AccordionContent id={"ex-toggle-" + index} isHidden={!expanded.includes('ex-toggle-' + index)} isFixed><Diff
                                    key={`${oldRevision}-${newRevision}`}
                                    viewType="split"
                                    diffType={"rename"}
                                    hunks={hunks}
                                >
                                    {(hunks: HunkData[]) =>
                                        hunks.map((hunk: HunkData) => <Hunk key={hunk.content} hunk={hunk} />)
                                    }
                                </Diff>
                                </AccordionContent>
                            </AccordionItem>

                        })}

                    </Accordion>
                </PanelMainBody>
            </Panel>

        </>)
}

export default FileDiffView;