export class MilestoneProduct {
    id: string = '';
    productId: string = '';
    name: string = '';
    description: string = '';
    createdAt: Date = new Date();

    public constructor(id: string, productId: string, name: string, description: string, createdAt: Date) {
        this.id = id;
        this.productId = productId;
        this.name = name;
        this.description = description;
        this.createdAt = createdAt;
    }
}

export class MilestoneProductFilter {
    static DEFAULT_FROM = undefined;
    static DEFAULT_TO = undefined;
    static DEFAULT_PRODUCT_ID = undefined;
    static DEFAULT_NAME = undefined;
    static DEFAULT_DESCRIPTION = undefined;

    from: Date | undefined;
    to: Date | undefined;
    productId: string | undefined;
    name: string | undefined;
    description: string | undefined;

    public constructor(from: Date | undefined, to: Date | undefined, productId: string | undefined, name: string | undefined, description: string | undefined) {
        this.from = from;
        this.to = to;
        this.productId = productId;
        this.name = name;
        this.description = description;
    }
    public static default(): MilestoneProductFilter {
        return new MilestoneProductFilter(MilestoneProductFilter.DEFAULT_FROM, MilestoneProductFilter.DEFAULT_TO, MilestoneProductFilter.DEFAULT_PRODUCT_ID, MilestoneProductFilter.DEFAULT_NAME, MilestoneProductFilter.DEFAULT_DESCRIPTION);
    }
}

export class MilestoneProductStep {
    id: number;
    milestoneProduct:  MilestoneProduct;
    sortOrder: number;
    name: string = '';
    processName: string = '';
    description: string = '';
    milestoneStep?: MilestoneProductStep;
    createdAt: Date = new Date();
    constructor(id: number, milestoneProduct: MilestoneProduct, sortOrder: number, name: string, processName: string, description: string, milestoneStep: MilestoneProductStep, createdAt: Date) {
        this.id = id;
        this.milestoneProduct = milestoneProduct;
        this.sortOrder = sortOrder;
        this.name = name;
        this.processName = processName;
        this.description = description;
        this.milestoneStep = milestoneStep;
        this.createdAt = createdAt;
    }
}

export class MilestoneStepAssign {
    milestoneStepId: number;
    assignedStepIds:  number[];
    constructor(milestoneStepId: number, assignedStepIds: number[]) {
        this.milestoneStepId = milestoneStepId;
        this.assignedStepIds = assignedStepIds;
    }
}

export class MilestoneProductStepAssign {
    assignTo: number;
    stepsToAssign: [number];

    constructor(assignTo: number, stepsToAssign: [number]) {
        this.assignTo = assignTo;
        this.stepsToAssign = stepsToAssign;
    }
}

export class MilestoneProductStepFilter {
    static DEFAULT_FROM = undefined;
    static DEFAULT_TO = undefined;
    static DEFAULT_NAME = undefined;
    static DEFAULT_DESCRIPTION = undefined;

    from: Date | undefined;
    to: Date | undefined;
    name: string | undefined;
    description: string | undefined;

    public constructor(from: Date | undefined, to: Date | undefined,  name: string | undefined, description: string | undefined) {
        this.from = from;
        this.to = to;
        this.name = name;
        this.description = description;
    }
    public static default(): MilestoneProductStepFilter {
        return new MilestoneProductStepFilter(MilestoneProductStepFilter.DEFAULT_FROM, MilestoneProductStepFilter.DEFAULT_TO, MilestoneProductStepFilter.DEFAULT_NAME, MilestoneProductStepFilter.DEFAULT_DESCRIPTION);
    }
}