import React from 'react';
import {Button, Modal, ModalVariant, Tooltip} from '@patternfly/react-core';
import {ProjectService} from "../../api/ProjectService";
import {useProjectStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import UndoIcon from '@patternfly/react-icons/dist/esm/icons/undo-icon';
import {ProjectDeadletterMessage} from "../../api/ProjectModels";

export interface DeadletterMessageRedeliveryButtonProps extends React.PropsWithoutRef<any> {
    id: number;
    messageId: string;
    isDisabled: boolean;
    selectedMessages: ProjectDeadletterMessage[];
    setSelectedMessages: Function
}

export const DeadletterMessageRedeliveryButton: React.FunctionComponent<DeadletterMessageRedeliveryButtonProps> = ({
    id = 0,
    messageId = '',
    isDisabled = false,
    selectedMessages,
    setSelectedMessages
}: DeadletterMessageRedeliveryButtonProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [project] = useProjectStore((state) => [state.project], shallow )

    const handleModalToggle = (_event: KeyboardEvent | React.MouseEvent) => {
        setIsModalOpen(!isModalOpen);
    };

    const handleMessageRedeliveryConfirmed = (_event: KeyboardEvent | React.MouseEvent) => {
        redeliverDeadletterMessage(id);

        // Remove the redelivered message from the selected messages in case it was selected prior to redelivery
        let messages = [...selectedMessages];
        messages = messages.filter(item => item.id !== id);
        setSelectedMessages(messages);

        setIsModalOpen(!isModalOpen);
    };

    const redeliverDeadletterMessage = (id: number) => {
        return ProjectService.redeliverDeadletterMessages(project.projectId, [id]);
    }

    return (
        <React.Fragment>
            <Tooltip content={"Redeliver message"} position={"bottom"}>
                <Button className="dev-action-button" variant="plain" icon={<UndoIcon/>} onClick={handleModalToggle} isDisabled={isDisabled}></Button>
            </Tooltip>
            <Modal
                variant={ModalVariant.medium}
                title="Warning"
                titleIconVariant="warning"
                isOpen={isModalOpen}
                aria-describedby="modal-title-icon-description"
                onClose={handleModalToggle}
                actions={[
                    <Button key="confirm" variant="primary" onClick={handleMessageRedeliveryConfirmed}>
                        Confirm
                    </Button>,
                    <Button key="cancel" variant="link" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                <span id="modal-title-icon-description">
                  Are you sure you want to redeliver message {messageId}?
                </span>
            </Modal>
        </React.Fragment>
    );
};
