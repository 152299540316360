import React, {ReactElement} from 'react';
import {
    Button,
    CodeBlock,
    CodeBlockCode,
    Divider,
    Modal,
    ModalVariant,
    Panel,
    PanelHeader,
    PanelMain,
    PanelMainBody
} from '@patternfly/react-core';

export interface DeadletterMessageStackTraceLinkProps extends React.PropsWithoutRef<any> {
    errorMessage: string;
    stackTrace: string;
}

export const DeadletterMessageStackTraceLink: React.FunctionComponent<DeadletterMessageStackTraceLinkProps> = ({
    errorMessage = '',
    stackTrace = '',
}: DeadletterMessageStackTraceLinkProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleModalToggle = (_event: KeyboardEvent | React.MouseEvent) => {
        setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
    };

    return (
        <React.Fragment>
            <Button style={{padding: '0px'}} variant={"link"} onClick={handleModalToggle}>{errorMessage}</Button>
            <Modal
                bodyAriaLabel="Error Stack Trace"
                tabIndex={0}
                variant={ModalVariant.large}
                title="Error Stack Trace"
                isOpen={isModalOpen}
                onClose={handleModalToggle}
                actions={[
                    <Button key="cancel" variant="link" onClick={handleModalToggle}>
                        Close
                    </Button>
                ]}
            >
                <Panel>
                    <Divider />
                    <PanelMain>
                        <PanelMainBody>
                            <CodeBlock >
                                <CodeBlockCode style={{overflow: 'scroll', whiteSpace: 'pre'}}  id="body-content"><small>{stackTrace}</small></CodeBlockCode>
                            </CodeBlock>
                        </PanelMainBody>
                    </PanelMain>
                </Panel>
            </Modal>
        </React.Fragment>
    );
};
