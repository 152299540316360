import React, {useEffect} from 'react';
import {
    Bullseye,

    Button,
    EmptyState,
    EmptyStateIcon,
    EmptyStateVariant,
    Flex,
    FlexItem,
    PageSection, Pagination, PaginationVariant,
    Panel, PanelFooter,
    PanelHeader,
    PanelMain,
    PanelMainBody,
    Text,
    TextContent, Title,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    Tooltip,
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {useProcessedEntityStore} from "../../api/ProcessedEntityStore";
import {PaginationRequest} from "../../api/ProjectModels";
import {Table, Tbody, Td, Th, Thead, Tr} from "@patternfly/react-table";

import {ProcessedEntitiesService} from "../../api/ProcessedEntitiesService";
import {ProcessedEntityFilter} from "../../api/ProcessedEntityModels";
import {shallow} from "zustand/shallow";

import RefreshIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";
import {MainToolbar} from "../../designer/MainToolbar";
import {ProcessedEntitiesToolbar} from "./ProcessedEntitiesToolbar";
import DeleteIcon from "@patternfly/react-icons/dist/js/icons/times-icon";
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import {DeleteProcessedEntityModal} from "./DeleteProcessedEntityModal";

export const ProcessedEntitiesPage = () => {

    const { processedEntities, paginationRequest, setPaginationRequest, paginationResult, activeFilter } = useProcessedEntityStore();
    const [setProcessedEntity] = useProcessedEntityStore((s) => [s.setProcessedEntity], shallow);

    const defaultFilter =  ProcessedEntityFilter.default();

    useEffect(() => {
        useProcessedEntityStore.getState().setActiveFilter(defaultFilter)
    }, []);

    useEffect(() => {
            ProcessedEntitiesService.getProcessedEntities(PaginationRequest.default(), ProcessedEntityFilter.default());
    }, []);

    let lineNumber = 1;

    const getProcessedEntities = (pagination: PaginationRequest, filter: ProcessedEntityFilter) => {
        return ProcessedEntitiesService.getProcessedEntities( pagination, filter);
    }

    const onSetPage = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPage: number) => {
        const newPaginationRequest = new PaginationRequest(newPage, paginationRequest.size);

        setPaginationRequest(newPaginationRequest);
        return getProcessedEntities(newPaginationRequest, activeFilter);
    };

    const onPerPageSelect = (
        _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
        newPerPage: number,
        newPage: number
    ) => {
        const newPaginationRequest = new PaginationRequest(newPage, newPerPage);

        setPaginationRequest(newPaginationRequest);
        return getProcessedEntities(newPaginationRequest, activeFilter);
    };


    function getTitle() {
        return <TextContent>
            <Text component="h2">Processed Entities</Text>
        </TextContent>
    }

    function getTools() {
        return <Toolbar id="toolbar-group-types">
            <ToolbarContent>
                <ToolbarItem>
                    <Button variant="link" icon={<RefreshIcon/>} onClick={e => getProcessedEntities(paginationRequest, activeFilter)}/>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    }

    return (
        <PageSection className="kamelet-section projects-page" padding={{default: 'noPadding'}}>
            <PageSection className="tools-section" padding={{default: 'noPadding'}}>
                <MainToolbar title={getTitle()} tools={getTools()} />
            </PageSection>
            <PageSection className="project-tab-panel" padding={{default: "padding"}}>
                <Panel isScrollable>
                    <PanelHeader style={{paddingBottom:'10px'}}>
                        <ProcessedEntitiesToolbar/>
                    </PanelHeader>
                    <PanelMain maxHeight={'calc(100vh - 300px)'}>
                        <PanelMainBody>
                            <Table aria-label="Processed Entities" variant={"compact"} className={"table"}>
                                <Thead>
                                    <Tr>
                                        <Th key='processorName'>Processor Name</Th>
                                        <Th key='messageId'>Message Id</Th>
                                        <Th key='createdAt'>Created At</Th>
                                        <Th key='actions'>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {processedEntities.map(processedEntity => {
                                        lineNumber = lineNumber + 1;
                                        let backgroundColor = lineNumber % 2 ? 'white' : '#f0f0f0';

                                        return <Tr key={lineNumber} style={{backgroundColor: backgroundColor}}>
                                            <Td>{processedEntity.id.processorName}</Td>
                                            <Td>{processedEntity.id.messageId}</Td>
                                            <Td>{processedEntity.createdAt.toString()}</Td>
                                            <Td className="project-action-buttons">
                                                <Flex direction={{default: "row"}}
                                                      spaceItems={{default: 'spaceItemsNone'}}>
                                                    <FlexItem>
                                                        <Tooltip content={"Delete entity"} position={"bottom"}>
                                                            <Button className="dev-action-button" variant={"plain"} icon={<DeleteIcon/>} onClick={e => {
                                                                setProcessedEntity( "delete", processedEntity);
                                                            }}></Button>
                                                        </Tooltip>
                                                    </FlexItem>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    })}
                                    {processedEntities.length === 0 &&
                                        <Tr>
                                            <Td colSpan={6}>
                                                <Bullseye>
                                                    <EmptyState variant={EmptyStateVariant.sm}>
                                                        <EmptyStateIcon icon={SearchIcon}/>
                                                        <Title headingLevel="h2" size="lg">
                                                            No results found
                                                        </Title>
                                                    </EmptyState>
                                                </Bullseye>
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>
                        </PanelMainBody>
                    </PanelMain>
                    <PanelFooter>
                        <Pagination
                            itemCount={paginationResult.totalItems}
                            widgetId="bottom-processed-entities"
                            perPage={paginationRequest.size}
                            page={paginationRequest.page}
                            variant={PaginationVariant.bottom}
                            onSetPage={onSetPage}
                            onPerPageSelect={onPerPageSelect}
                        />
                    </PanelFooter>
                </Panel>
                <DeleteProcessedEntityModal />
            </PageSection>
        </PageSection>
    )
}