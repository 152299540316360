import React, { useEffect, useState, useMemo } from "react";
import { Button, Flex, FlexItem, Label } from "@patternfly/react-core";
import AngleLeftIcon from "@patternfly/react-icons/dist/esm/icons/angle-left-icon";
import { shallow } from "zustand/shallow";
import "./file-diff-view.css"
import { getProjectFileTypeTitle } from "../../api/ProjectModels";
import { ProjectService } from "../../api/ProjectService";
import { useProjectStore, useFileHistoryStore, useFileStore } from "../../api/ProjectStore";
import DataTable from "./data-table/DataTable";
import FileDiffView from "./FileDiffView";
import { ListItems } from "./VersionHistoryModel";

const tableHeader = {
    author: "Author",
    sha: "SHA",
    message: "Message",
    date: "Date"
}

const HistoryList = () => {
    const [listData, setListData] = useState<ListItems[]>([]);
    const [project] = useProjectStore((s) => [s.project], shallow);
    const { setIsFileHistory } = useFileHistoryStore();
    const [file] = useFileStore((s) => [s.file], shallow);
    const [showFileDiff, setShowFileDiff] = useState<ListItems>({
        author: "",
        commitId: "",
        lastCommitMessage: "",
        lastCommitTimestamp: 0,
    });

    useEffect(() => {
        const fetchCommitHistory = async () => {
            try {
                const result = await ProjectService.getCommitHistory(`${file?.projectId}/${file?.name}`);
                setListData(result?.data);
            } catch (err) {
                console.log(err);
            } 
        };

        fetchCommitHistory();
    }, [file]);

    const changeKeys = (file: any): any => {
        return {
            "name": file?.name,
            "code": file?.code,
            "projectId": file?.projectId,
            "lastUpdate": file?.lastUpdate
        }
    }

    const type = getProjectFileTypeTitle(changeKeys(file));

    const renderItems = (item: ListItems, blockItem: keyof ListItems) => {
        if (blockItem === "author") {
            return (
                <>
                    <span className="smh-avatar">{item[blockItem]?.charAt(0)}</span>
                    {item[blockItem]}
                </>
            );
        }

        if (blockItem === "commitId") {
            return (
                <>
                    <Label color="grey" onClick={() => setShowFileDiff(item)}>{item[blockItem]}</Label>
                </>
            );
        }

        if (blockItem === "lastCommitTimestamp") {
            return (
                <>
                    <Label color="grey">
                        {new Date(item[blockItem] as number).toLocaleString()}
                    </Label>
                </>
            );
        }

        return item[blockItem];
    };


    return (
        <>
            {showFileDiff?.author ? (
                <FileDiffView showFileDiff={showFileDiff} setShowFileDiff={setShowFileDiff} />
            ) : (
                <>
                    <Flex className="toolbar" direction={{ default: "row" }} justifyContent={{ default: "justifyContentFlexStart" }}>
                        <FlexItem align={{ default: 'alignLeft' }} style={{ paddingBottom: '20px' }}>
                            <Button className="dev-action-button" size="sm" variant="secondary" icon={<AngleLeftIcon />} onClick={e => { setIsFileHistory(false); }}>
                                Back
                            </Button>
                        </FlexItem>
                    </Flex>

                    <DataTable
                        listData={listData}
                        project={project}
                        type={type}
                        itemPerPage={10}
                        pages={1}
                        showTableHeader={true}
                        showPagination={true}
                        tableHeader={tableHeader}
                        renderItems={renderItems}
                    />
                </>
            )}
        </>
    );
};

export default HistoryList;