import React, { useState } from 'react';
import {
    Button, Form, FormGroup,
    Modal,
    ModalVariant, Text, TextContent, TextInput,
} from '@patternfly/react-core';
import { FileUpload } from '@patternfly/react-core';
import '../../designer/karavan.css';
import {useMilestoneProductsStore} from "../../api/MilestoneProductStore";
import {MilestoneProductsService} from "../../api/MilestoneProductsService";
import {MilestoneProduct} from "../../api/MilestoneProductModels";
import {DropEvent} from "react-dropzone";
import {AxiosError} from "axios";

export const ProductImportModal = () => {

    const { operation, activeFilter, paginationRequest} = useMilestoneProductsStore();
    const [productId, setProductId] = useState('');
    const [name, setName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [selectedFile, setSelectedFile] = React.useState<File | undefined>(undefined);
    const [fileName, setFileName] = React.useState<string>('');
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState<AxiosError | undefined>(undefined);

    function cleanValues()  {
        setProductId("");
        setSelectedFile(undefined);
        setFileName('');
        setProductDescription("");
        setName("");
        setError(undefined);
        setIsError(false);
    }

    async function closeModal () {
        useMilestoneProductsStore.setState({operation: "none"});

        cleanValues();
    }

    async function reloadMilestoneProducts() {
        await MilestoneProductsService.getMilestoneProducts(paginationRequest, activeFilter);
    }

    async function  confirmAndCloseModal<T extends React.UIEvent> (event: T) {
        if(event !== null) {
            event.preventDefault();
        }

        setError(undefined);
        setIsError(false);

        const formData = new FormData();
        if(selectedFile !== undefined) {
            formData.append("file", selectedFile);
        }

        const milestoneProduct = new MilestoneProduct(
            "",
            productId,
            name,
            productDescription,
            new Date()
        );

        const [ err, product ] = await MilestoneProductsService.importMilestoneProduct(milestoneProduct, formData);
        if(err !== undefined && err !== null) {
            setError(err);
        }

        if(err !== null) {
            setIsError(true);
        } else if(product !== null) {
            setIsError(false);
            await reloadMilestoneProducts()
            useMilestoneProductsStore.setState({operation: "none"});
            cleanValues();
        }
    }

    async function onKeyDown (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> {
        if (event.key === 'Enter' && isReady()) {
            await confirmAndCloseModal(event);
        }
    }

    const isReady = () => {
       return name.trim() !== '' && productId.trim() !== '' && selectedFile !== undefined;
    };

    const onProductIdChange = (value: string) => {
        setProductId(value);
    };

    const onProductNameChange = (value: string) => {
        setName(value);
    };

    const onProductDescriptionChange = (value: string) => {
        setProductDescription(value);
    };
    const onFileSelect = (_: DropEvent, file: File) => {
        setSelectedFile(undefined);
        setFileName('');

        setFileName(file.name);
        setSelectedFile(file)
    }

    function formatErrors() {
        const aggregatedError = error!!.response!!.data as AggregateError;
        let errorsFormatted = aggregatedError.errors.map(function(e) {
            return <TextContent>
                <Text/>
                <Text style={{color: 'red', fontStyle: 'italic'}}>{e.field}:</Text>
                {e.message}
            </TextContent>
        });
        return <TextContent>
            {errorsFormatted}
        </TextContent>;
    }

    return (
        <Modal
            title="Add product"
            variant={ModalVariant.small}
            isOpen={["import"].includes(operation)}
            onClose={closeModal}
            onKeyDown={onKeyDown}
            actions={[
                <Button key="confirm" variant="primary" isDisabled={!isReady()}
                        onClick={confirmAndCloseModal}>Add</Button>,
                <Button key="cancel" variant="secondary" onClick={closeModal}>Cancel</Button>
            ]}
            className="new-project"
        >
            <Form isHorizontal={true} autoComplete="off">
                <FileUpload
                    id="simple-file"
                    name="csv"
                    value={selectedFile}
                    filename={fileName}
                    filenamePlaceholder="Select import file"
                    onFileInputChange={onFileSelect}
                    onClearClick={cleanValues}
                    dropzoneProps={{
                        accept: { 'text/csv': ['.csv'] },
                        maxSize: 6000 * 1024
                    }}
                    browseButtonText="Select"
                    isRequired={true}
                    validated={isError ? 'error' : 'default'}
                />
                <FormGroup label="Product Id" fieldId="product-id" isRequired>
                    <TextInput className="text-field" type="text" id="product-id" name="product-id"
                               value={productId}
                               onChange={e => onProductIdChange(e.currentTarget.value)}
                    />

                </FormGroup>
                <FormGroup label="Name" fieldId="name" isRequired>
                    <TextInput className="text-field" type="text" id="name" name="name"
                               value={name}
                               onChange={e => onProductNameChange(e.currentTarget.value)}
                    />
                </FormGroup>
                <FormGroup label="Description" fieldId="description">
                    <TextInput className="text-field" type="text" id="description" name="description"
                               value={productDescription}
                               onChange={e => onProductDescriptionChange(e.currentTarget.value)}
                    />

                    {error && formatErrors()}
                </FormGroup>
            </Form>
        </Modal>
    )
}