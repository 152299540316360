import React, {ReactElement} from 'react';
import {
    Button,
    CodeBlock,
    CodeBlockCode,
    Divider,
    Modal,
    ModalVariant,
    Panel,
    PanelHeader,
    PanelMain,
    PanelMainBody, Tooltip
} from '@patternfly/react-core';
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';

export interface DeadletterMessageInspectionButtonProps extends React.PropsWithoutRef<any> {
    headers: Map<string, string>;
    bodyValue: string;
    bodyType: string;
}

export const DeadletterMessageInspectionButton: React.FunctionComponent<DeadletterMessageInspectionButtonProps> = ({
    headers = new Map<string, string>(),
    bodyValue = '',
    bodyType = '',
}: DeadletterMessageInspectionButtonProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleModalToggle = (_event: KeyboardEvent | React.MouseEvent) => {
        setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
    };

    const renderHeaders = (headers: any) => {
        const map: Map<string, string> = new Map(Object.entries(headers));

        const comps: ReactElement<any, any>[] = [];
        map.forEach((value, key) => comps.push(<div>{key} : {value}</div>));
        return comps;
    };

    return (
        <React.Fragment>
            <Tooltip content={"Inspect message"} position={"bottom"}>
                <Button className="dev-action-button" variant="plain" icon={<SearchIcon/>} onClick={handleModalToggle}></Button>
            </Tooltip>
            <Modal
                bodyAriaLabel="Message details"
                tabIndex={0}
                variant={ModalVariant.medium}
                title="Message details"
                isOpen={isModalOpen}
                onClose={handleModalToggle}
                actions={[
                    <Button key="cancel" variant="link" onClick={handleModalToggle}>
                        Close
                    </Button>
                ]}
            >
                <Panel>
                    <PanelHeader><strong>Headers</strong></PanelHeader>
                    <Divider />
                    <PanelMain>
                        <PanelMainBody>
                            <CodeBlock>
                                <CodeBlockCode id="headers-content">
                                    {renderHeaders(headers)}
                                </CodeBlockCode>
                            </CodeBlock>
                        </PanelMainBody>
                    </PanelMain>
                </Panel>
                <Panel>
                    <PanelHeader>
                        <strong>Body</strong> ({bodyType})
                    </PanelHeader>
                    <Divider />
                    <PanelMain>
                        <PanelMainBody>
                            <CodeBlock>
                                <CodeBlockCode id="body-content">{atob(bodyValue)}</CodeBlockCode>
                            </CodeBlock>
                        </PanelMainBody>
                    </PanelMain>
                </Panel>
            </Modal>
        </React.Fragment>
    );
};
