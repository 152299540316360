import React from 'react';

import {
    Button,
    Modal,
    ModalVariant,
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {useProcessedEntityStore} from "../../api/ProcessedEntityStore";
import {ProcessedEntitiesService} from "../../api/ProcessedEntitiesService";


export const DeleteProcessedEntityModal = () => {

    function confirmAndCloseModal () {
        if (processedEntity) ProcessedEntitiesService.deleteProcessedEntity(processedEntity).then(res => {
            ProcessedEntitiesService.getProcessedEntities(paginationRequest, activeFilter)
        }
    );
        useProcessedEntityStore.setState({operation: "none"});
    }

    const {processedEntity, operation, activeFilter,paginationRequest, } = useProcessedEntityStore();

    function closeModal () {
        useProcessedEntityStore.setState({operation: "none"})

    }

    const isOpen= operation === "delete";
    return (
        <Modal
            title="Confirmation"
            variant={ModalVariant.small}
            isOpen={isOpen}
            onClose={() => closeModal()}
            actions={[
                <Button key="confirm" variant="primary" onClick={e => confirmAndCloseModal()}>Delete</Button>,
                <Button key="cancel" variant="link"
                        onClick={e => closeModal()}>Cancel</Button>
            ]}
            onEscapePress={e => closeModal()}>
            <div>{"Are you sure you want to delete processed entity " + processedEntity?.id.processorName + "-" + processedEntity?.id.messageId + "?"}</div>
        </Modal>
    )
}