import {createWithEqualityFn} from "zustand/traditional";

import {
    PaginationRequest,
    PaginationResult,
} from "./ProjectModels";
import {shallow} from "zustand/shallow";
import {MilestoneProductStep, MilestoneProductStepAssign, MilestoneProductStepFilter} from "./MilestoneProductModels";

interface MilestoneProductStepsState {
    milestoneProductSteps: MilestoneProductStep[];
    milestoneProductStepCandidates: MilestoneProductStep[];
    milestoneProductStep?: MilestoneProductStep;
    operation: "assign" | "delete" | "none";
    setOperation: (o: "assign" | "delete" | "none") => void;
    paginationRequest: PaginationRequest;
    paginationResult: PaginationResult;
    activeFilter: MilestoneProductStepFilter,
    setMilestoneProductSteps: (milestoneProductSteps: MilestoneProductStep[]) => void;
    setMilestoneProductStepCandidates: (milestoneProductStepsAssign: MilestoneProductStep[] ) => void;
    setPaginationRequest: (pagination: PaginationRequest) => void;
    setPaginationResult: (pagination: PaginationResult) => void;
    setActiveFilter: (filter: MilestoneProductStepFilter) => void;
    setMilestoneProductStep: (operation:  "delete" | "none", milestoneProductStep?: MilestoneProductStep) => void;
}

export const useMilestoneProductStepsStore = createWithEqualityFn<MilestoneProductStepsState>((set)  => ({
    milestoneProductSteps: [],
    milestoneProductStepCandidates: [],
    milestoneProductStep: undefined,
    operation: "none",
    paginationRequest: PaginationRequest.default(),
    paginationResult: PaginationResult.empty(),
    activeFilter: MilestoneProductStepFilter.default(),
    setMilestoneProductSteps: (milestoneProductSteps: MilestoneProductStep[]) => {
        set((state: MilestoneProductStepsState) => ({
            milestoneProductSteps: milestoneProductSteps
        }));
    },
    setMilestoneProductStepCandidates: (milestoneProductStepCandidates: MilestoneProductStep[]) => {
        set((state: MilestoneProductStepsState) => ({
            milestoneProductStepCandidates: milestoneProductStepCandidates
        }));
    },
    setPaginationRequest: (pagination: PaginationRequest) => {
        set((state: MilestoneProductStepsState) => ({
            paginationRequest: pagination
        }));
    },
    setPaginationResult: (pagination: PaginationResult) => {
        set((state: MilestoneProductStepsState) => ({
            paginationResult: pagination
        }));
    },
    setActiveFilter: (filter: MilestoneProductStepFilter) => {
        set((state: MilestoneProductStepsState) => ({
            activeFilter: filter
        }));
    },
    setOperation: (o:  "delete" | "none" | "assign") => {
        set(() => ({
            operation: o
        }));
    },
    setMilestoneProductStep: (operation:  "delete" | "none", milestoneProductStep?: MilestoneProductStep) => {
        set(() => ({
            milestoneProductStep: milestoneProductStep,
            operation: operation
        }));
    },
}), shallow)