export class ProcessedEntityId {
    processorName: string = '';
    messageId: string = '';
    public constructor(processorName: string, messageId: string) {
        this.processorName = processorName;
        this.messageId = messageId;
    }
}
export class ProcessedEntity {
    id: ProcessedEntityId ;
    createdAt: Date = new Date();

    public constructor(processorName: string, messageId: string, createdAt: Date) {
        this.id = new ProcessedEntityId(processorName, messageId);
        this.createdAt = createdAt;
    }
}

export class ProcessedEntityFilter {
    static DEFAULT_FROM = undefined;
    static DEFAULT_TO = undefined;
    static DEFAULT_PROCESSOR_NAME = undefined;
    static DEFAULT_MESSAGE_ID = undefined;

    from: Date | undefined;
    to: Date | undefined;
    processorName: string | undefined;
    messageId: string | undefined;

    public constructor(from: Date | undefined, to: Date | undefined, processorName: string | undefined, messageId: string | undefined) {
        this.from = from;
        this.to = to;
        this.processorName = processorName;
        this.messageId = messageId;
    }
    public static default(): ProcessedEntityFilter {
        return new ProcessedEntityFilter(ProcessedEntityFilter.DEFAULT_FROM, ProcessedEntityFilter.DEFAULT_TO, ProcessedEntityFilter.DEFAULT_PROCESSOR_NAME, ProcessedEntityFilter.DEFAULT_MESSAGE_ID);
    }
}