import {SmhApi} from "./SmhApi";
import {ProjectService} from "./ProjectService";

export class ProjectAddOnService {
    public static enableAddOn(projectId: string, addOnType: string) {
        SmhApi.enableProjectAddOn(projectId, addOnType, res => {
            if (res.status === 200) {
                ProjectService.refreshProjectData(projectId);
            } else {
            }
        });
    }

    public static disableAddOn(projectId: string, addOnType: string) {
        SmhApi.disableProjectAddOn(projectId, addOnType, res => {
            if (res.status === 200) {
                ProjectService.refreshProjectData(projectId);
            } else {
            }
        });
    }
}
