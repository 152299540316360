import {SmhApi} from "./SmhApi";
import {ProcessedEntity, ProcessedEntityFilter} from "./ProcessedEntityModels";
import {PaginatedResult, PaginationRequest} from "./ProjectModels";
import {useProcessedEntityStore} from "./ProcessedEntityStore";

export class ProcessedEntitiesService {
    public static async getProcessedEntities(pagination: PaginationRequest, filter: ProcessedEntityFilter) {
        return SmhApi.getProcessedEntities(pagination, filter, (result: PaginatedResult<ProcessedEntity>) => {
            useProcessedEntityStore.setState({processedEntities: result.items});
            useProcessedEntityStore.setState({paginationResult: result.pagination});
        });
    }

    public static async deleteProcessedEntity(processedEntity: ProcessedEntity) {
        return await SmhApi.deleteProcessedEntity(processedEntity);
    }
}
