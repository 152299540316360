import React from 'react';
import {Button, Modal, ModalVariant, Text} from '@patternfly/react-core';
import UndoIcon from '@patternfly/react-icons/dist/esm/icons/undo-icon';
import {ProjectDeadletterMessage} from "../../api/ProjectModels";

export interface DeadletterMessageBulkRedeliveryButtonProps extends React.PropsWithoutRef<any> {
    isRedeliveryDisabled: boolean,
    messagesForRedelivery: ProjectDeadletterMessage[],
    onRedeliverMessages: Function
}

export const DeadletterMessageBulkRedeliveryButton: React.FunctionComponent<DeadletterMessageBulkRedeliveryButtonProps> = (props: DeadletterMessageBulkRedeliveryButtonProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleModalToggle = (_event: KeyboardEvent | React.MouseEvent) => {
        setIsModalOpen(!isModalOpen);
    };

    const handleMessagesRedeliveryConfirmed = (_event: KeyboardEvent | React.MouseEvent) => {
        props.onRedeliverMessages();
        setIsModalOpen(!isModalOpen);
    };

    const messageIdsForRedelivery = props.messagesForRedelivery.map((message) => {
        return <div>{message.messageId}</div>
    });

    return (
        <React.Fragment>
            <Button size={"sm"} variant={"primary"} icon={<UndoIcon/>} isDisabled={props.isRedeliveryDisabled}
                    onClick={handleModalToggle}> Redeliver
            </Button>
            <Modal
                variant={ModalVariant.medium}
                title="Warning"
                titleIconVariant="warning"
                isOpen={isModalOpen}
                aria-describedby="modal-title-icon-description"
                onClose={handleModalToggle}
                actions={[
                    <Button key="confirm" variant="primary" onClick={handleMessagesRedeliveryConfirmed}>
                        Confirm
                    </Button>,
                    <Button key="cancel" variant="link" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                <span id="modal-title-icon-description">
                  Are you sure you want to redeliver these messages?<br/><br/>
                    <Text>
                        {messageIdsForRedelivery}
                    </Text>
                </span>
            </Modal>
        </React.Fragment>
    );
};
