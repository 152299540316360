import React from 'react';

import {
    Button,
    Modal,
    ModalVariant,
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {useLicenseStore} from "../../api/LicenseStore";
import {LicenseService} from "../../api/LicenseService";

export const DeleteLicenseModal = () => {
    const {license, operation} = useLicenseStore();

    async function closeModal () {
        const [err, _license] = await LicenseService.getLicense();

        if(err === null && _license !== null) {
            useLicenseStore.setState({license: _license!!, operation: "none"})
        }
    }

    async function confirmAndCloseModal () {
        const licenseDeletion = await LicenseService.deleteLicense();
        if(licenseDeletion === null) {
            useLicenseStore.setState({operation: "none", license: undefined});
        }
    }

    const isOpen= operation === "delete" && license?.appCode !== undefined;
    return (
        <Modal
            title="Confirmation"
            variant={ModalVariant.small}
            isOpen={isOpen}
            onClose={() => closeModal()}
            actions={[
                <Button key="confirm" variant="danger" onClick={e => confirmAndCloseModal()}>Delete</Button>,
                <Button key="cancel" variant="link"
                        onClick={e => closeModal()}>Cancel</Button>
            ]}
            onEscapePress={e => closeModal()}>
            <div>{"Are you sure you want to delete license?"}</div>
        </Modal>
    )
}