import React from 'react';
import {Button, Modal, ModalVariant, Tooltip} from '@patternfly/react-core';
import {ProjectService} from "../../api/ProjectService";
import {useProjectStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import DeleteIcon from "@patternfly/react-icons/dist/js/icons/times-icon";
import {ProjectDeadletterMessage} from "../../api/ProjectModels";

export interface DeadletterMessageDeleteButtonProps extends React.PropsWithoutRef<any> {
    id: number;
    messageId: string;
    selectedMessages: ProjectDeadletterMessage[];
    setSelectedMessages: Function
}

export const DeadletterMessageDeleteButton: React.FunctionComponent<DeadletterMessageDeleteButtonProps> = ({
    id = 0,
    messageId = '',
    selectedMessages,
    setSelectedMessages
}: DeadletterMessageDeleteButtonProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [project] = useProjectStore((state) => [state.project], shallow )

    const handleModalToggle = (_event: KeyboardEvent | React.MouseEvent) => {
        setIsModalOpen(!isModalOpen);
    };

    const handleMessageDeleteConfirmed = (_event: KeyboardEvent | React.MouseEvent) => {
        deleteDeadletterMessage(id);

        // Remove the deleted message from the selected messages in case it was selected prior to deletion
        let messages = [...selectedMessages];
        messages = messages.filter(item => item.id !== id);
        setSelectedMessages(messages);

        setIsModalOpen(!isModalOpen);
    };

    const deleteDeadletterMessage = (id: number) => {
        return ProjectService.deleteDeadletterMessages(project.projectId, id);
    }

    return (
        <React.Fragment>
            <Tooltip content={"Delete message"} position={"bottom"}>
                <Button className="dev-action-button" variant="plain" icon={<DeleteIcon/>} onClick={handleModalToggle}></Button>
            </Tooltip>
            <Modal
                variant={ModalVariant.medium}
                title="Warning"
                titleIconVariant="danger"
                isOpen={isModalOpen}
                aria-describedby="modal-title-icon-description"
                onClose={handleModalToggle}
                actions={[
                    <Button key="confirm" variant="primary" onClick={handleMessageDeleteConfirmed}>
                        Confirm
                    </Button>,
                    <Button key="cancel" variant="link" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                <span id="modal-title-icon-description">
                  Are you sure you want to delete message {messageId}?
                </span>
            </Modal>
        </React.Fragment>
    );
};
