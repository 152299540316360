import React, {useState} from 'react';
import {
    Bullseye,
    Checkbox,
    EmptyState,
    EmptyStateIcon,
    EmptyStateVariant,
    Flex,
    FlexItem,
    PageSection,
    Pagination,
    PaginationVariant,
    Panel,
    PanelFooter,
    PanelHeader,
    PanelMain,
    PanelMainBody,
    Title
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {Table, Tbody, Td, Th, Thead, Tr} from "@patternfly/react-table";
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import {useProjectDeadletterStore, useProjectStore} from "../../api/ProjectStore";
import {ProjectDeadletterToolbar} from "./ProjectDeadletterToolbar";
import {shallow} from "zustand/shallow";
import {PaginationRequest, ProjectDeadletterMessage, ProjectDeadletterMessageFilter} from "../../api/ProjectModels";
import {ProjectService} from "../../api/ProjectService";
import {DeadletterMessageInspectionButton} from "./DeadletterMessageInspectionButton";
import {DeadletterMessageRedeliveryButton} from "./DeadletterMessageRedeliveryButton";
import {DeadletterMessageDeleteButton} from "./DeadletterMessageDeleteButton";
import {DeadletterMessageStackTraceLink} from "./DeadletterMessageStackTraceLink";

export const ProjectDeadletterTab = () => {
    const { messages, paginationRequest, setPaginationRequest, paginationResult, activeFilter } = useProjectDeadletterStore();
    const [project] = useProjectStore((state) => [state.project], shallow )
    const [isRedeliverButtonDisabled, setRedeliverButtonDisabled] = useState(true);
    const [selectedMessages, setSelectedMessages] = useState<ProjectDeadletterMessage[]>([]);

    let lineNumber = 1;

    const getDeadletterMessages = (pagination: PaginationRequest, filter: ProjectDeadletterMessageFilter) => {
        return ProjectService.getProjectDeadletterMessages(project.projectId, pagination, filter);
    }

    const onSetPage = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPage: number) => {
        const newPaginationRequest = new PaginationRequest(newPage, paginationRequest.size);

        setPaginationRequest(newPaginationRequest);
        return getDeadletterMessages(newPaginationRequest, activeFilter);
    };

    const onPerPageSelect = (
        _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
        newPerPage: number,
        newPage: number
    ) => {
        const newPaginationRequest = new PaginationRequest(newPage, newPerPage);

        setPaginationRequest(newPaginationRequest);
        return getDeadletterMessages(newPaginationRequest, activeFilter);
    };

    const transformStatus = (status: string) => {
        switch (status) {
            case 'NEW':
                return 'New'
            case 'REDELIVERY_REQUESTED':
                return 'Redelivery Requested';
            case 'REDELIVERED':
                return 'Redelivered';
            case 'REDELIVERY_FAILED':
                return 'Redelivery Failed';
            default:
                return 'Unknown';
        }
    }

    const handleMessageSelect = (message: ProjectDeadletterMessage) => {
        let messages = [...selectedMessages];
        const messageIds = messages.map((message) => {
            return message.id
        });
        if (!messageIds.includes(message.id)) {
            messages.push(message);
        } else {
            messages = messages.filter(item => item.id !== message.id);
        }
        setSelectedMessages(messages);

        messages.length > 0 ? disableRedeliverButton(false) : disableRedeliverButton(true)
    }

    const isChecked = (messageId :Number) => {
        return selectedMessages.some(el =>  el.id === messageId)
    }

    const disableRedeliverButton = (status :boolean) => {
        setRedeliverButtonDisabled(status);
    }

    const redeliverDeadletterMessages = () => {
        let messageIdsForRedelivery = selectedMessages.map((message) => {
            return message.id
        });

        ProjectService.redeliverDeadletterMessages(project.projectId, messageIdsForRedelivery);
        setSelectedMessages([]);
        disableRedeliverButton(true)
    }

    const isRedeliveryDisabled = (status: string) => {
        return status !== 'NEW' && status !== 'REDELIVERY_FAILED';
    }

    return (
        <PageSection className="project-tab-panel" padding={{default: "padding"}}>
            <Panel isScrollable>
                <PanelHeader style={{paddingBottom:'10px'}}>
                    <ProjectDeadletterToolbar isRedeliveryDisabled={isRedeliverButtonDisabled} messagesForRedelivery={selectedMessages} onRedeliverMessages={redeliverDeadletterMessages}/>
                </PanelHeader>
                <PanelMain maxHeight={'calc(100vh - 300px)'}>
                    <PanelMainBody>
                        <Table aria-label="Deadletter Messages" variant={"compact"} className={"table"}>
                            <Thead>
                                <Tr>
                                    <Th key='messageIdKey'> </Th>
                                    <Th key='exchangeId'>Exchange Id</Th>
                                    <Th key='messageId'>Message Id</Th>
                                    <Th key='routeId'>Route Id</Th>
                                    <Th key='redeliveryEndpoint'>Redelivery Endpoint</Th>
                                    <Th key='exceptionMessage'>Error Message</Th>
                                    <Th key='createdAt'>Failed On</Th>
                                    <Th key='status'>Status</Th>
                                    <Th key='actions'>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {messages.map(line => {
                                    lineNumber = lineNumber + 1;
                                    let backgroundColor = lineNumber % 2 ? 'white' : '#f0f0f0';

                                    return <Tr key={line.id} style={{backgroundColor: backgroundColor}}>
                                        <Td modifier="fitContent" textCenter={true}>
                                            <Checkbox
                                                id={"deadletter-message-" + line.id.toString()}
                                                isChecked = {isChecked(line.id)}
                                                value={line.id}
                                                onChange={() => handleMessageSelect(line)}
                                                isDisabled={isRedeliveryDisabled(line.status)}/>
                                        </Td>
                                        <Td><small>{line.exchangeId}</small></Td>
                                        <Td><small>{line.messageId}</small></Td>
                                        <Td>{line.routeId}</Td>
                                        <Td>{line.redeliveryEndpoint}</Td>
                                        <Td>
                                            <DeadletterMessageStackTraceLink errorMessage={line.exceptionMessage} stackTrace={line.exceptionStackTrace} />
                                        </Td>
                                        <Td>{line.createdAt.toString()}</Td>
                                        <Td>{transformStatus(line.status)}</Td>
                                        <Td>
                                            <Flex direction={{default: "row"}} spaceItems={{default: 'spaceItemsNone'}}>
                                                <FlexItem>
                                                    <DeadletterMessageInspectionButton headers={line.headers} bodyValue={line.bodyValue} bodyType={line.bodyType} />
                                                </FlexItem>
                                                <FlexItem>
                                                    <DeadletterMessageDeleteButton id={line.id} messageId={line.messageId} selectedMessages={selectedMessages} setSelectedMessages={setSelectedMessages}/>
                                                </FlexItem>
                                                <FlexItem>
                                                    <DeadletterMessageRedeliveryButton id={line.id} messageId={line.messageId} isDisabled={isRedeliveryDisabled(line.status)} selectedMessages={selectedMessages} setSelectedMessages={setSelectedMessages}/>
                                                </FlexItem>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                })}
                                {messages.length === 0 &&
                                    <Tr>
                                        <Td colSpan={8}>
                                            <Bullseye>
                                                <EmptyState variant={EmptyStateVariant.sm}>
                                                    <EmptyStateIcon icon={SearchIcon}/>
                                                    <Title headingLevel="h2" size="lg">
                                                        No results found
                                                    </Title>
                                                </EmptyState>
                                            </Bullseye>
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </PanelMainBody>
                </PanelMain>
                <PanelFooter>
                    <Pagination
                        itemCount={paginationResult.totalItems}
                        widgetId="bottom-deadletter"
                        perPage={paginationRequest.size}
                        page={paginationRequest.page}
                        variant={PaginationVariant.bottom}
                        onSetPage={onSetPage}
                        onPerPageSelect={onPerPageSelect}
                    />
                </PanelFooter>
            </Panel>
        </PageSection>
    )
}
