import {SmhApi} from "./SmhApi";
import {useSmhIntegrationStore} from "./SmhIntegrationStore";
import {ProjectFile} from "./ProjectModels";

export class SmhIntegrationService {
    public static getIntegrationCount() {
        return SmhApi.getIntegrationsCount((result: number) => {
            useSmhIntegrationStore.setState({integrationsCount: result});
        })
    }

    public static getAllIntegrationsFiles() {
        return SmhApi.getAllIntegrationsFiles((files: ProjectFile[]) => {
            useSmhIntegrationStore.setState({integrationsFiles: files});
        })
    }
}