import {createWithEqualityFn} from "zustand/traditional";

import {
    PaginationRequest,
    PaginationResult,
} from "./ProjectModels";
import {shallow} from "zustand/shallow";
import {ProcessedEntity, ProcessedEntityFilter} from './ProcessedEntityModels';

interface ProcessedEntityState {
    processedEntities: ProcessedEntity[];
    processedEntity?: ProcessedEntity;
    operation: "delete" | "none";
    paginationRequest: PaginationRequest;
    paginationResult: PaginationResult;
    activeFilter: ProcessedEntityFilter,
    setProcessedEntities: (processedEntities: ProcessedEntity[]) => void;
    setPaginationRequest: (pagination: PaginationRequest) => void;
    setPaginationResult: (pagination: PaginationResult) => void;
    setActiveFilter: (filter: ProcessedEntityFilter) => void;
    setProcessedEntity: (operation:  "delete" | "none", processedEntity?: ProcessedEntity) => void;
}

export const useProcessedEntityStore = createWithEqualityFn<ProcessedEntityState>((set)  => ({
    processedEntities: [],
    processedEntity: undefined,
    operation: "none",
    paginationRequest: PaginationRequest.default(),
    paginationResult: PaginationResult.empty(),
    activeFilter: ProcessedEntityFilter.default(),
    setProcessedEntities: (processedEnities: ProcessedEntity[]) => {
        set((state: ProcessedEntityState) => ({
            processedEntities: processedEnities
        }));
    },
    setPaginationRequest: (pagination: PaginationRequest) => {
        set((state: ProcessedEntityState) => ({
            paginationRequest: pagination
        }));
    },
    setPaginationResult: (pagination: PaginationResult) => {
        set((state: ProcessedEntityState) => ({
            paginationResult: pagination
        }));
    },
    setActiveFilter: (filter: ProcessedEntityFilter) => {
        set((state: ProcessedEntityState) => ({
            activeFilter: filter
        }));
    },
    setOperation: (o:  "delete" | "none") => {
        set(() => ({
            operation: o
        }));
    },
    setProcessedEntity: (operation:  "delete" | "none", processedEntity?: ProcessedEntity) => {
        set(() => ({
            processedEntity: processedEntity,
            operation: operation
        }));
    },
}), shallow)